import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const history = {
  path: 'history',
  name: `${basePath}-offer-history`,
  component: () =>
    import(
      /* webpackChunkName: "ec-offers" */ '@/components/organisms/modules/ecommerce/offer/form/history/History.vue'
    ),
  meta: {
    requiresAuth: true,
    title: 'Offer comments ',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.PUBLISHER.id],
    loadingIcon: true,
  },
  props: true,
};
