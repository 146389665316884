import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import basicInfo from '@/router/private/modules/socialAudience/demand/campaign/createForm/basicInfo';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;
const contextSecure = CONTEXTS.SOCIAL_AUDIENCE.id;

export const create = {
  path: 'new',
  name: `${basePath}-new-campaign`,
  redirect: { name: basicInfo.name },
  component: () =>
    import(/* webpackChunkName: "sa-campaign-view" */ '@/views/private/modules/socialAudience/campaign/CampaignCreate'),
  meta: {
    title: 'New Campaign ',
    requiresAuth: true,
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id],
    breadcrumbs: new BreadcrumbItem('New Campaign', { name: `${basePath}-new-campaign` }, {}),
  },
  props: true,
  children: [basicInfo],
};
