import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const clickId = {
  path: 'click-id',
  name: `${basePath}-analytics-click-id`,
  component: () =>
    import(/* webpackChunkName: "ec-analytics-click-id" */ '@/views/private/modules/ecommerce/analytics/ClickId.vue'),
  meta: {
    breadcrumbs: new BreadcrumbItem('Click ID', { name: `${basePath}-analytics-click-id` }, {}),
    requiresAuth: true,
    title: 'Click ID',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  props: true,
};

export default clickId;
