import { core as api } from '@/services/modules/Core';
import { USER_RESOURCE } from '@/services/keys';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';

export const getResources = () => ({
  [USER_RESOURCE]: undefined,
});

/**
 * @param {QueryParamsBuilder} params
 * @param {String} clientId
 * @return {Promise.<{data:CoreUser[],meta:EndpointMeta}>}
 */
export async function getSellersUsers(params = new QueryParamsBuilder(), clientId = undefined) {
  const partialUrl = api.createUrl(getResources());

  params.addFilter('client.id', clientId);
  params.addFilter('contextRole.context', CONTEXTS.DASHBOARD_SSP.id);
  params.addFilter('contextRole.role', ROLES.SELLER.id);

  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
