import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;
const contextSecure = CONTEXTS.SOCIAL_AUDIENCE.id;

export default {
  path: 'targeting',
  name: `${basePath}-campaign-targeting`,
  component: () =>
    import(
      /* webpackChunkName: "sa-campaign-targeting" */
      '@/components/organisms/modules/socialAudience/campaign/form/targeting/TargetingHandler'
    ),
  meta: {
    requiresAuth: true,
    title: 'Campaign',
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id, ROLES.CLIENT.id, ROLES.ADVERTISER.id],
  },
  props: true,
};
