import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;

export const openMarket = {
  path: 'open-market',
  name: `${basePath}-open-market`,
  component: () =>
    import(
      /* webpackChunkName: "ds-analytics-open-market" */ '@/views/private/modules/dashboardSSP/analytics/openMarket/OpenMarket'
    ),
  meta: {
    requiresAuth: true,
    title: 'Analytics Open Market',
    contextSecure: CONTEXTS.DASHBOARD_SSP.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id],
    breadcrumbs: new BreadcrumbItem('Open Market', { name: `${basePath}-open-market` }),
  },
  props: true,
};
