import { ROLES } from '@/model/shared/roles';

export const SOCIAL_AUDIENCE = {
  [ROLES.ADMIN.id]: {
    CREATE: ['USER', 'CLIENT', 'COMMENT'],
    DELETE: ['USER', 'CLIENT'],
    SHOW_LIST: ['USER', 'CLIENT'],
    SHOW: ['USER', 'CLIENT'],
    EDIT: ['USER', 'CLIENT'],
  },
  [ROLES.CLIENT.id]: {
    CREATE: ['USER', 'COMMENT'],
    DELETE: ['USER'],
    SHOW_LIST: ['USER', 'CLIENT'],
    SHOW: ['USER', 'CLIENT'],
    EDIT: ['USER', 'CLIENT'],
  },
  [ROLES.AD_OPS.id]: {
    CREATE: ['CLIENT', 'COMMENT'],
    DELETE: [],
    SHOW_LIST: ['CLIENT'],
    SHOW: ['CLIENT'],
    EDIT: ['CLIENT'],
  },
  [ROLES.ADVERTISER.id]: {
    CREATE: [],
    DELETE: [],
    SHOW_LIST: [],
    SHOW: [],
    EDIT: [],
  },
};
