/**
 * AnalyticGraph
 * @class
 * @constructor
 * @public
 */
export default class AnalyticGraph {
  /**
   * @construcor
   * @typedef {object} AnalyticGraph
   * @property @param {string} id
   * @property @param {number} revenue
   * @property @param {number} requests
   * @property @param {number} clicks
   * @property @param {number} impression
   * @property @param {number} fillRate
   */
  constructor(id, revenue = 0, requests = 0, clicks = 0, impression = 0, fillRate = 0) {
    this.id = id;
    this.revenue = revenue;
    this.requests = requests;
    this.clicks = clicks;
    this.impression = impression;
    this.fillRate = fillRate;
  }

  static create({ id: entityId, attributes: attr }) {
    return new AnalyticGraph(entityId, attr.revenue, attr.requests, attr.clicks, attr.impression, attr.fillRate);
  }
}
