import { stringToDate } from '@/filters/dateFilters';
import { SocialAudienceClient } from './SocialAudienceClient';
import Status from '@/model/shared/Status';
import Analytic from './Analytic';
import Advertiser from './Advertiser';
import { CAMPAIGN_STATUS, CAMPAIGN_STATUS_DEFAULT } from '@/model/modules/socialAudience/campaign/CampaignStatus';

export default class CampaignAnalytics {
  /**
   * @constructor
   * @typedef {object} CampaignAnalytics
   * @property @param {string} id
   * @property @param {string} name
   * @property @param {string} start
   * @property @param {string} end
   * @property @param {string} status
   * @property @param {string} type
   * @property @param {Advertiser} advertiser
   * @property @param {Client} client
   *
   */
  constructor(
    id = null,
    name = '',
    startDate = new Date(),
    endDate = new Date(),
    status = null,
    type = '',
    advertiser = new Advertiser(),
    client = new SocialAudienceClient(),
    analytic = new Analytic()
  ) {
    this.id = id;
    this.name = name;
    this.startDate = startDate;
    this.endDate = endDate;
    this.status = status || CAMPAIGN_STATUS_DEFAULT;
    this.type = type;
    this.advertiser = advertiser;
    this.client = client;
    this.analytic = analytic;
  }

  /**
   *
   * @param {{id:string, attributes: CampaignAnalytics, relationships?: Object}} entity
   * @param object included
   * @return {CampaignAnalytics}
   */
  static create(entity, included) {
    const attr = entity.attributes;

    const advertiserId = entity.relationships?.advertiser?.data.id;
    const clientId = entity.relationships?.client?.data.id;

    const status = CAMPAIGN_STATUS.find(status => status.id === attr.status);

    return new CampaignAnalytics(
      entity.id,
      attr.name,
      attr.start ? stringToDate(attr.start) : null,
      attr.end ? stringToDate(attr.end) : null,
      status,
      new Status(attr.type),
      included.advertisers?.find(advertiser => advertiser.id === advertiserId),
      included.clients?.find(client => client.id === clientId),
      Analytic.create({ attributes: attr.analytic })
    );
  }
}
