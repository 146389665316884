import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import basicInfo from '@/router/private/modules/ecommerce/demand/offer/createForm/basicInfo';
import targeting from '@/router/private/modules/ecommerce/demand/offer/createForm/targeting';
import creatives from '@/router/private/modules/ecommerce/demand/offer/createForm/creatives';
import fraud from '@/router/private/modules/ecommerce/demand/offer/createForm/fraud';
import postEvent from '@/router/private/modules/ecommerce/demand/offer/createForm/postEvent';
import publishers from '@/router/private/modules/ecommerce/demand/offer/createForm/publishers';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const create = {
  path: 'new',
  name: `${basePath}-new-offer`,
  redirect: { name: basicInfo.name },
  component: () =>
    import(/* webpackChunkName: "ec-offes-create" */ '@/views/private/modules/ecommerce/offer/OfferCreate'),
  meta: {
    requiresAuth: true,
    title: 'New Offer ',
    breadcrumbs: new BreadcrumbItem('New Offer', { name: `${basePath}-new-offer` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.PUBLISHER.id],
  },
  children: [basicInfo, targeting, creatives, fraud, postEvent, publishers],
  props: true,
};
