import CREATIVE_FEED_TYPES from '@/model/modules/ecommerce/offer/creative/creativeFeed/CreativeFeedTypes';
import { dateToYYYYMMDD, stringToDate } from '@/filters/dateFilters';
import FeedStructure from '@/model/shared/FeedStructure';

/**
 * @class
 * @public
 * @constructor
 */
export default class FeedParserProvider extends FeedStructure {
  /**
   * @constructor
   * @typedef {Object} FeedParserProvider
   * @property @param {String} id
   * @property @param {String} name
   * @property @param {Date} createdAt
   * @property @param {CreativeFeedType} feedType
   * @property @param {Number} hourInterval
   * @property @param {Object} dictionary
   * @property @param {String, Object} source
   * @property @param {String} utm
   */
  constructor(
    id = null,
    name = null,
    createdAt = null,
    feedType = CREATIVE_FEED_TYPES.FILE,
    hourInterval = -1,
    dictionary = {},
    source = null,
    utm = null
  ) {
    super(dictionary);
    this.id = id;
    this.name = name;
    this.createdAt = createdAt;
    this.feedType = feedType;
    this.hourInterval = hourInterval;
    this.dictionary = dictionary;
    this.source = source;
    this.utm = utm;
  }

  static create(entity) {
    const createdAt = dateToYYYYMMDD(stringToDate(entity.attributes.createdAt.date));
    const feedType = entity.attributes.hourInterval === -1 ? CREATIVE_FEED_TYPES.FILE : CREATIVE_FEED_TYPES.FEED;
    const dictionary = FeedParserProvider.parseDictionary(entity.attributes.dictionary);

    return new FeedParserProvider(
      entity.id,
      entity.attributes.name,
      createdAt,
      feedType,
      entity.attributes.hourInterval,
      dictionary,
      entity.attributes.url,
      entity.attributes.utm
    );
  }

  payload() {
    const { name, hourInterval, utm, source, dictionary } = this;
    const ObjectToSend = {
      name,
      hourInterval,
      dictionary,
      utm,
    };
    if (this.feedType.id === CREATIVE_FEED_TYPES.FILE.id) {
      ObjectToSend.file = source;
    } else {
      ObjectToSend.url = source;
    }
    return ObjectToSend;
  }

  toFormData() {
    const feedParser = this.payload();
    const bodyData = new FormData();
    if (feedParser.file) bodyData.set('file', feedParser.file);
    if (feedParser.url) bodyData.set('url', feedParser.url);
    bodyData.set('name', feedParser.name);
    bodyData.set('hourInterval', feedParser.hourInterval);
    bodyData.set('utm', feedParser.utm);
    Object.entries(feedParser.dictionary.feed).forEach(entry => {
      const [key, value] = entry;
      if (value) {
        bodyData.set(`dictionary${key}`, value.id);
      }
    });

    return bodyData;
  }

  static flattenObject(object, parent = '') {
    let flat = {};
    Object.entries(object).forEach(pair => {
      const [key, value] = pair;
      if (typeof value === 'string') {
        const parentKey = parent ? `[${parent}]` : '';
        flat[`${parentKey}[${key}]`] = value;
      } else if (typeof value === 'object') {
        flat = { ...flat, ...FeedParserProvider.flattenObject(value, parent + key) };
      }
    });

    return flat;
  }

  static dictValueToOption(value) {
    return { id: value, name: value };
  }

  static parseDictionary(dictionary) {
    if (dictionary.our) {
      dictionary.our = Object.values(dictionary.our).map(v => {
        return FeedParserProvider.dictValueToOption(v);
      });
    }

    if (dictionary.file) {
      dictionary.file = FeedParserProvider.flattenObject(dictionary.file);
    }

    if (dictionary.feed) {
      dictionary.feed = FeedParserProvider.flattenObject(dictionary.feed);
      Object.keys(dictionary.feed).forEach(key => {
        dictionary.feed[key] = FeedParserProvider.dictValueToOption(dictionary.feed[key]);
      });
    }

    return dictionary;
  }
}
