import Vue from 'vue';
import VueRouter from 'vue-router';
import { ACTIVE_CLIENT, RESET_CONTEXT, SET_ACTIVE_CLIENT } from '@/store/modules/context/keys';
import store from '@/store';
import { BREADCRUMB_FROM_ROUTE } from '@/store/modules/breadcrumbs/keys';
import { hasLoggedIn } from './utils/hasLoggedIn';
import { hasRolePermissions } from './utils/hasRolePermissions';
import { getClientByIdOrNull } from '@/services/modules/Core/client/getClientById';
import { getClients } from '@/services/modules/Core/client';
import { LAST_URL, USER } from '@/store/modules/auth/keys';
import { CONTEXTS } from '@/model/shared/contexts';
import { CONTEXT_BOARD_BASE } from '@/model/shared/constants';
import { ROUTE_COMPONENTS } from '@/model/shared/router';
import { privateRoutes } from '@/router/private';
import { publicRoutes } from '@/router/public';
import { withoutPermission } from '@/router/private/shared/withoutPermission';
import { isAdminUser } from '@/utils/user/isAdminUser';
import { home } from '@/router/private/shared/home';
import { login } from '@/router/public/login';
import redirectToContextPage from '@/router/utils/redirectToContextPage';
import { isCustomDomain } from '@/utils/domain';
import getDefaultRouterNameByUser from '@/model/shared/context/getDefaultRouterByUser';

const loginPage = { name: login.name };
const homePage = { name: home.name };
const withoutPermissionPage = { name: withoutPermission.name };
const contextBoard = { name: `${CONTEXT_BOARD_BASE}-${ROUTE_COMPONENTS.CLIENT}` };
Vue.use(VueRouter);

export const routes = [...publicRoutes, ...privateRoutes];

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/*
 * Ensure that the user only gets access to the right content.
 *
 * We must take care of the use cases:
 *  - Context board can only by seen by selected roles
 *  - If you are not allowed to see context board, pick a client and go to the view
 *  - Logged users are redirected to context board if the access the login or reset-password view
 *  - The permission to access to each view must be matched to the logged user permission
 *  - The only view that can be accessed with out a client must be the context board
 *  */

router.beforeEach(async (to, _, next) => {
  const isLogged = await hasLoggedIn(to);
  const loggedUser = store.getters[USER];
  if (loggedUser && (to.name === loginPage.name || to.name === homePage.name)) {
    if (isAdminUser(loggedUser)) {
      return next(contextBoard);
    }
    const route = redirectToContextPage(loggedUser);
    if (route) return next(route);
  }

  const noAuthNeeded = !to.meta.requiresAuth;
  if (noAuthNeeded) {
    return next(nextWhenNoAuthNeeded(isLogged, to));
  }

  if (!isLogged) {
    await storeLastUrl(to);
    return next(loginPage);
  }

  await saveBreadcrumb(to);

  if (!hasRolePermissions(to)) {
    return next(withoutPermissionPage);
  }

  if (!isClientRequiredForView(to)) {
    await nextWhenClientNoIsRequiredForView();
    return next();
  }

  const storedClient = store.getters[ACTIVE_CLIENT];
  if (!isStoredClientOutdated(storedClient, to)) {
    return next();
  }

  const client = await getActiveClientFromUrl(to);
  if (client) {
    return next(getRouteWithClientId(to, client));
  }
  const selectedClient = await getSelectedClient(to.meta.contextSecure);
  if (selectedClient) {
    return next(contextDefaultViewPath(selectedClient, loggedUser));
  }

  return next(withoutPermissionPage);
});

/*
 * Set the tab title after each navigation. If the title has any placeholder ({user}, {client},...) won't be replaced
 */
router.afterEach(to => {
  const domain = window.location.host.split('.')[1];
  const title = isCustomDomain() ? `${to.meta.title} - ${domain}` : `${to.meta.title} - SunMedia`;

  if (title?.indexOf('{') === -1) {
    document.title = title;
  }
});

/*
 * Saves last path to store, so when user logs in it can be redirected back to the last url.
 * The url is stored if it's not the context-board
 */
async function storeLastUrl(to) {
  if (to.name.indexOf(CONTEXT_BOARD_BASE) === -1) {
    await store.dispatch(LAST_URL, to.path);
  }
}

/*
 Checks if the view needs a Client to properly operate. Guest views are excluded here, since them will never require
 a client.
 */
function isClientRequiredForView(to) {
  const viewsWithoutClient = [
    ROUTE_COMPONENTS.HOME,
    ROUTE_COMPONENTS.NO_PERMISSION,
    ROUTE_COMPONENTS.NOT_FOUND,
    ROUTE_COMPONENTS.PROFILE,

    `${CONTEXT_BOARD_BASE}-${ROUTE_COMPONENTS.USER}`,
    `${CONTEXT_BOARD_BASE}-${ROUTE_COMPONENTS.USER_NEW}`,
    `${CONTEXT_BOARD_BASE}-${ROUTE_COMPONENTS.USER_EDIT}`,

    `${CONTEXT_BOARD_BASE}-${ROUTE_COMPONENTS.CLIENT}`,
    `${CONTEXT_BOARD_BASE}-${ROUTE_COMPONENTS.CLIENT_NEW}`,
    `${CONTEXT_BOARD_BASE}-${ROUTE_COMPONENTS.CLIENT_EDIT}`,

    `${CONTEXTS.THIRD_PARTY.baseUrl}-${ROUTE_COMPONENTS.CLIENT_EDIT}`,
    `${CONTEXTS.THIRD_PARTY.baseUrl}-${ROUTE_COMPONENTS.CLIENT_NEW}`,
  ];

  return !viewsWithoutClient.includes(to?.name);
}

function contextDefaultViewPath(client, user) {
  const defaultContext = CONTEXTS[client.type];
  return { name: getDefaultRouterNameByUser(defaultContext, user), params: { clientId: client.id } };
}

/*
 * Ensures that the stored client matches the on requested by the url
 * */
function isStoredClientOutdated(storedClient, to) {
  return !storedClient?.id || (to.params?.clientId && to.params.clientId !== storedClient.id);
}

/*
 * Create a new route with provided client
 * */
function getRouteWithClientId(to, client) {
  to.params.clientId = client.id;
  return to;
}

async function getActiveClientFromUrl(to) {
  let clientFromUrl = null;

  if (to.params?.clientId) {
    clientFromUrl = await getClientByIdOrNull(to.params.clientId);
  }

  await store.dispatch(SET_ACTIVE_CLIENT, clientFromUrl);

  return clientFromUrl;
}

function nextWhenNoAuthNeeded(isLogged, to) {
  // Views that can't be reached if you are logged.
  // In that situation, you are redirected to CB
  const avoidLoggedAccess = [ROUTE_COMPONENTS.LOGIN, ROUTE_COMPONENTS.RESET_PASSWORD];
  if (isLogged && avoidLoggedAccess.includes(to?.name)) {
    return contextBoard;
  }
}

async function nextWhenClientNoIsRequiredForView() {
  await store.dispatch(SET_ACTIVE_CLIENT, null);
  await store.dispatch(RESET_CONTEXT, null);
}

async function getSelectedClient(context) {
  try {
    if (!context) {
      const loggedUser = store.getters[USER];
      const availableContexts = loggedUser.contextRoles
        ?.filter(cr => cr.context !== CONTEXTS.CORE.id)
        .map(cr => cr.context);
      return clientsRelated.find(client => availableContexts.includes(client.type));
    }
    const clientsRelated = (await getClients()).data;
    return clientsRelated.find(client => client.type === context);
  } catch {
    return undefined;
  }
}

async function saveBreadcrumb(to) {
  await store.dispatch(BREADCRUMB_FROM_ROUTE, to);
}

export default router;
