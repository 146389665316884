/**
 * Metric
 * @constructor
 * @public
 */
export default class Metric {
  /**
   * @typedef {object} Metric
   * @property @param {number} clicks
   * @property @param {number} ctr
   * @property @param {number} fillRate
   * @property @param {number} grossCPMRequest
   * @property @param {number} grossCPMRequestUsd
   * @property @param {number} grossECPM
   * @property @param {number} grossECPMUsd
   * @property @param {number} grossRevenue
   * @property @param {number} grossRevenueUsd
   * @property @param {number} impressions
   * @property @param {number} netCPMRequest
   * @property @param {number} netCPMRequestUsd
   * @property @param {number} netECPM
   * @property @param {number} netECPMUsd
   * @property @param {number} netRevenue
   * @property @param {number} netRevenueUsd
   * @property @param {number} requests
   */
  constructor(
    clicks = null,
    ctr = null,
    fillRate = null,
    grossCPMRequest = null,
    grossCPMRequestUsd = null,
    grossECPM = null,
    grossECPMUsd = null,
    grossRevenue = null,
    grossRevenueUsd = null,
    impressions = null,
    netCPMRequest = null,
    netCPMRequestUsd = null,
    netECPM = null,
    netECPMUsd = null,
    netRevenue = null,
    netRevenueUsd = null,
    requests = null
  ) {
    this.clicks = clicks;
    this.ctr = ctr;
    this.fillRate = fillRate;
    this.grossCPMRequest = grossCPMRequest;
    this.grossCPMRequestUsd = grossCPMRequestUsd;
    this.grossECPM = grossECPM;
    this.grossECPMUsd = grossECPMUsd;
    this.grossRevenue = grossRevenue;
    this.grossRevenueUsd = grossRevenueUsd;
    this.impressions = impressions;
    this.netCPMRequest = netCPMRequest;
    this.netCPMRequestUsd = netCPMRequestUsd;
    this.netECPM = netECPM;
    this.netECPMUsd = netECPMUsd;
    this.netRevenue = netRevenue;
    this.netRevenueUsd = netRevenueUsd;
    this.requests = requests;
  }

  /**
   * This method is used to get a AnalyticMetric object from a JSON.
   * @param {AnalyticMetric} attr
   * @return {AnalyticMetric}
   */
  static build(attr = {}) {
    return new Metric(
      attr.clicks,
      attr.ctr,
      attr.fillRate,
      attr.grossCPMRequest,
      attr.grossCPMRequestUsd,
      attr.grossECPM,
      attr.grossECPMUsd,
      attr.grossRevenue,
      attr.grossRevenueUsd,
      attr.impressions,
      attr.netCPMRequest,
      attr.netCPMRequestUsd,
      attr.netECPM,
      attr.netECPMUsd,
      attr.netRevenue,
      attr.netRevenueUsd,
      attr.requests
    );
  }
}
