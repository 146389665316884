import {
  PERMISSIONS_KEY,
  IS_AUTHENTICATED_KEY,
  TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  USER_KEY,
  LAST_URL_KEY,
  TOKEN_TO_RESET_PASS_KEY,
  USER_AVAILABLE_CONTEXTS,
} from './keys';

const getters = {
  [IS_AUTHENTICATED_KEY]: state => state[IS_AUTHENTICATED_KEY],
  [TOKEN_KEY]: state => state[TOKEN_KEY],
  [REFRESH_TOKEN_KEY]: state => state[REFRESH_TOKEN_KEY],
  [USER_KEY]: state => state[USER_KEY],
  [PERMISSIONS_KEY]: state => state[PERMISSIONS_KEY],
  [LAST_URL_KEY]: state => state[LAST_URL_KEY],
  [TOKEN_TO_RESET_PASS_KEY]: state => state[TOKEN_TO_RESET_PASS_KEY],
  [USER_AVAILABLE_CONTEXTS]: state => state[USER_KEY]?.contextRoles?.map(contextRol => contextRol.context) || [],
};

export default getters;
