import { initialState } from '@/store/modules/settings/initialState';
import { APP_CONFIG_KEY } from '@/store/modules/appConfig/keys';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';

const mutations = {
  [APP_CONFIG_KEY]: (state, payload) => {
    state[APP_CONFIG_KEY] = payload;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[APP_CONFIG_KEY] = initialState()[APP_CONFIG_KEY];
  },
};

export default mutations;
