import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.CONTEXTUAL.baseUrl;

export const create = {
  path: 'create',
  name: `${basePath}-create-cluster`,
  component: () =>
    import(/* webpackChunkName: "co-cluster" */ '@/views/private/modules/contextual/cluster/ClusterEdit'),
  meta: {
    requiresAuth: true,
    title: 'New Cluster',
    breadcrumbs: new BreadcrumbItem('Edit Cluster', { name: `${basePath}-create-cluster ` }, {}),
    props: true,
  },
};
