var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fixed waiting-toast z-100 w-26",
      class: { hidden: _vm.isHidden },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "relative bg-white rounded-lg px-6 py-4 flex shadow-xl justify-start items-center z-100 border border-gray-200",
          class: { "bounce-animation": _vm.status !== _vm.loading },
        },
        [
          _c("div", { staticClass: "flex items-center w-full" }, [
            _c(
              "div",
              {
                staticClass:
                  "p-2 opacity-75 mr-4 inline-block justify-center rounded-full -ml-2",
                class: {
                  "bg-red-200": _vm.status === _vm.error,
                  "bg-green-200": _vm.status === _vm.success,
                  "bg-blue-200": _vm.status === _vm.loading,
                },
              },
              [
                _vm.status === _vm.loading
                  ? _c("sun-loading", { staticClass: "h-4 w-4 text-blue-900" })
                  : _vm.status === _vm.success
                  ? _c("check-svg", { staticClass: "h-4 w-4 text-green-900" })
                  : _c("warning-svg", { staticClass: "h-4 w-4 text-red-900" }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex flex-col max-w-full text-sm" },
              [
                _vm.status === _vm.loading
                  ? _c(
                      "p",
                      { staticClass: "text-blue-700 font-black text-left" },
                      [_vm._v("Please wait...")]
                    )
                  : _vm.status === _vm.success
                  ? _c(
                      "p",
                      { staticClass: "text-green-700 font-black text-left" },
                      [_vm._v("Success!")]
                    )
                  : _c(
                      "p",
                      { staticClass: "text-red-700 font-black text-left" },
                      [_vm._v("Error!")]
                    ),
                _vm._t(
                  "default",
                  function () {
                    return [
                      _c(
                        "p",
                        { staticClass: "text-gray-600 text-left" },
                        [_vm._t("message")],
                        2
                      ),
                    ]
                  },
                  { close: _vm.close }
                ),
              ],
              2
            ),
          ]),
          _vm.status !== _vm.loading
            ? _c(
                "div",
                {
                  staticClass:
                    "absolute font-black close-right cursor-pointer text-gray-600 p-2",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.close()
                    },
                  },
                },
                [_c("close-svg", { staticClass: "h-3 text-gray-700" })],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }