export const LOADING_TOAST_NS = 'loadingToast';

export const SET_STATE_ERROR_KEY = 'setStateError';
export const SET_STATE_SUCCESS_KEY = 'setStateSuccess';
export const SET_STATE_LOADING_TOAST_KEY = 'setStateLoadingToast';
export const LOADING_TOAST_KEY = 'loadingToast';
export const SET_LOADING_TOAST_KEY = 'setLoadingToast';

export const SET_STATE_ERROR_TOAST = `${LOADING_TOAST_NS}/${SET_STATE_ERROR_KEY}`;
export const SET_STATE_SUCCESS_TOAST = `${LOADING_TOAST_NS}/${SET_STATE_SUCCESS_KEY}`;
export const SET_STATE_LOADING_TOAST = `${LOADING_TOAST_NS}/${SET_STATE_LOADING_TOAST_KEY}`;
export const LOADING_TOAST = `${LOADING_TOAST_NS}/${LOADING_TOAST_KEY}`;
export const SET_LOADING_TOAST = `${LOADING_TOAST_NS}/${SET_LOADING_TOAST_KEY}`;
