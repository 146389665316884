import SelectOption from '@/model/shared/SelectOption';

export default class Status extends SelectOption {
  constructor(id, name = '', color = null) {
    super(id, name);
    this.value = id;
    this.color = color;
  }

  isEqual(status) {
    return this.id === status.id;
  }
}
