import { api } from '.';
import { CATEGORY_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import coreEntityParser from '@/entities/Core/CoreEntityParser';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = () => ({
  [CATEGORY_RESOURCE]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:Category[],meta:EndpointMeta}>}
 */
export async function getCategoriesAndSubcategories(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources());
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(coreEntityParser.parse(data), data.meta).build();
}
