import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { list } from '@/router/private/modules/ecommerce/demand/advertiser/list';
import { edit } from '@/router/private/modules/ecommerce/demand/advertiser/edit';
import { create } from '@/router/private/modules/ecommerce/demand/advertiser/create';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;
const breadcrumbs = {
  index: new BreadcrumbItem('Advertisers', { name: `${basePath}-advertisers` }, {}),
  new: new BreadcrumbItem('New', { name: `${basePath}-new-advertiser` }, {}),
  edit: new BreadcrumbItem('Advertiser', {}, {}),
};

export const advertiser = {
  name: `${basePath}-advertiser`,
  path: 'advertiser',
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: breadcrumbs.index,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  children: [list, create, edit],
};
