import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;
const contextSecure = CONTEXTS.SOCIAL_AUDIENCE.id;

export const transactionsList = {
  path: 'transactions-list/:walletId?',
  name: `${basePath}-admin-wallet-transactions`,
  component: () =>
    import(/* webpackChunkName: "sa-wallet" */ '@/views/private/modules/socialAudience/wallet/TransactionsList'),
  meta: {
    requiresAuth: true,
    title: 'Wallet ',
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id],
  },
  props: true,
};
