import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const campaignManagerToday = {
  path: 'campaign-manager-today',
  name: `${basePath}-campaign-manager-today`,
  component: () =>
    import(
      /* webpackChunkName: "tp-campaign-manager-today" */ '@/views/private/modules/thirdParty/campaignManager/campaignManagerToday/CampaignManagerToday.vue'
    ),
  meta: {
    breadcrumbs: new BreadcrumbItem('Campaign Manager Today', { name: `${basePath}-campaign-manager-today` }, {}),
    requiresAuth: true,
    title: 'Campaign Manager Today',
  },
  props: true,
};

export default campaignManagerToday;
