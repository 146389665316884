import { initialState } from '@/store/modules/auth/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import {
  AUTH_ERROR_KEY,
  AUTH_SUCCESS_KEY,
  AUTH_REFRESH_KEY,
  IS_AUTHENTICATED_KEY,
  TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  USER_KEY,
  PERMISSIONS_KEY,
  LAST_URL_KEY,
  TOKEN_TO_RESET_PASS_KEY,
} from './keys';

const mutations = {
  [AUTH_SUCCESS_KEY]: (state, token, refreshToken) => {
    state[IS_AUTHENTICATED_KEY] = true;
    state[TOKEN_KEY] = token;
    state[REFRESH_TOKEN_KEY] = refreshToken;
  },
  [AUTH_ERROR_KEY]: state => {
    state[IS_AUTHENTICATED_KEY] = false;

    state[TOKEN_KEY] = null;
    state[REFRESH_TOKEN_KEY] = null;
  },
  /**
   * Reset the auth store to it's initial state. Since we are relying on cookies to create the initial state, those
   * cookies must be unset before executing the reset mutation.
   *
   * @param state
   * @returns a fresh state
   *
   */
  [RESET_STORE_MODULE_MUTATION]: state => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },

  [AUTH_REFRESH_KEY]: (state, payload) => {
    state[TOKEN_KEY] = payload.token;
    state[REFRESH_TOKEN_KEY] = payload.refreshToken;
    state[IS_AUTHENTICATED_KEY] = true;
  },
  [USER_KEY]: (state, user) => {
    state[USER_KEY] = user;
  },
  [PERMISSIONS_KEY]: (state, modules) => {
    state[PERMISSIONS_KEY] = modules;
  },
  [LAST_URL_KEY]: (state, url) => {
    state[LAST_URL_KEY] = url;
  },
  [TOKEN_TO_RESET_PASS_KEY]: (state, token) => {
    state[TOKEN_TO_RESET_PASS_KEY] = token;
  },
};

export default mutations;
