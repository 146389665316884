import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';
import { demand } from './demand';
import { supply } from './supply';
import { admin } from './admin';
import { profile } from './profile';
import { notFound } from '@/router/private/shared/notFound';
import { analytics } from './analytics';
import { billing } from '@/router/private/modules/ecommerce/billing';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const ecommerce = {
  path: `/${basePath}`,
  redirect: { name: notFound.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: new BreadcrumbItem('Home', { name: 'home' }),
    contextSecure: CONTEXTS.ECOMMERCE.id,
  },
  children: [
    {
      path: `:clientId`,
      component: () => import(/* webpackChunkName: "ec-index" */ '@/views/private/modules/ecommerce/EcommerceIndex'),
      children: [admin, demand, supply, analytics, billing, profile],
      props: true,
    },
  ],
  props: true,
};
