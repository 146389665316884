export const CAMPAIGN_MANAGER_NS = 'campaignManager';

export const SHOW_REPORT_MODAL_KEY = 'showReportModal';
export const SET_STATE_REPORT_MODAL_KEY = 'setStateReportModal';
export const DATA_REPORT_MODAL_KEY = 'dataReportModal';
export const SET_DATA_REPORT_MODAL_KEY = 'setDataReportModal';
export const CLEAR_REPORT_MODAL_KEY = 'clearReportModal';
export const SET_TYPE_REPORT_MODAL_KEY = 'setTypeReportModal';
export const TYPE_REPORT_MODAL_KEY = 'typeReportModal';

export const SHOW_REPORT_MODAL = `${CAMPAIGN_MANAGER_NS}/${SHOW_REPORT_MODAL_KEY}`;
export const SET_STATE_REPORT_MODAL = `${CAMPAIGN_MANAGER_NS}/${SET_STATE_REPORT_MODAL_KEY}`;
export const DATA_REPORT_MODAL = `${CAMPAIGN_MANAGER_NS}/${DATA_REPORT_MODAL_KEY}`;
export const SET_DATA_REPORT_MODAL = `${CAMPAIGN_MANAGER_NS}/${SET_DATA_REPORT_MODAL_KEY}`;
export const CLEAR_REPORT_MODAL = `${CAMPAIGN_MANAGER_NS}/${CLEAR_REPORT_MODAL_KEY}`;
export const SET_TYPE_REPORT_MODAL = `${CAMPAIGN_MANAGER_NS}/${SET_TYPE_REPORT_MODAL_KEY}`;
export const TYPE_REPORT_MODAL = `${CAMPAIGN_MANAGER_NS}/${TYPE_REPORT_MODAL_KEY}`;
