import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const fraud = {
  path: 'fraud',
  name: `${basePath}-duplicate-fraud-template-fraud`,
  component: () =>
    import(/* webpackChunkName: "ec-fraudTemplates" */ '@/components/organisms/modules/ecommerce/fraud/form/Fraud'),

  meta: {
    requiresAuth: true,
    title: 'Duplicate Fraud Template',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id],
  },
  props: true,
};
