/**
 * @typedef Relationships
 * @property {{data:{type:string,id:string}}} any - Relationships object name
 *
 * @typedef EndpointData
 * @property {string} id
 * @property {object} attributes
 * @property {Relationships} [relationships]
 *
 * @typedef EndpointMeta
 * @property {number} totalPages
 * @property {number} totalResults
 * @property {number} size
 *
 * @typedef ResponseBuilderType
 * @property {object|array} data
 * @property {EndpointMeta} meta
 */

/**
 * ResponseBuilder. Useful to give an unified response for api calls
 *
 * @class
 * @constructor
 * @public
 *
 * @typedef ResponseBuilder
 * @property @param {EndpointData|EndpointData[]} data
 * @property @param {EndpointMeta} meta
 */

class ResponseBuilder {
  constructor(data, meta) {
    this.data = data;
    this.meta = meta || {};
  }

  /**
   * @return {ResponseBuilderType}
   */
  build() {
    return {
      data: this.data,
      meta: this.meta,
    };
  }
}
export default ResponseBuilder;
