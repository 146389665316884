import actions from './actions';
import mutations from './mutations';
import state from './state';

const browser = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default browser;
