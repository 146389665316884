import * as RESOURCE from '@/services/keys';
import { EntityParser } from '@/entities/shared/EntityParser';
import { ClientShared } from '@/entities/shared/ClientShared';
import Browser from '@/entities/Core/Browser';
import Category from '@/entities/Core/Category';
import Currency from '@/entities/shared/Currency';
import Device from '@/entities/Core/Device';
import FeedParserProvider from '@/entities/Core/FeedParserProvider';
import Language from '@/entities/Core/Language';
import OperatingSystem from '@/entities/Core/OperatingSystem';
import Region from '@/entities/Core/Region';
import Salesforce from '@/entities/dashboardSSP/Salesforce';
import UserCore from '@/entities/Auth/UserCore';
import { AdvertiserShared } from '@/entities/shared/AdvertiserShared';

const coreEntityParser = new EntityParser({
  [RESOURCE.BROWSER_RESOURCE]: Browser.create,
  [RESOURCE.CATEGORY_RESOURCE]: Category.create,
  [RESOURCE.CLIENT_RESOURCE]: ClientShared.create,
  [RESOURCE.CURRENCY_RESOURCE]: Currency.create,
  [RESOURCE.DEVICE_RESOURCE]: Device.create,
  [RESOURCE.FEED_RESOURCE]: FeedParserProvider.create,
  [RESOURCE.LANGUAGE_RESOURCE]: Language.create,
  [RESOURCE.OPERATING_SYSTEM_RESOURCE]: OperatingSystem.create,
  [RESOURCE.REGION_RESOURCE]: Region.create,
  [RESOURCE.SUBCATEGORIES_RESOURCE]: Category.create,
  [RESOURCE.USER_RESOURCE]: UserCore.create,
  [RESOURCE.ADVERTISER_RESOURCE]: AdvertiserShared.create,
  [RESOURCE.PUBLISHER_RESOURCE]: AdvertiserShared.create, // TODO ake Publisher Shared
  salesforceUser: Salesforce.create,
});

export default coreEntityParser;
