/**
 *  RegionOffice
 *
 * @class
 * @public
 * @constructor
 */

export default class RegionOffice {
  /**
   * @constructor
   * @typedef {Object} RegionOffice
   * @property @param {string} id
   * @property @param {string} name
   */
  constructor(id = null, name = '') {
    this.id = id;
    this.name = name;
  }

  static create(entity) {
    return new RegionOffice(entity.id, entity.attributes.name);
  }
}
