import { CONTEXTS } from '@/model/shared/contexts';
import { EMPTY_PERMISSIONS } from '@/model/shared/constants';

export function setPermissions(user, context = null) {
  let permissions = EMPTY_PERMISSIONS;

  if (context !== null) {
    const contextRole = user.contextRoles.find(permission => permission.context === context) || null;
    if (contextRole?.role && CONTEXTS[context].permissions)
      permissions = CONTEXTS[context].permissions[contextRole.role];
  }
  return permissions;
}
