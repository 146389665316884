import actions from './actions';
import mutations from './mutations';
import state from './state';
import getters from './getters';

const settings = {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};

export default settings;
