import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { workspace } from './workspace';
import { user } from './user';

const basePath = CONTEXTS.CORE.baseUrl;

export const contextBoard = [
  {
    noBreadcrums: true,
    path: '/context',
    redirect: { name: workspace.name },
    name: basePath,
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: {
      requiresAuth: true,
      contextSecure: CONTEXTS.CORE.id,
      rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id],
    },
    children: [
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "tp-index" */ '@/views/private/modules/core/contextBoard/ContextBoardIndex.vue'),
        children: [workspace, ...user],
        props: true,
      },
    ],
    props: true,
  },
];
