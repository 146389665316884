/**
 *  IncludeItemNew
 *
 * @class
 * @public
 * @constructor
 */

export default class IncludeItemNew {
  /**
   * @param {string} type
   * @param {Object} value
   */

  static VALID_TYPES = {
    NONE: 'NONE',
    INCLUDE: 'INCLUDE',
    EXCLUDE: 'EXCLUDE',
  };

  static VALID_TYPES_NAMES = {
    NONE: 'None',
    INCLUDE: 'Include',
    EXCLUDE: 'Exclude',
  };

  constructor(type = IncludeItemNew.VALID_TYPES.NONE, value = {}) {
    this.value = value;

    if (Object.values(IncludeItemNew.VALID_TYPES).includes(type)) {
      this.type = type;
    } else {
      this.type = IncludeItemNew.VALID_TYPES.NONE;
    }
  }

  getTypeName() {
    return IncludeItemNew.VALID_TYPES_NAMES[this.type];
  }

  static deepClone(clone) {
    const value = { ...clone?.value };
    return new IncludeItemNew(clone?.type, value);
  }

  clone() {
    const value = { ...this.value };
    return new IncludeItemNew(this?.type, value);
  }
}
