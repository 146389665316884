import { list } from '@/router/private/modules/dashboardSSP/platforms/list';
import { edit } from '@/router/private/modules/dashboardSSP/platforms/edit';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;

export const platforms = {
  path: 'platforms',
  name: `${basePath}-platform`,
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    requiresAuth: true,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id, ROLES.CLIENT.id],
    breadcrumbs: new BreadcrumbItem('Platforms', { name: `${basePath}-platforms` }, {}),
  },
  children: [list, edit],
  props: true,
};
