import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

const basicInfo = {
  path: '',
  name: `${basePath}-new-publisher-basic-info`,
  component: () =>
    import(
      /* webpackChunkName: "ec-publisher-basic-info" */ '@/components/organisms/modules/ecommerce/publisher/basicInfo/BasicInfo'
    ),

  meta: {
    requiresAuth: true,
    title: 'Publisher',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  props: true,
};

export default basicInfo;
