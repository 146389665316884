/**
 * @module Category
 */
export default class Category {
  /**
   * Create a Category
   * @param {string} id
   * @param {string} name
   * @param {string} category
   * @param {string} group
   * @param {Category[]} subcategories
   */
  constructor(id, name = '', category = '', group = '', subcategories = []) {
    this.id = id;
    this.name = name;
    this.category = category;
    this.group = group;
    this.subcategories = subcategories;
  }

  static create(entity, included) {
    const subcats = included?.subcategories?.filter(c => c.category === entity.id) || null;
    return new Category(
      entity.id,
      entity.attributes.name,
      entity.attributes.category,
      entity.attributes.group,
      subcats
    );
  }
}
