import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;

export const edit = {
  path: ':creativeId',
  name: `${basePath}-edit-creative`,
  component: () =>
    import(
      /* webpackChunkName: "sa-creative" */ '@/components/organisms/modules/socialAudience/campaign/form/creatives/CreativeModalHandler'
    ),
  meta: {
    noBreadcrums: true,
    requiresAuth: true,
    title: 'Campaign',
    contextSecure: CONTEXTS.SOCIAL_AUDIENCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id],
  },
  props: true,
};
