var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { attrs: { viewBox: "0 0 16 16" } }, [
    _c("path", {
      staticClass: "fill-current",
      attrs: {
        d: "M15.1 3.1L12.9.9L8 5.9L3.1.9L.9 3.1l5 4.9l-5 \n    4.9l2.2 2.2l4.9-5l4.9 5l2.2-2.2l-5-4.9z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }