import { cloneDeep } from 'lodash';

/**
 * Function to clone items with no reference
 * to original objects is kept.
 *
 * @see merge
 * @return {Object} Result of all merge properties
 */
export const deepClone = obj => {
  return cloneDeep(obj);
};
