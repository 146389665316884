/**
 * To transform jsonApi response respect includes
 * @param {Array} arrayIncluded
 * @returns {Object} response
 */
export const transformArrayToObject = arrayIncluded => {
  const response = {};
  if (arrayIncluded.length > 0) {
    arrayIncluded.forEach(entityData => {
      if (!response[entityData.type]) response[entityData.type] = {};
      response[entityData.type][entityData.id] = {
        ...{
          id: entityData.id,
          type: entityData.type,
          link: entityData.links,
        },
        ...entityData.attributes,
        ...entityData.relationships,
      };
    });
  }
  return response;
};
