import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { list } from '@/router/private/modules/ecommerce/demand/offer/list';
import { edit } from '@/router/private/modules/ecommerce/demand/offer/edit';
import { create } from '@/router/private/modules/ecommerce/demand/offer/create';
import { show } from '@/router/private/modules/ecommerce/demand/offer/show';
import { duplicate } from '@/router/private/modules/ecommerce/demand/offer/duplicate';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const offer = {
  name: `${basePath}-offer`,
  path: 'offer',
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: new BreadcrumbItem('Offers', { name: `${basePath}-offers` }, {}),
  },
  children: [list, edit, create, show, duplicate],
};
