import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const campaignManagerList = {
  path: 'campaign-manager-list',
  name: `${basePath}-campaign-manager-list`,
  component: () =>
    import(
      /* webpackChunkName: "tp-campaign-manager-list" */ '@/views/private/modules/thirdParty/campaignManager/CampaignManagerList/CampaignManagerList.vue'
    ),
  meta: {
    breadcrumbs: new BreadcrumbItem('Campaign Manager List', { name: `${basePath}-campaign-manager-list` }, {}),
    requiresAuth: true,
    title: 'Campaign Manager List',
  },
  props: true,
};

export default campaignManagerList;
