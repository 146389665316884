import CONFIG from '@/components/organisms/modules/dashboardSSP/platformsDeal/form/dealComments/config';
import CommentShared from '@/model/shared/CommentShared';

/**
 * DealComment
 *
 * @class
 * @public
 */
export default class DealComment extends CommentShared {
  static create(entity, included) {
    return super.create(entity, included, CONFIG.tags);
  }
}
