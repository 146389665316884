import { stats } from './stats';
import { clickId } from './clickId';
import { logs } from './logs';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const analytics = {
  path: 'analytics',
  name: `${basePath}-analytics`,
  redirect: { name: stats.name },
  meta: {
    requiresAuth: true,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id, ROLES.PUBLISHER.id],
  },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [stats, clickId, logs],
  props: true,
};
