import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const ott = {
  path: 'ott',
  name: `${basePath}-ott`,
  component: () =>
    import(/* webpackChunkName: "tp-analytics" */ '@/views/private/modules/thirdParty/analytics/Ott.vue'),
  meta: {
    breadcrumbs: new BreadcrumbItem('Ott', { name: `${basePath}-ott` }, {}),
    requiresAuth: true,
    title: 'Ott',
  },
  props: true,
};

export default ott;
