export const AUTH_NS = 'auth';

export const AUTH_LOGIN_KEY = 'auth_login';
export const AUTH_SUCCESS_KEY = 'auth_success';
export const AUTH_ERROR_KEY = 'auth_error';
export const AUTH_LOGOUT_KEY = 'auth_logout';
export const AUTH_REFRESH_KEY = 'auth_refresh';
export const TOKEN_TO_RESET_PASS_KEY = 'token_to_reset_pass';
export const PERMISSIONS_KEY = 'permissions';
export const IS_AUTHENTICATED_KEY = 'is_authenticated';
export const TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const USER_KEY = 'user';
export const LAST_URL_KEY = 'last_url';
export const USER_AVAILABLE_CONTEXTS = 'context_role';
export const UPDATE_USER_KEY = 'update_user';

export const AUTH_LOGIN = `${AUTH_NS}/${AUTH_LOGIN_KEY}`;
export const AUTH_SUCCESS = `${AUTH_NS}/${AUTH_SUCCESS_KEY}`;
export const AUTH_ERROR = `${AUTH_NS}/${AUTH_ERROR_KEY}`;
export const AUTH_LOGOUT = `${AUTH_NS}/${AUTH_LOGOUT_KEY}`;
export const AUTH_REFRESH = `${AUTH_NS}/${AUTH_REFRESH_KEY}`;
export const TOKEN_TO_RESET_PASS = `${AUTH_NS}/${TOKEN_TO_RESET_PASS_KEY}`;
export const PERMISSIONS = `${AUTH_NS}/${PERMISSIONS_KEY}`;
export const IS_AUTHENTICATED = `${AUTH_NS}/${IS_AUTHENTICATED_KEY}`;
export const TOKEN = `${AUTH_NS}/${TOKEN_KEY}`;
export const REFRESH_TOKEN = `${AUTH_NS}/${REFRESH_TOKEN_KEY}`;
export const USER = `${AUTH_NS}/${USER_KEY}`;
export const LAST_URL = `${AUTH_NS}/${LAST_URL_KEY}`;
export const UPDATE_USER = `${AUTH_NS}/${UPDATE_USER_KEY}`;
export const AUTH_USER_AVAILABLE_CONTEXTS = `${AUTH_NS}/${USER_AVAILABLE_CONTEXTS}`;
