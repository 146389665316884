import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import {
  ANALYTICS_REPORT_TOAST_KEY,
  SET_ANALYTICS_REPORT_TOAST_KEY,
  SET_ANALYTICS_REPORT_TOAST_FINISHED_KEY,
} from './keys';
import { initialState } from './initialState';

const mutations = {
  [SET_ANALYTICS_REPORT_TOAST_FINISHED_KEY]: state => {
    state[ANALYTICS_REPORT_TOAST_KEY].waiting = false;
  },
  [SET_ANALYTICS_REPORT_TOAST_KEY]: (state, analyticsReportToast) => {
    state[ANALYTICS_REPORT_TOAST_KEY] = analyticsReportToast;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[ANALYTICS_REPORT_TOAST_KEY] = initialState()[ANALYTICS_REPORT_TOAST_KEY];
  },
};

export default mutations;
