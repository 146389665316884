import {
  BREAKDOWN_KEY,
  COLUMN_CONFIG_KEY,
  FILTER_LIST_KEY,
  ITEMS_PER_PAGE_KEY,
  QUICK_FILTER_LIST_KEY,
  SORTER_LIST_KEY,
} from './keys';

const getters = {
  // TODO call directly from state
  [FILTER_LIST_KEY]: state => state[FILTER_LIST_KEY],
  [QUICK_FILTER_LIST_KEY]: state => idView => state[QUICK_FILTER_LIST_KEY].get(idView),
  // TODO call directly from state
  [BREAKDOWN_KEY]: state => idView => state[BREAKDOWN_KEY].get(idView),
  [COLUMN_CONFIG_KEY]: state => idView => state[COLUMN_CONFIG_KEY].get(idView),
  [ITEMS_PER_PAGE_KEY]: state => idView => state[ITEMS_PER_PAGE_KEY].get(idView),
  [SORTER_LIST_KEY]: state => idView => state[SORTER_LIST_KEY].get(idView),
};

export default getters;
