import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { CONTEXT_BOARD_BASE } from '@/model/shared/constants';

const basePath = CONTEXT_BOARD_BASE;

export const user = [
  {
    noBreadcrums: true,
    path: 'users',
    name: `${basePath}-users`,
    component: () =>
      import(
        /* webpackChunkName: "contextboard" */ '@/views/private/modules/core/contextBoard/users/ContextBoardUsers.vue'
      ),
    meta: {
      requiresAuth: true,
      title: 'Users',
      loadingIcon: true,
      contextSecure: CONTEXTS.CORE.id,
      rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id],
    },
    children: [
      {
        path: 'new',
        name: `${basePath}-new-user`,
        component: () =>
          import(/* webpackChunkName: "tp-index" */ '@/components/organisms/modules/core/user/UserModal.vue'),
        meta: {
          requiresAuth: true,
          contextSecure: CONTEXTS.CORE.id,
          rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id],
          title: 'New User',
        },
      },
      {
        noBreadcrums: true,
        path: 'user/:id/edit',
        name: `${basePath}-edit-user`,
        component: () =>
          import(/* webpackChunkName: "contextboard" */ '@/components/organisms/modules/core/user/UserModal.vue'),
        meta: {
          requiresAuth: true,
          contextSecure: CONTEXTS.CORE.id,
          rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id],
          title: 'User Edit',
        },
      },
    ],
  },
];
