import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.CONTEXTUAL.baseUrl;

export const list = {
  path: 'list',
  name: `${basePath}-cluster-list`,
  component: () =>
    import(/* webpackChunkName: "co-cluster" */ '@/views/private/modules/contextual/cluster/ClusterList'),
  meta: {
    requiresAuth: true,
    title: 'Clusters List',
    contextSecure: CONTEXTS.CONTEXTUAL.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.SALES.id, ROLES.AD_OPS.id],
    loadingIcon: true,
  },
  props: true,
};
