import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { list } from './list';
import { create } from './create';
import { edit } from './edit';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;
const breadcrumbs = {
  index: new BreadcrumbItem('Campaigns', { name: `${basePath}-campaigns` }, {}),
};

export const campaign = {
  name: `${basePath}-campaign`,
  path: 'campaign',
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: breadcrumbs.index,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  children: [list, create, edit],
};
