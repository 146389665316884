export const getDomain = () => {
  if (window.location.hostname === 'localhost') {
    return window.location.hostname;
  }

  return process.env.VUE_APP_DOMAIN;
};

export const isCustomDomain = () => {
  return window.location.hostname !== process.env.VUE_APP_API_URL && window.location.hostname !== 'localhost';
};

export const customImgTypes = {
  BG_DARK: 'bck_dark.png',
  BG_LIGHT: 'bck_light.png',
  FAVICON: 'favicon.ico',
};
