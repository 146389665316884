import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { transactionsList } from '@/router/private/modules/socialAudience/admin/wallet/transactionsList';
import { clientList } from '@/router/private/modules/socialAudience/admin/wallet/clientList';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;
const contextSecure = CONTEXTS.SOCIAL_AUDIENCE.id;

export const wallet = {
  name: `${basePath}-admin-wallet`,
  path: 'wallet',
  redirect: { name: transactionsList.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    requiresAuth: true,
    title: 'Wallet ',
    breadcrumbs: new BreadcrumbItem('Wallet', { name: `${basePath}-admin-wallet` }, {}),
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id],
  },
  props: true,
  children: [transactionsList, clientList],
};
