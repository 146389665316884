import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';
import { notFound } from '@/router/private/shared/notFound';
import { cluster } from '@/router/private/modules/contextual/cluster';

const basePath = CONTEXTS.CONTEXTUAL.baseUrl;
const contextSecure = CONTEXTS.CONTEXTUAL.id;

export const contextual = {
  path: `/${basePath}`,
  redirect: { name: notFound.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: new BreadcrumbItem('Home', { name: 'home' }, {}),
    contextSecure,
  },
  children: [
    {
      path: `:clientId`,
      component: () => import(/* webpackChunkName: "co-index" */ '@/views/private/modules/contextual/ContextualIndex'),
      children: [cluster],
      props: true,
    },
  ],
  props: true,
};
