import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;
export const countryList = {
  path: 'countries',
  name: `${basePath}-country-list`,
  component: () =>
    import(/* webpackChunkName: "ec-countryList" */ '@/views/private/modules/ecommerce/country/CountryList'),
  meta: {
    requiresAuth: true,
    title: 'Country list',
    breadcrumbs: new BreadcrumbItem('Country list', { name: `${basePath}-country-list` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
};
