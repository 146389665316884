import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { getDevicesSmart, getDevicesVideoPlaza } from '@/services/modules/Core/userAgent';
import {
  DEVICES_SMART_KEY,
  DEVICES_VIDEO_PLAZA_KEY,
  GET_DEVICES_SMART_REQUEST_KEY,
  GET_DEVICES_VIDEO_PLAZA_REQUEST_KEY,
} from './keys';

const actions = {
  [GET_DEVICES_SMART_REQUEST_KEY]: async ({ state, commit }) => {
    try {
      if (state[DEVICES_SMART_KEY].length) return state[DEVICES_SMART_KEY];
      const { data } = await getDevicesSmart();
      commit(DEVICES_SMART_KEY, data);
    } catch (error) {
      if (error.code !== HTTPStatusCode.Cancel) throw error;
    }
  },
  [GET_DEVICES_VIDEO_PLAZA_REQUEST_KEY]: async ({ state, commit }) => {
    try {
      if (state[DEVICES_VIDEO_PLAZA_KEY].length) return state[DEVICES_VIDEO_PLAZA_KEY];
      const { data } = await getDevicesVideoPlaza();
      commit(DEVICES_VIDEO_PLAZA_KEY, data);
    } catch (error) {
      if (error.code !== HTTPStatusCode.Cancel) throw error;
    }
  },
};

export default actions;
