/**
 * @module Country
 */
export default class Country {
  /**
   * Create a Country
   * @param {string|null} id
   * @param {string|null} name
   * @param {string} iso3
   * @param {string} currencyName
   * @param {string} currencyCode
   * @param {string} continent
   * @param {number} isoNumeric
   * @param {number} geonameId
   * @param {{latitude:number, longitude:number}} coordinates
   * @param {object|null} platform
   */
  constructor(
    id,
    name = '',
    iso3 = '',
    currencyName = '',
    currencyCode = '',
    continent = '',
    isoNumeric = null,
    geonameId = null,
    coordinates = { latitude: null, longitude: null },
    platform = null
  ) {
    this.id = id;
    this.name = name;
    this.isoNumeric = isoNumeric;
    this.geonameId = geonameId;
    this.iso3 = iso3;
    this.currencyName = currencyName;
    this.currencyCode = currencyCode;
    this.continent = continent;
    this.coordinates = coordinates;
    this.platform = platform;
  }
}
