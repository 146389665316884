import { initialState } from '@/store/modules/country/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import { OS_SMART_KEY, OS_VIDEO_PLAZA_KEY } from './keys';

const mutations = {
  [OS_SMART_KEY]: (state, payload) => {
    state[OS_SMART_KEY] = payload;
  },
  [OS_VIDEO_PLAZA_KEY]: (state, payload) => {
    state[OS_VIDEO_PLAZA_KEY] = payload;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[OS_SMART_KEY] = initialState()[OS_SMART_KEY];
    state[OS_VIDEO_PLAZA_KEY] = initialState()[OS_VIDEO_PLAZA_KEY];
  },
};

export default mutations;
