import { api } from '.';
import { CONTENT_TARGETING } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';

export const getResources = () => ({
  [CONTENT_TARGETING]: undefined,
});

/**
 * @returns {Promise.<{data:Array<ContentTargeting>, meta:undefined}>}
 */
export async function getContentTargeting() {
  const partialUrl = api.createUrl(getResources());
  const data = await api.get(partialUrl);
  return new ResponseBuilder(socialAudienceEntityParser.parse(data.data)).build();
}
