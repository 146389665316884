export const TOAST_TYPES = {
  INFO: 'info',
  WARNING: 'warn',
  SUCCESS: 'success',
  ERROR: 'error',
};

export class Toast {
  constructor(title = '', message = '', type = TOAST_TYPES.INFO) {
    this.id = Date.now();
    this.title = title;
    this.message = message;
    this.type = type;
  }

  /**
   *
   * @param {string|undefined} title
   * @param {string|undefined} message
   * @returns {Toast}
   */
  static success(title = undefined, message = undefined) {
    return new Toast(title, message, TOAST_TYPES.SUCCESS);
  }

  /**
   *
   * @param {string|undefined} title
   * @param {string|undefined} message
   * @returns {Toast}
   */
  static info(title = undefined, message = undefined) {
    return new Toast(title, message, TOAST_TYPES.INFO);
  }

  /**
   *
   * @param {string|undefined} title
   * @param {string|undefined} message
   * @returns {Toast}
   */
  static warning(title = undefined, message = undefined) {
    return new Toast(title, message, TOAST_TYPES.WARNING);
  }

  /**
   *
   * @param {string|undefined} title
   * @param {string|undefined} message
   * @returns {Toast}
   */
  static error(title = undefined, message = undefined) {
    return new Toast(title, message, TOAST_TYPES.ERROR);
  }
}
