import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;
const contextSecure = CONTEXTS.ECOMMERCE.id;

export const create = {
  path: 'new',
  name: `${basePath}-new-campaign`,
  component: () =>
    import(/* webpackChunkName: "ec-campaign-view" */ '@/views/private/modules/ecommerce/campaign/CampaignEdit'),
  meta: {
    title: 'New Campaign ',
    requiresAuth: true,
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
    breadcrumbs: new BreadcrumbItem('New Campaign', { name: `${basePath}-new-campaign` }, {}),
  },
  props: true,
};
