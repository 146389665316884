/**
 * Metric
 * @constructor
 * @public
 */
export default class Metric {
  /**
   * @typedef {object} global
   * @property {number} global.impressions // Analytic metrics globals
   * @property {number} global.clicks     // Analytic metrics globals
   *
   *
   * @typedef {object} cost
   * @property {string} cost.amount
   * @property {string} cost.currency
   *
   * @construcor
   * @typedef {object} Metric
   *
   * @property @param {calculated} calculated
   * @property @param {global} global
   */
  constructor(global = {}, cost = {}) {
    this.global = global;
    this.cost = cost;
  }

  /**
   *
   * @param {{id:string, attributes: Metric}} entity
   * @return {Metric}
   */
  static create(entity) {
    return new Metric(entity.attributes.global, entity.attributes.cost);
  }
}
