import { NATIVE_TYPE, VIDEO_TYPE } from '@/model/modules/socialAudience/campaign/CampaignTypes';
import CreativeNative from '@/entities/socialAudience/CreativeNative';
import CreativeVideo from '@/entities/socialAudience/CreativeVideo';

export default class CreativeFactory {
  /**
   * Get a Creative class based on campaign type
   * @param {object} entity
   * @returns {CreativeNative|CreativeVideo}
   */
  static create(entity) {
    const type = entity.attributes.type;

    switch (type) {
      case NATIVE_TYPE.id:
        return CreativeNative.create(entity);
      case VIDEO_TYPE.id:
        return CreativeVideo.create(entity);
      default:
        throw Error(`There is not creative for ${type} type`);
    }
  }
}
