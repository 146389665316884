export const DEVICE_NS = 'device';

export const GET_DEVICES_SMART_REQUEST_KEY = 'get_devices_smart_request';
export const GET_DEVICES_VIDEO_PLAZA_REQUEST_KEY = 'get_devices_video_plaza_request';
export const DEVICES_SMART_KEY = 'devices_smart';
export const DEVICES_VIDEO_PLAZA_KEY = 'devices_video_plaza';

export const GET_DEVICES_SMART_REQUEST = `${DEVICE_NS}/${GET_DEVICES_SMART_REQUEST_KEY}`;
export const GET_DEVICES_VIDEO_PLAZA_REQUEST = `${DEVICE_NS}/${GET_DEVICES_VIDEO_PLAZA_REQUEST_KEY}`;
export const DEVICES_SMART = `${DEVICE_NS}/${DEVICES_SMART_KEY}`;
export const DEVICES_VIDEO_PLAZA = `${DEVICE_NS}/${DEVICES_VIDEO_PLAZA_KEY}`;
