import { users } from './users';
import { blockList } from './blocks';
import { countryList } from './countries';
import { fraudTemplates } from './fraudTemplates';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const admin = {
  path: 'admin',
  name: `${basePath}-admin`,
  redirect: { name: users.name },
  meta: {
    requiresAuth: true,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [users, blockList, countryList, fraudTemplates],
  props: true,
};
