import { api } from '.';
import { USER_RESOURCE, PROFILE_RESOURCE } from '@/services/keys';

/**
 *
 * @param {User} user
 */
export async function updateProfile(user) {
  const partialUrl = api.createUrl({
    [USER_RESOURCE]: undefined,
    [PROFILE_RESOURCE]: undefined,
  });
  return await api.update(partialUrl, user);
}
