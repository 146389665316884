import { ROLES } from '@/model/shared/roles';

export const DASHBOARD_SSP = {
  [ROLES.ADMIN.id]: {
    CREATE: ['USER', 'CLIENT', 'COMMENT'],
    DELETE: ['USER', 'CLIENT'],
    SHOW_LIST: ['USER', 'CLIENT'],
    SHOW: ['USER', 'CLIENT'],
    EDIT: ['USER', 'CLIENT'],
  },
  [ROLES.AD_OPS.id]: {
    CREATE: ['CLIENT', 'COMMENT'],
    DELETE: [],
    SHOW_LIST: ['CLIENT'],
    SHOW: ['CLIENT'],
    EDIT: ['CLIENT'],
  },
  [ROLES.CLIENT.id]: {
    CREATE: ['USER', 'COMMENT'],
    DELETE: ['USER'],
    SHOW_LIST: ['USER', 'CLIENT'],
    SHOW: ['USER', 'CLIENT'],
    EDIT: ['USER', 'CLIENT'],
  },
  [ROLES.SELLER.id]: {
    CREATE: ['USER', 'COMMENT'],
    DELETE: ['USER'],
    SHOW_LIST: ['USER', 'CLIENT'],
    SHOW: ['USER', 'CLIENT'],
    EDIT: ['USER', 'CLIENT'],
  },
  // ADVERTISER: {},
  // FINANCE: {},
};
