import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.CONTEXTUAL.baseUrl;

export const edit = {
  path: ':clusterId/edit',
  name: `${basePath}-edit-cluster`,
  component: () =>
    import(/* webpackChunkName: "co-cluster" */ '@/views/private/modules/contextual/cluster/ClusterEdit'),
  meta: {
    requiresAuth: true,
    title: 'Cluster',
    breadcrumbs: new BreadcrumbItem('Edit Cluster', { name: `${basePath}-edit-cluster ` }, {}),
    props: true,
  },
};
