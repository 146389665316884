<template>
  <wait-toast :value="!!loadingToast" :status="toastStatus" @close="closeToast()">
    <template #default="{ close }">
      <p class="text-gray-600 text-left">
        {{ textSelected }}
      </p>
      <div
        v-if="toastStatus !== loading"
        class="flex w-full min-h-full justify-start items-center text-gray-700 font-bold relative bottom-0 text-sm"
        @click="close()"
      >
        <router-link v-if="routeEdit" :to="routeEdit" class="p-2 cursor-pointer text-orange-600"> Edit </router-link>
        <span
          v-if="loadingToast && loadingToast.showReload"
          class="p-2 rounded cursor-pointer ml-1"
          @click="reloadOnPage()"
          >Reload</span
        >
      </div>
    </template>
  </wait-toast>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTIVE_CLIENT, ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { LOADING_TOAST, SET_LOADING_TOAST } from '@/store/modules/loadingToast/keys';
import WaitToast from '@/components/atoms/WaitToast';
import { LOADING_TOAST_STATUS } from '@/model/shared/LoadingToastStatus';

export default {
  name: 'LoadingToast',
  components: {
    WaitToast,
  },
  data() {
    return {
      success: LOADING_TOAST_STATUS.SUCCESS,
      error: LOADING_TOAST_STATUS.ERROR,
      loading: LOADING_TOAST_STATUS.LOADING,
    };
  },
  computed: {
    ...mapGetters({
      loadingToast: LOADING_TOAST,
      activeClient: ACTIVE_CLIENT,
      activeContext: ACTIVE_CONTEXT,
    }),
    textSelected() {
      let textToReturn = '';
      switch (this.loadingToast?.status) {
        case this.loading:
          textToReturn = `${this.loadingToast?.textLoading}...`;
          break;
        case this.success:
          textToReturn = this.loadingToast?.textSuccess;
          break;
        case this.error:
          textToReturn = this.loadingToast?.textError;
          break;
      }
      return textToReturn;
    },
    toastStatus() {
      return this.loadingToast?.status;
    },
    routeEdit() {
      return this?.loadingToast?.nameEntity && this?.canEdit
        ? {
            name: `${this.activeContext.baseUrl}-edit-${this?.loadingToast?.nameEntity}`,
            params: { id: this?.loadingToast?.entityObject?.id },
          }
        : null;
    },
    canEdit() {
      return !!this?.loadingToast?.canEdit;
    },
  },
  watch: {
    toastStatus(newStatus) {
      if (newStatus === this.success || newStatus === this.error) {
        setTimeout(this.closeToast, 5000);
      }
    },
  },
  methods: {
    ...mapActions({
      setLoadingToast: SET_LOADING_TOAST,
    }),
    closeToast() {
      this.setLoadingToast();
    },
    reloadOnPage() {
      this.$router.push({
        name: `${this.activeContext.baseUrl}-${this?.loadingToast?.nameEntity}s`,
      });
      window.location.reload();
    },
  },
};
</script>
