import numeral from 'numeral';
import 'numeral/locales/es-es';
import numFormat from 'vue-filter-number-format';

export default function numberFormatEs(value, format) {
  numeral.locale('es-es');
  let amount = typeof value === 'string' ? parseFloat(value) : value;
  amount = isNaN(amount) ? 0 : amount;
  const result = numFormat(numeral)(amount, format);
  numeral.reset();
  return result;
}
