import { getToken } from '@/utils/user/getToken';
import { Cookies } from '@/model/shared/Cookies';
import {
  IS_AUTHENTICATED_KEY,
  LAST_URL_KEY,
  PERMISSIONS_KEY,
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
  TOKEN_TO_RESET_PASS_KEY,
  USER_KEY,
} from '@/store/modules/auth/keys';

const cookies = new Cookies();

export const initialState = () => ({
  [IS_AUTHENTICATED_KEY]: !!getToken(),
  [TOKEN_KEY]: getToken() || null,
  [REFRESH_TOKEN_KEY]: cookies.getCookie(process.env.VUE_APP_REFRESH_TOKEN_NAME) || null,
  [USER_KEY]: JSON.parse(cookies.getCookie(process.env.VUE_APP_USER_COOKIE_NAME)) || null,
  [TOKEN_TO_RESET_PASS_KEY]: null,
  [PERMISSIONS_KEY]: {},
  [LAST_URL_KEY]: null,
});
