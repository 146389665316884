import { api } from '.';
import { COUNTRIES_RESOURCE } from '@/services/keys';
import Country from '@//entities/Core/Country';

export const getResources = () => ({
  [COUNTRIES_RESOURCE]: undefined,
});

/**
 * @returns {Promise.<{data:Country[],meta:EndpointMeta}>}
 */
export async function getCountries() {
  const partialUrl = api.createUrl(getResources());
  const {
    data: { data, included },
    meta,
  } = await api.get(partialUrl, { all: true });
  return { data: makeClass(data, included), meta };
}

/**
 * @param {EndpointData[]} data
 * @returns {Advertiser}
 */
function makeClass(data = []) {
  return data.map(item => {
    /** @type {Country} */
    const attributes = item.attributes;
    return new Country(
      item.id,
      attributes.name,
      attributes.iso3,
      attributes.currencyName,
      attributes.currencyCode,
      attributes.continent,
      attributes.isoNumeric,
      attributes.geonameId,
      attributes.coordinates,
      attributes.platform
    );
  });
}
