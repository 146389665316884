import { PLATFORM_FEE_RESOURCE } from '@/services/keys';
import Provider from '@/entities/dashboardSSP/Provider';
import { DashboardSSPClient } from './DashboardSSPClient';
import {
  IMPORTING_STATUS,
  PLATFORM_STATUS_DEFAULT,
  PLATFORM_STATUS,
} from '@/model/modules/dashboardSSP/platform/PlatformConnectionStatus';

export const MIN_HOURS_SINCE_LAST_IMPORT = 1;

/**
 * Platform
 * @class
 * @constructor
 * @public
 */
export default class Platform {
  /**
   * @constructor
   * @typedef {object} Platform
   * @property @param {string} id
   * @property @param {string} name
   * @property @param {object} provider
   * @property @param {object} client
   * @property @param {PlatformFee[]} platformFees,
   * @property @param {string} status
   * @property @param {string} updatedAt
   * @property @param {string} lastImport
   * @property @param {EmailIngestion[]} emailIngestions
   */
  constructor(
    id = null,
    name = '',
    provider = new Provider(),
    client = new DashboardSSPClient(),
    platformFees = [],
    status = null,
    updatedAt = null,
    lastImport = null,
    emailIngestions = []
  ) {
    this.id = id;
    this.name = name;
    this.platformFees = platformFees;
    this.provider = provider;
    this.client = client;
    this.status = status || PLATFORM_STATUS_DEFAULT;
    this.updatedAt = updatedAt;
    this.lastImport = lastImport;
    this.emailIngestions = emailIngestions;
  }

  reload() {
    this.status = IMPORTING_STATUS;
  }

  canReloadByStatusRestriction() {
    return this.status.id !== IMPORTING_STATUS.id;
  }

  canReloadByTimeRestriction() {
    if (!this.lastImport) return true;
    const newDate = new Date();
    const dateNow = new Date(
      newDate.getUTCFullYear(),
      newDate.getUTCMonth(),
      newDate.getUTCDate(),
      newDate.getUTCHours(),
      newDate.getUTCMinutes(),
      newDate.getUTCSeconds()
    );
    return Math.abs(dateNow.getTime() - new Date(this.lastImport).getTime()) / 36e5 > MIN_HOURS_SINCE_LAST_IMPORT;
  }

  payload() {
    const { name } = this;

    return { name };
  }

  /**
   *
   * @param {{id:string, attributes: Platform}} entity
   * @param {Object} included
   * @returns {Platform}
   */
  static create(entity, included) {
    const attr = entity.attributes;
    const provider = included?.providers?.find(({ id }) => id === attr.providerId);
    const client = included?.clients?.find(({ id }) => id === attr.clientId);
    const platformFees = included?.[PLATFORM_FEE_RESOURCE]?.filter(({ platformId }) => platformId === entity.id).sort(
      (a, b) => b.startDate - a.startDate
    );
    const status = PLATFORM_STATUS.find(status => status.id === attr.status);
    const emails = included?.emails?.filter(email =>
      entity.relationships.emails?.data.some(emailRelated => emailRelated.id === email.id)
    );

    return new Platform(
      entity.id,
      attr.name,
      provider || new Provider(attr.providerId, attr.providerName),
      client,
      platformFees,
      status,
      attr.updatedAt,
      attr.lastImport,
      emails
    );
  }
}
