import { CONTEXTS } from '@/model/shared/contexts';

export class ClientShared {
  constructor(
    id = '',
    name = '',
    email = '',
    type = CONTEXTS.CORE.id,
    contactName = '',
    isExternal = false,
    users = [],
    taxId = ''
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.type = type;
    this.contactName = contactName;
    this.isExternal = isExternal;
    this.users = users;
    this.taxId = taxId;
  }

  toString() {
    return this.name || this.email || '';
  }

  setUsersIds(arrayUsers) {
    this.users = arrayUsers;
  }

  /**
   *
   * @param {{id:string, attributes: ClientShared}} entity
   * @param object included
   * @return {UserCore}
   */
  static create(entity) {
    return new ClientShared(
      entity.id,
      entity.attributes.name,
      entity.attributes.email,
      entity.attributes.type,
      entity.attributes.contactName,
      entity.attributes.isExternal,
      undefined,
      entity.attributes.taxId
    );
  }
}
