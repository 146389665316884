var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("wait-toast", {
    attrs: { value: !!_vm.loadingToast, status: _vm.toastStatus },
    on: {
      close: function ($event) {
        return _vm.closeToast()
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ close }) {
          return [
            _c("p", { staticClass: "text-gray-600 text-left" }, [
              _vm._v(" " + _vm._s(_vm.textSelected) + " "),
            ]),
            _vm.toastStatus !== _vm.loading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex w-full min-h-full justify-start items-center text-gray-700 font-bold relative bottom-0 text-sm",
                    on: {
                      click: function ($event) {
                        return close()
                      },
                    },
                  },
                  [
                    _vm.routeEdit
                      ? _c(
                          "router-link",
                          {
                            staticClass: "p-2 cursor-pointer text-orange-600",
                            attrs: { to: _vm.routeEdit },
                          },
                          [_vm._v(" Edit ")]
                        )
                      : _vm._e(),
                    _vm.loadingToast && _vm.loadingToast.showReload
                      ? _c(
                          "span",
                          {
                            staticClass: "p-2 rounded cursor-pointer ml-1",
                            on: {
                              click: function ($event) {
                                return _vm.reloadOnPage()
                              },
                            },
                          },
                          [_vm._v("Reload")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }