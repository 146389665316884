/* eslint-disable no-console */

import { register } from 'register-service-worker';

export const INIT_SERVICE_WORKER = enviroment => {
  // console.log(enviroment);
  if (enviroment !== 'development' && 'serviceWorker' in navigator) {
    register('/service-worker.js', {
      registrationOptions: { scope: './' },
      ready() {
        console.log('Version:', process.env.VUE_APP_VERSION);
        // console.log(process);
        // console.log('Service worker is active.', );
      },
      registered() {
        // console.log('Service worker has been registered.', );
      },
      cached() {
        // console.log('Content has been cached for offline use.', );
      },
      updatefound() {
        // console.log('New content is downloading.', );
      },
      async updated(registration) {
        await registration.update();
        console.log('Service Worker updated.');
        window.location.reload();
      },
      offline() {
        // console.log('No internet connection found. App is running in offline mode.');
      },
      error() {
        // console.error('Error during service worker registration:', error);
      },
    });
  }
};
