import {
  SET_ANALYTICS_REPORT_TOAST_KEY,
  SET_ANALYTICS_REPORT_TOAST_FINISHED_KEY,
  DELETE_ANALYTICS_REPORT_TOAST_KEY,
  ANALYTICS_REPORT_TOAST_KEY,
} from './keys';
import { initialState } from './initialState';

const actions = {
  [SET_ANALYTICS_REPORT_TOAST_FINISHED_KEY]: ({ commit }) => {
    commit(SET_ANALYTICS_REPORT_TOAST_FINISHED_KEY);
  },
  [SET_ANALYTICS_REPORT_TOAST_KEY]: ({ commit }, { id, format, client, reportEndpoints }) => {
    commit(SET_ANALYTICS_REPORT_TOAST_KEY, { id, format, waiting: true, client, reportEndpoints });
  },
  [DELETE_ANALYTICS_REPORT_TOAST_KEY]: ({ commit }) => {
    commit(SET_ANALYTICS_REPORT_TOAST_KEY, initialState()[ANALYTICS_REPORT_TOAST_KEY]);
  },
};

export default actions;
