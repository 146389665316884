import { getSettings as getSharedSettings } from '@/services/shared/getSettings';
import { api } from '.';

export function getSettings(preventKey = 'get-user-settings', preventCancel = false, responseType = null) {
  const config = {
    preventKey,
    preventCancel: preventCancel ?? false,
    responseType: responseType ?? null,
  };

  return getSharedSettings(api, config);
}
