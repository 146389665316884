/**
 *  Salesforce
 *
 * @class
 * @public
 * @constructor
 */

export default class Salesforce {
  /**
   * @constructor
   * @typedef {object} Salesforce
   * @property @param {string} id
   * @property @param {string} name
   * @property @param {string} email
   */
  constructor(id = null, name = '', email = '') {
    this.id = id;
    this.name = name;
    this.email = email;
  }

  /**
   * This method is using on entity parse
   * @param {{id:string, attributes: Salesforce}} entity
   * @return {Salesforce}
   */
  static create(entity) {
    return new Salesforce(entity.id, entity.attributes.name, entity.attributes.email);
  }
}
