import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { comments } from '@/router/private/modules/ecommerce/demand/offer/showDetails/comments';
import { history } from '@/router/private/modules/ecommerce/demand/offer/showDetails/history';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const show = {
  path: ':offerId/show',
  redirect: { name: history.name },
  name: `${basePath}-show-offer`,
  component: () => import(/* webpackChunkName: "ec-offers" */ '@/views/private/modules/ecommerce/offer/OfferDetails'),
  meta: {
    requiresAuth: true,
    title: 'Offer',
    breadcrumbs: new BreadcrumbItem('Offer', { name: `${basePath}-show-offer` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.PUBLISHER.id],
    loadingIcon: true,
  },
  props: true,
  children: [comments, history],
};
