export const ACCOUNT_RESOURCE = 'accounts';
export const ADVERTISER_LINK_AGENCY_RESOURCE = 'link-agency';
export const ADVERTISER_RESOURCE = 'advertisers';
export const AGENCY_RESOURCE = 'agencies';
export const ALLOCATE_RESOURCE = 'allocate';
export const ANALYTICS_RESOURCE = 'analytics';
export const ASN_RESOURCE = 'asns';
export const SINGLE_ASN_RESOURCE = 'asn';
export const AUTHOR_RESOURCE = 'author';
export const AUTOMATIC_RECHARGE_RESOURCE = 'automaticRecharge';
export const BLOCK_LIST_RESOURCE = 'block-lists';
export const BREAKDOWN_RESOURCE = 'breakdown';
export const BROWSER_RESOURCE = 'browsers';
export const CAMPAIGN_GROUP_RESOURCE = 'campaign-groups'; // TODO singular or plural
export const CAMPAIGNS_ANALYTICS_BREAKDOWN_RESOURCE = 'campaigns-breakdown-analytics';
export const CAMPAIGNS_ANALYTICS_RESOURCE = 'campaigns-analytics';
export const PLATFORM_ANALYTICS_RESOURCE = 'platform-analytics';
export const CAMPAIGNS_GROUP_RESOURCE = 'campaigns-groups';
export const CAMPAIGNS_RESOURCE = 'campaigns';
export const CAPPING_RESOURCE = 'capping';
export const CATEGORY_RESOURCE = 'categories';
export const CLIENT_RESOURCE = 'clients';
export const CLUSTERS_RESOURCE = 'clusters';
export const CLUSTER_RESOURCE = 'cluster';
export const COMMENTS_RESOURCE = 'comments';
export const CONTENT_TARGETING = 'content-targeting';
export const COST_RESOURCE = 'costs';
export const COUNTRIES_RESOURCE = 'countries';
export const COUNTRY_RESOURCE = 'country';
export const COUNT_BLOCKED_REASONS = 'count-blocked-reasons';
export const BLOCKED_REASONS = 'countBlockedReasons';
export const CREATIVE_BANNER_RESOURCE = 'creative-banner';
export const CREATIVE_DEEPLINK_RESOURCE = 'creative-deeplink';
export const CREATIVE_FEED_RESOURCE = 'creative-feed';
export const CREATIVE_HTML_RESOURCE = 'creative-html';
export const CREATIVE_RESOURCE = 'creatives';
export const CREATIVE_NATIVE_RESOURCE = 'native';
export const CREATIVE_VIDEO_RESOURCE = 'video';
export const CREATIVE_TEXTLINK_RESOURCE = 'creative-textlink';
export const CURRENCY_RATES_RESOURCE = 'currency-rates';
export const CURRENCY_RESOURCE = 'currencies';
export const DASHBOARD_GRAPH = 'dashboard-graph';
export const DASHBOARD_GRAPH_ANALYTICS_RESOURCE_RESOURCE = 'dashboard-graph-analytics'; // TODO: delete infavor of "economics-graph-analytics"
export const DASHBOARD_KPIS = 'kpis';
export const DAY_BREAKDOWN_ANALYTICS_RESOURCE = 'day-deal-analytics';
export const DAY_ANALYTICS_RESOURCE = 'day-analytics'; // TODO: delete (use "deal-analytics")
export const DAY_RESOURCE = 'day';
export const DAYS_RESOURCE = 'days';
export const DEAL_ANALYTICS_KPIS_RESOURCE = 'deal-analytics-kpis'; // TODO: delete (use "deal-analytics")
export const DEAL_ANALYTICS_RESOURCE = 'deal-analytics';
export const DAY_ANALYTICS_DOMAIN_RESOURCE = 'day-breakdown-domain'; // TODO: delete (use "deal-analytics")
export const DEAL_BREAKDOWN_ANALYTICS_RESOURCE = 'deal-breakdown-analytics'; // TODO: delete (use "deal-analytics")
export const DEALS_RESOURCE = 'deals'; // TODO: delete
export const DEALS_TRANSACTION_RESOURCE = 'deals-transaction';
export const DEALS_TYPES_RESOURCE = 'deals-types';
export const DELIVERY_RESOURCE = 'delivery';
export const DEVICE_RESOURCE = 'devices';
export const DICTIONARY_RESOURCE = 'dictionary';
export const DOWNLOAD_CREATIVE_RESOURCE = 'download-creatives';
export const DUPLICATE_RESOURCE = 'duplicate';
export const ECONOMICS_ANALYTICS_RESOURCE = 'economics-analytics';
export const ECONOMICS_COUNTRY_ANALYTICS_RESOURCE = 'economics-country-analytics'; // TODO: delete infavor of "economics-analytics"
export const ECONOMICS_COUNTRY_BREAKDOWN_RESOURCE = 'economics-country-breakdown'; // TODO: delete infavor of "economics-analytics"
export const ECONOMICS_DAY_ANALYTICS_RESOURCE = 'economics-day-analytics'; // TODO: delete infavor of "economics-analytics"
export const ECONOMICS_DAY_BREAKDOWN_RESOURCE = 'economics-day-breakdown'; // TODO: delete infavor of "economics-analytics"
export const ECONOMICS_GRAPH_ANALYTICS_RESOURCE_RESOURCE = 'economics-graph-analytics';
export const ECONOMICS_RESOURCE = 'economics';
export const EVENTS_RESOURCE = 'events';
export const FEED_RESOURCE = 'feeds';
export const FILE_RESOURCE = 'file';
export const FILTER_RESOURCE = 'filters';
export const FINISH_RESOURCE = 'finish';
export const FORMAT_RESOURCE = 'formats';
export const FRAUD_RESOURCE = 'fraud';
export const FRAUD_TEMPLATE_RESOURCE = 'fraud-templates';
export const FREQUENCY_RESOURCE = 'frequencies';
export const GEOLOCATION_RESOURCE = 'geolocation';
export const GET_BY_NAME_ID = 'get-by-name-id';
export const HISTORY_RESOURCE = 'history';
export const HEADERS_MAPPER_RESOURCE = 'headers-mapper';
export const IAS_RESOURCE = 'ias';
export const IMPORT_RESOURCE = 'import';
export const IMPORT_MAIL_RESOURCE = 'import-mail';
export const INGESTION_JOB_NOTIFICATIONS_RESOURCE = 'ingestion-job-notifications';
export const INGESTION_RESOURCE = 'ingestion';
export const INSERTION_RESOURCE = 'insertions';
export const KPIS_RESOURCE = 'kpis';
export const LANGUAGE_RESOURCE = 'languages';
export const LINE_INSERTION_RESOURCE = 'line-insertions';
export const LINKED_USER_RESOURCE = 'linked-users';
export const LIST_RESOURCE = 'list';
export const LOGIN_RESOURCE = 'login';
export const OFFER_COMMENTS_RESOURCE = 'offer-comments';
export const OFFER_FRAUD_RESOURCE = 'offer-fraud';
export const OFFER_POST_EVENT_RESOURCE = 'offer-post-event';
export const OFFER_PUBLISHER_RESOURCE = 'offer-publisher';
export const OFFER_RESOURCE = 'offers';
export const OPERATING_SYSTEM_RESOURCE = 'operating-systems';
export const PARAMS_RESOURCE = 'params';
export const PAUSE_RESOURCE = 'pause';
export const PLATFORM_DEALS_RESOURCE = 'platform-deals';
export const PLATFORM_DEALS_STATUS_TYPES_RESOURCE = 'platform-deals-status-types';
export const PLATFORM_FEE_RESOURCE = 'platform-fee';
export const PLATFORM_RESOURCE = 'platforms';
export const PLATFORM_COSTS_RESOURCE = 'platforms-costs';
export const POSITION_RESOURCE = 'positions';
export const POST_EVENT_RESOURCE = 'post-events';
export const POST_EVENT_STATUS_RESOURCE = 'post-event-status';
export const PRICE_RESOURCE = 'price';
export const PRICING_CAP_RESOURCE = 'pricing-caps';
export const PRODUCT_ANALYTIC_RESOURCE = 'products-analytics';
export const PRODUCT_ANALYTICS_BREAKDOWN_RESOURCE = 'products-breakdown-analytics';
export const PRODUCT_METRICS_RESOURCE = 'metrics';
export const PRODUCT_RESOURCE = 'products';
export const PRODUCT_TRAFFIC_RESOURCE = 'product-traffic';
export const PROFILE_RESOURCE = 'profile';
export const PROVIDERS_CONFIG_RESOURCE = 'provider-config';
export const PROVIDERS_RESOURCE = 'providers';
export const PUBLISHER_RESOURCE = 'publishers';
export const PUBLISHER_TRACKERLINK_RESOURCE = 'publisher-tracker-link';
export const PUT_ONLINE_RESOURCE = 'putOnline';
export const RAW_DATA_RESOURCE = 'raw-data';
export const REFRESH_TOKEN_RESOURCE = 'refresh-token';
export const REGION_OFFICE_RESOURCE = 'region-offices';
export const REGION_RESOURCE = 'regions';
export const REPORT_RESOURCE = 'report';
export const REPORTS_RESOURCE = 'reports';
export const SCHEDULE_REPORTS_RESOURCE = 'report-schedules';
export const AUTHORS_RESOURCE = 'authors';
export const RESET_PASSWORD_LOGGED_RESOURCE = 'reset-password';
export const RESET_PASSWORD_NOT_LOGGED_RESOURCE = 'reset-password-not-logged';
export const RESET_PASSWORD_REQUEST_RESOURCE = 'reset-password-request';
export const RESET_EXTRA_PARAMS_RESOURCE = 'reset-extra-params';
export const SALESFORCE_ACCOUNT_RESOURCE = 'salesforce-accounts';
export const SALESFORCE_REGION_OFFICES = 'salesforce-region-offices';
export const SALESFORCE_RESOURCE = 'salesforce';
export const SALESFORCE_USERS_RESOURCE = 'salesforce-users';
export const SALESFORCE_PRODUCT_RESOURCE = 'salesforce-product';
export const SALESFORCE_OPPORTUNITY_RESOURCE = 'salesforce-opportunity';
export const SCHEDULED_RANGE_RESOURCE = 'scheduled-ranges';
export const SELLER_RESOURCE = 'sellers';
export const SETTINGS = 'settings';
export const SITE_RESOURCE = 'sites';
export const STATUS_RESOURCE = 'status';
export const SUBCATEGORIES_RESOURCE = 'subcategories';
export const SYNC_RESOURCE = 'sync';
export const TAGS_RESOURCE = 'tags';
export const TARGETING_RESOURCE = 'targetings';
export const TARGETING_NATIVE_RESOURCE = 'targetings-native';
export const TARGETING_VIDEO_RESOURCE = 'targetings-video';
export const TARGET_RESOURCE = 'target';
export const TECH_FEE_RESOURCE = 'tech-fee';
export const TOTALS_RESOURCE = 'totals';
export const TEMPLATE_RESOURCE = 'template';
export const TRANSACTION_RESOURCE = 'transaction';
export const TRANSACTIONS_RESOURCE = 'transactions';
export const UPLOAD_RESOURCE = 'upload';
export const USER_AGENTS_RESOURCE = 'user-agents';
export const USER_RESOURCE = 'users';
export const USER_SINGLE_RESOURCE = 'user';
export const UTM_DEEPLINK_RESOURCE = 'utm-creative-deeplink';
export const VIRTUAL_RESOURCE = 'virtual';
export const WALLET_RESOURCE = 'wallet';
export const WALLET_TRANSACTIONS_RESOURCE = 'wallet-transactions';
export const WALLETS_RESOURCE = 'wallets';
export const INFO_EVENT_SESSION_ID = 'infoEventSessionId';
export const INFO_EVENT_SESSION_ID_COLLECTION = 'infoEventSessionIdCollection';
export const RAW_DATA = 'raw-data';
export const EVENT_SESSION_ID = 'event-session-id';
export const ALIAS_RESOURCE = 'alias';
export const CLIENTS_ALIAS = 'clientsAlias';
export const OPPORTUNITIES_RESOURCE = 'opportunities';
export const SALESFORCE_OPPORTUNITIES_RESOURCE = 'salesforce-opportunities';
export const LABELS_RESOURCES = 'labels';
export const SALESFORCE_ADVERTISERS_RESOURCE = 'salesforce_advertisers';
export const EMAILS_RESOURCES = 'emails';
export const CAMPAIGN_MANAGER_RESOURCE = 'campaign-manager';
