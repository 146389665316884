import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const campaignManagerStarting = {
  path: 'campaign-manager-starting',
  name: `${basePath}-campaign-manager-starting`,
  component: () =>
    import(
      /* webpackChunkName: "tp-campaign-manager-starting" */ '@/views/private/modules/thirdParty/campaignManager/campaignManagerStarting/CampaignManagerStarting.vue'
    ),
  meta: {
    breadcrumbs: new BreadcrumbItem('Campaign Manager Starting', { name: `${basePath}-campaign-manager-starting` }, {}),
    requiresAuth: true,
    title: 'Campaign Manager Starting',
  },
  props: true,
};

export default campaignManagerStarting;
