import actions from './actions';
import mutations from './mutations';
import state from './state';

const contentTargeting = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default contentTargeting;
