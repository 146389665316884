import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;

export const others = {
  path: 'others',
  name: `${basePath}-others`,
  component: () =>
    import(/* webpackChunkName: "tp-analytics" */ '@/views/private/modules/dashboardSSP/analytics/others/Others.vue'),
  meta: {
    breadcrumbs: new BreadcrumbItem('Others', { name: `${basePath}-others` }, {}),
    requiresAuth: true,
    title: 'Others',
    contextSecure: CONTEXTS.DASHBOARD_SSP.id,
    rolesAccepted: [ROLES.ADMIN.id],
  },
  props: true,
};
