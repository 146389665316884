import { BreadcrumbItem } from '../../../../entities/BreadcrumbItem';

const breadcrumbs = {
  index: new BreadcrumbItem('Profile', { name: 'profile' }, {}),
};

export const profile = {
  path: `profile`,
  name: 'dashboard-ssp-profile',
  component: () => import(/* webpackChunkName: "ds-profile" */ '@/views/private/modules/core/profile/Edit'),
  meta: {
    breadcrumbs: breadcrumbs.index,
    requiresAuth: true,
    title: 'Profile',
  },
  props: true,
};
