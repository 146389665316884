export class BreadcrumbItem {
  constructor(text = '', nameUrl = { name: '' }, params = {}) {
    this.text = text;
    this.nameUrl = nameUrl;
    this.params = params;
  }

  composeRoute() {
    return { ...this.nameUrl, ...this.params };
  }

  setParams(params) {
    this.params = params;
    return this;
  }
}
