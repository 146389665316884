import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { list } from '@/router/private/modules/ecommerce/supply/publisher/list';
import { edit } from '@/router/private/modules/ecommerce/supply/publisher/edit';
import { create } from '@/router/private/modules/ecommerce/supply/publisher/create';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;
const breadcrumbs = {
  index: new BreadcrumbItem('Publishers', { name: `${basePath}-publishers` }, {}),
  new: new BreadcrumbItem('New', { name: `${basePath}-new-publisher` }, {}),
  edit: new BreadcrumbItem('Publisher', {}, {}),
};

export const publisher = {
  name: `${basePath}-publisher`,
  path: 'publisher',
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: breadcrumbs.index,
  },
  children: [list, edit, create],
};
