import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;
export const create = {
  path: 'new',
  name: `${basePath}-new-platform`,
  component: () =>
    import(/* webpackChunkName: "tp-platform" */ '@/views/private/modules/thirdParty/platform/PlatformCreate'),
  meta: {
    title: 'New Platform ',
    requiresAuth: true,
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id, ROLES.CLIENT.id],
    breadcrumbs: new BreadcrumbItem('New Platform', { name: `${basePath}-new-platform` }, {}),
  },
  props: true,
};
