import TargetItem from '@/model/shared/TargetItem';
import IncludeItemNew from '@/model/shared/IncludeItemNew';
import FrequencyCapping from '@/model/modules/socialAudience/targeting/FrequencyCapping';
import { VIDEO_PLAZA } from '@/model/shared/Platforms';

export default class TargetVideo {
  static TARGET_IDS = {
    GEO: 'geoFilter',
    FREQUENCY_CAPPING: 'frequencyCappingFilter',
    BROWSER: 'browserFilter',
    OS: 'OSFilter',
    DEVICE: 'deviceFilter',
    CONTENT_TARGETING: 'contentTargetingFilter',
  };

  /**
   * @param {String} id
   * @param {array} countries
   * @param {array} browsers
   * @param {array} OS
   * @param {array} devices
   */
  constructor(
    id = null,
    countries = [],
    frequencyCapping = [],
    browsers = [],
    OS = [],
    devices = [],
    contentTargeting = []
  ) {
    this.id = id;
    this.geoFilter = new TargetItem(TargetVideo.TARGET_IDS.GEO, 'Geotargeting', countries, true, false);
    this.frequencyCappingFilter = new TargetItem(
      TargetVideo.TARGET_IDS.FREQUENCY_CAPPING,
      'Frequency capping',
      frequencyCapping
    );
    this.browserFilter = new TargetItem(TargetVideo.TARGET_IDS.BROWSER, 'Browsers', browsers, true, false);
    this.OSFilter = new TargetItem(TargetVideo.TARGET_IDS.OS, 'OS', OS, true, false);
    this.deviceFilter = new TargetItem(TargetVideo.TARGET_IDS.DEVICE, 'Devices', devices, true, false);
    this.contentTargetingFilter = new TargetItem(
      TargetVideo.TARGET_IDS.CONTENT_TARGETING,
      'Content targeting',
      contentTargeting,
      true,
      false
    );
  }

  getItems() {
    return [
      this.geoFilter,
      this.frequencyCappingFilter,
      this.browserFilter,
      this.OSFilter,
      this.deviceFilter,
      this.contentTargetingFilter,
    ];
  }

  payload() {
    const { geoFilter, frequencyCappingFilter, browserFilter, OSFilter, deviceFilter, contentTargetingFilter } = this;
    return {
      countries: geoFilter.value.map(item => ({
        externalId: item.value.isoNumeric,
        ...item.value,
      })),
      frequencyCapping: frequencyCappingFilter.value.map(item => item.payload()),
      browsers: browserFilter.value.map(item => ({
        ...item.value,
        externalId: item.value.externalId || item.value.platform[VIDEO_PLAZA.id],
      })),
      devices: deviceFilter.value.map(item => ({
        ...item.value,
        externalId: item.value.externalId || item.value.platform[VIDEO_PLAZA.id],
      })),
      os: OSFilter.value.map(item => ({
        ...item.value,
        externalId: item.value.externalId || item.value.platform[VIDEO_PLAZA.id],
      })),
      content: contentTargetingFilter.value.map(item => ({
        ...item,
        externalId: item.id,
        type: 'include', // XXX TODO: hablar con Hermes para poder enviar IncludeNewItem.VALID_TYPES.INCLUDE
      })),
    };
  }

  clone() {
    return new TargetVideo(
      this.id,
      this.geoFilter.value,
      this.frequencyCapping.value,
      this.browserFilter.value,
      this.OSFilter.value,
      this.deviceFilter.value,
      this.contentTargetingFilter.value
    );
  }

  static create(entity) {
    const { countries, frequencyCapping, browsers, os, devices, content } = entity?.attributes;

    return new TargetVideo(
      entity?.id,
      countries.map(item => new IncludeItemNew(IncludeItemNew.VALID_TYPES.INCLUDE, item)),
      frequencyCapping.map(item => new FrequencyCapping(item.type, item.value)),
      browsers.map(item => new IncludeItemNew(IncludeItemNew.VALID_TYPES.INCLUDE, item)),
      os.map(item => new IncludeItemNew(IncludeItemNew.VALID_TYPES.INCLUDE, item)),
      devices.map(item => new IncludeItemNew(IncludeItemNew.VALID_TYPES.INCLUDE, item)),
      content
    );
  }
}
