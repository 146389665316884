import { stringToDate } from '@/filters/dateFilters';
import SelectOption from '@/model/shared/SelectOption';
import TemplateCreative from '@/model/modules/socialAudience/creative/TemplateCreative';

/**
 * Insertion
 * @class
 * @constructor
 * @public
 */
export default class Insertion {
  /**
   * Create a Insertion
   * @typedef Insertion
   * @property @param {string|null} id
   * @property @param {string} name
   * @property @param {Date} startDate
   * @property @param {Date} endDate
   * @property @param {string} type
   * @property @param {string} budgetType
   * @property @param {Advertiser} budgetGoalType
   * @property @param {string} budgetGoal
   * @property @param {string} strategy
   * @property @param {string} strategyPrice
   * @property @param {TemplateCreative} template
   */
  constructor(
    id = null,
    name = '',
    startDate = '',
    endDate = '',
    type = '',
    budgetType = '',
    budgetGoalType = '',
    budgetGoal = null,
    strategy = '',
    strategyPrice = null,
    template = new TemplateCreative()
  ) {
    this.id = id;
    this.name = name;
    this.startDate = startDate;
    this.endDate = endDate;
    this.type = type;

    this.budgetType = budgetType;
    this.budgetGoalType = budgetGoalType;
    this.budgetGoal = budgetGoal;
    this.strategy = strategy;
    this.strategyPrice = strategyPrice;
    this.template = template;
  }

  payload() {
    return {
      ...this,
      type: this.type.id,
      budgetGoalType: this.budgetGoalType.id,
      template: this.template.payload(),
      start: this.startDate.toJSON().split('T')[0],
      end: this.endDate.toJSON().split('T')[0],
    };
  }

  clone() {
    return new Insertion(
      this.id,
      this.name,
      this.startDate,
      this.endDate,
      this.type,
      this.budgetType,
      this.budgetGoalType,
      this.budgetGoal,
      this.strategy,
      this.strategyPrice,
      this.template
    );
  }

  hasTemplate() {
    return !!this.template.name;
  }

  /**
   * @param {{id:string,attributes:Insertion}} entity
   */
  static create(entity) {
    const attr = entity.attributes;

    return new Insertion(
      entity.id,
      attr.name,
      attr.start ? stringToDate(attr.start) : null,
      attr.end ? stringToDate(attr.end) : null,
      new SelectOption(attr.type.toUpperCase()),
      attr.budgetType.toUpperCase(),
      new SelectOption(attr.budgetGoalType.toUpperCase()),
      attr.budgetGoal,
      attr.strategy.toUpperCase(),
      attr.strategyPrice,
      new TemplateCreative(attr.template.name, attr.template.description)
    );
  }
}
