import { stringToDate, dateToYYYYMMDD } from '@/filters/dateFilters';
import Advertiser from './Advertiser';
import {
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_DEFAULT,
  COMPLETED_STATUS,
} from '@/model/modules/socialAudience/campaign/CampaignStatus';
import { CAMPAIGN_TYPES, CAMPAIGN_TYPE_DEFAULT } from '@/model/modules/socialAudience/campaign/CampaignTypes';
import SelectOption from '@/model/shared/SelectOption';

export default class Campaign {
  /**
   * @typedef Campaign
   * @property @param {string|null} id
   * @property @param {string} name
   * @property @param {Advertiser} advertiser
   * @property @param {Date|null} startDate
   * @property @param {Date|null} endDate
   * @property @param {string} status
   * @property @param {boolean} isSync
   */
  constructor(
    id = null,
    name = '',
    advertiser = new Advertiser(),
    startDate = new Date(),
    endDate = null,
    status = null,
    isSync = false,
    type = null,
    budgetType = '',
    budgetGoalType = '',
    budgetGoal = null,
    strategy = '',
    strategyPrice = null
  ) {
    this.id = id;
    this.name = name;
    this.advertiser = advertiser;
    this.startDate = startDate;
    this.endDate = endDate;
    this.status = status || CAMPAIGN_STATUS_DEFAULT;
    this.isSync = isSync;
    this.type = type || CAMPAIGN_TYPE_DEFAULT;
    this.budgetType = budgetType;
    this.budgetGoalType = budgetGoalType;
    this.budgetGoal = budgetGoal;
    this.strategy = strategy;
    this.strategyPrice = strategyPrice;
    this.insertionId = null;
  }

  payload() {
    const { advertiser, startDate, endDate, type, budgetGoalType, ...params } = this;

    return {
      ...params,
      advertiserId: advertiser?.id,
      start: dateToYYYYMMDD(startDate),
      end: dateToYYYYMMDD(endDate),
      type: type.id,
      budgetGoalType: budgetGoalType.id,
      status: this.status.id,
    };
  }

  isCompleted() {
    return this.status.isEqual(COMPLETED_STATUS);
  }

  typeIsChanged() {
    this.strategy = '';
    this.budgetType = '';
  }

  /**
   * This method past insertion property to campaign property
   * If using insertion like a campaign's property delete it
   * @param {Insertion} insertion
   */
  setInsertion(insertion) {
    this.startDate = this.startDate || insertion.startDate;
    this.endDate = this.endDate || insertion.endDate;
    this.type = insertion.type;
    this.budgetType = insertion.budgetType;
    this.budgetGoalType = insertion.budgetGoalType;
    this.budgetGoal = insertion.budgetGoal;
    this.strategy = insertion.strategy;
    this.strategyPrice = insertion.strategyPrice;
    this.insertionId = insertion.id;
  }

  /**
   *
   * @param {{id:string, attributes: Campaign}} entity
   * @param object included
   * @return {Campaign}
   */
  static create(entity, included) {
    const startDate = entity.attributes.start;
    const endDate = entity.attributes.end;
    const advertiser = included?.advertisers?.find(a => a.id === entity.relationships?.advertiser.data.id);
    const status = CAMPAIGN_STATUS.find(status => status.id === entity.attributes.status);
    const type = CAMPAIGN_TYPES.find(type => type.id === entity.attributes.type);

    return new Campaign(
      entity.id,
      entity.attributes.name,
      advertiser,
      startDate ? stringToDate(startDate) : null,
      endDate ? stringToDate(endDate) : null,
      status,
      entity.attributes.isSync,
      type,
      entity.attributes.budgetType,
      new SelectOption(entity.attributes.budgetGoalType),
      entity.attributes.budgetGoal,
      entity.attributes.strategy,
      entity.attributes.strategyPrice
    );
  }
}
