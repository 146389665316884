import SelectOption from '@/model/shared/SelectOption';

export default class FrequencyCapping {
  static TIME_TYPES = [
    new SelectOption('FIVE_MINUTES', '5 minutes'),
    new SelectOption('TEN_MINUTES', '10 minutes'),
    new SelectOption('QUARTER_HOUR', '15 minutes'),
    new SelectOption('HALF_HOUR', '30 minutes'),
    new SelectOption('HOUR', 'hour'),
    new SelectOption('DAY', 'day'),
    new SelectOption('WEEK', 'week'),
    new SelectOption('MONTH', 'month'),
    new SelectOption('GOAL_LIFETIME', 'goal life time'),
    new SelectOption('CAMPAIGN_LIFETIME', 'campaign life time'),
  ];

  constructor(typeId, value = 0) {
    this.type = FrequencyCapping.TIME_TYPES.find(type => type.id === typeId);
    this.value = value;
  }

  payload() {
    return {
      type: this.type.id,
      value: this.value,
    };
  }
}
