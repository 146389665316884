export default class MoneyAmount {
  constructor(currency = null, value = null) {
    this.currency = currency;
    this.value = value;
  }

  /**
   * Build a MoneyAmount object
   *
   * @param {object} options
   * @param {string|undefined} options.value
   * @param {string|undefined} options.currency
   * @returns
   * @memberof MoneyAmount
   */
  static build(options) {
    return new MoneyAmount(options.currency, options.value);
  }
}
