export const resetPassword = {
  noBreadcrums: true,
  path: '/reset-password',
  name: 'reset-password',
  component: () => import(/* webpackChunkName: "resetPassword" */ '@/views/public/login/ResetPassword.vue'),
  meta: {
    title: 'Reset Password',
    requiresAuth: false,
  },
};
