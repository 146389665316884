import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const performance = {
  path: 'performance-',
  name: `${basePath}-performance`,
  component: () =>
    import(/* webpackChunkName: "tp-analytics" */ '@/views/private/modules/thirdParty/analytics/Performance.vue'),
  meta: {
    breadcrumbs: new BreadcrumbItem('Performance', { name: `${basePath}-performance` }, {}),
    requiresAuth: true,
    title: 'Performance',
  },
  props: true,
};

export default performance;
