import Address from './Address';

/**
 * Advertiser
 * @class
 * @constructor
 * @public
 */
export default class Advertiser {
  /**
   * Create a Advertiser
   * @param {string} id
   * @param {string} name
   * @param {string} email
   * @param {number} activeCampaignsCount
   * @param {number} totalCost
   * @param {number} lastCost
   * @param {string} taxId
   * @param {Address} address
   * @param {Category|null} category
   * @param {Category|null} subcategory
   * @param {Client} client
   */
  constructor(
    client = null,
    id = null,
    name = null,
    email = null,
    activeCampaignsCount = null,
    totalCost = null,
    lastCost = null,
    taxId = null,
    address = new Address(),
    category = null,
    subcategory = null
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.activeCampaignsCount = activeCampaignsCount;
    this.totalCost = totalCost;
    this.lastCost = lastCost;
    this.taxId = taxId;
    this.address = address || new Address();
    this.category = category;
    this.subcategory = subcategory;
    this.client = client;
  }

  payload() {
    const { name, email, taxId, address, category, subcategory, client } = this;

    return {
      name,
      email,
      taxId,
      address: address.payload(),
      categoryId: category?.id,
      subCategoryId: subcategory?.id,
      clientId: client?.id,
    };
  }

  static create(entity, included) {
    const category = included?.categories?.find(cat => cat.id === entity.relationships.category?.data?.id);
    // TODO: Adjust subcategory naming
    const subCategory = (included?.subCategories || included?.subcategories)?.find(
      subCat => subCat.id === entity.relationships.subCategory?.data?.id
    );
    const client = included?.clients?.find(client => client.id === entity.relationships.client?.data?.id);
    const { city, country, county, postalCode, street } = entity.attributes.address;

    return new Advertiser(
      client,
      entity.id,
      entity.attributes.name,
      entity.attributes.email,
      entity.attributes.activeCampaignsCount,
      entity.attributes.totalCost,
      entity.attributes.last30DaysCost,
      entity.attributes.taxId,
      new Address(city, country.iso, county, postalCode, street),
      category,
      subCategory
    );
  }
}
