import { WALLET_TYPE_DEFAULT } from '@/model/modules/socialAudience/wallet/WalletType';

export default class WalletClient {
  /**
   * @param {number} balance
   * @param {boolean} automaticRecharge
   */
  constructor(balance = 0, automaticRecharge = false, type = null) {
    this.balance = balance;
    this.automaticRecharge = automaticRecharge;
    this.type = type || WALLET_TYPE_DEFAULT;
  }

  payload() {
    return this;
  }

  /**
   *
   * @param {{id:string, attributes: WalletClient}} entity
   * @param object included
   * @return {WalletClient}
   */
  static create(entity) {
    const { balance, automaticRecharge, type } = entity;
    return new WalletClient(balance, automaticRecharge, type);
  }
}
