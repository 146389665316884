var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-gray-200 relative", attrs: { id: "app" } },
    [
      _c("transition", { attrs: { name: "fade" } }, [_c("router-view")], 1),
      _c("asterix-toasts"),
      _c("loading-toast"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }