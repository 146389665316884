export const CONTEXTBOARD_NS = 'contextBoard';

export const SAVE_PARAMS_KEY = 'saveParams';
export const PARAM_LIST_KEY = 'params';
export const RESET_CONTEXTBOARD_KEY = 'reset';
export const RESET_PARAMS_EXCEPT_KEY = 'resetParamsExcept';

// context_board/saveFilter
export const SAVE_PARAMS = `${CONTEXTBOARD_NS}/${SAVE_PARAMS_KEY}`;
// context_board/filterList
export const PARAM_LIST = `${CONTEXTBOARD_NS}/${PARAM_LIST_KEY}`;
// context_board/reset
export const RESET_CONTEXTBOARD = `${CONTEXTBOARD_NS}/${RESET_CONTEXTBOARD_KEY}`;
// context_board/resetParamsExcept;
export const RESET_PARAMS_EXCEPT = `${CONTEXTBOARD_NS}/${RESET_PARAMS_EXCEPT_KEY}`;
