import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { CONTEXT_BOARD_BASE } from '@/model/shared/constants';

const basePath = CONTEXT_BOARD_BASE;

export const PATH_WORKSPACES = 'workspaces';
export const workspace = {
  noBreadcrums: true,
  path: PATH_WORKSPACES,
  name: `${basePath}-workspaces`,
  component: () =>
    import(
      /* webpackChunkName: "contextboard" */ '@/views/private/modules/core/contextBoard/workspaces/ContextBoardWorkspaces.vue'
    ),
  meta: {
    requiresAuth: true,
    title: 'Workspaces',
    loadingIcon: true,
    contextSecure: CONTEXTS.CORE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id],
  },
  children: [
    {
      noBreadcrums: true,
      path: 'new',
      name: `${basePath}-new-workspace`,
      component: () => import('@/components/organisms/modules/core/workspace/WorkspaceModal.vue'),
      meta: {
        title: 'New Workspace',
        requiresAuth: true,
        contextSecure: CONTEXTS.CORE.id,
        rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id],
      },
      props: true,
    },
    {
      noBreadcrums: true,
      path: ':clientId/edit',
      name: `${basePath}-edit-workspace`,
      component: () => import('@/components/organisms/modules/core/workspace/WorkspaceModal.vue'),
      meta: {
        requiresAuth: true,
        title: 'Client Edit',
        contextSecure: CONTEXTS.CORE.id,
        rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id],
      },
      props: true,
    },
  ],
};
