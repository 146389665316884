import { branding } from '@/router/private/modules/thirdParty/analytics/branding.js';
import { ott } from '@/router/private/modules/thirdParty/analytics/ott.js';
import { youtube } from '@/router/private/modules/thirdParty/analytics/youtube.js';
import { performance } from '@/router/private/modules/thirdParty/analytics/performance.js';
import { others } from '@/router/private/modules/thirdParty/analytics/others.js';
import { economics } from '@/router/private/modules/thirdParty/analytics/economics.js';
import { CONTEXTS } from '@/model/shared/contexts';
import { skin } from '@/router/private/modules/thirdParty/analytics/skin';
import { kleup } from '@/router/private/modules/thirdParty/analytics/kleup';
import content from '@/router/private/modules/thirdParty/analytics/content';
import precio from '@/router/private/modules/thirdParty/analytics/precio';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const analytics = {
  path: 'analytics',
  name: `${basePath}-analytics`,
  component: () =>
    import(
      /* webpackChunkName: "ds-analytics-economics" */ '@/views/private/modules/thirdParty/analytics/AnalyticsThirdPartyIndex'
    ),
  meta: {
    requiresAuth: true,
    title: 'Analytics',
  },
  children: [branding, performance, others, economics, ott, youtube, skin, content, kleup, precio],
  props: true,
};
