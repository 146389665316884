import actions from './actions';
import mutations from './mutations';
import state from './state';

const operating_system = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default operating_system;
