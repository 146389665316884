import { CREATIVE_STATUS } from '@/model/modules/socialAudience/creative/CreativeStatus';
import SelectOption from '@/model/shared/SelectOption';

/**
 * CreativeVideo
 * @class
 * @constructor
 * @public
 */
export default class CreativeVideo {
  static TYPE_FILE = new SelectOption('file', 'Upload creative now');

  static TYPE_VAST = new SelectOption('vast', '3rd  Party Ad');

  static CREATIVE_TYPES = [CreativeVideo.TYPE_FILE, CreativeVideo.TYPE_VAST];

  static EXTERNAL_TRACKING_TYPES = [
    new SelectOption('IMPRESSION'),
    new SelectOption('CLICK_THROUGH', 'Click Tracking'),
    new SelectOption('AD_START', 'Start'),
    new SelectOption('AD_FIRST_QUARTILE', 'First quartile'),
    new SelectOption('AD_MIDPOINT', 'Midpoint'),
    new SelectOption('AD_THIRD_QUARTILE', 'Third quartile'),
    new SelectOption('AD_COMPLETE', 'Complete'),
    new SelectOption('MUTE'),
    new SelectOption('UNMUTE'),
    new SelectOption('PAUSE'),
    new SelectOption('REWIND'),
    new SelectOption('RESUME'),
    new SelectOption('FULLSCREEN'),
    new SelectOption('EXPAND'),
    new SelectOption('CLOSE'),
    new SelectOption('SKIP'),
  ];

  /**
   * Create a CreativeVideo
   * @param {string} id
   * @param {string} name
   * @param {number} impressions
   * @param {number} views
   * @param {object} status
   * @param {SelectOption} typeCreative
   * @param {File} file
   * @param {string} urlFile
   * @param {string} vast
   * @param {string} destinationUrl
   * @param {string} externalTrackingType
   * @param {string} externalTrackingValue
   */
  constructor(
    id = null,
    name = null,
    impressions = null,
    views = null,
    status = null,
    typeCreative = null,
    file = null,
    urlFile = null,
    vast = null,
    destinationUrl = null,
    externalTracking = []
  ) {
    this.id = id;
    this.name = name;
    this.impressions = impressions;
    this.views = views;
    this.status = status;
    this.typeCreative = typeCreative;
    this.file = file;
    this.urlFile = urlFile;
    this.vast = vast;
    this.destinationUrl = destinationUrl;
    this.externalTracking = externalTracking;
  }

  isCreativeTypeFile() {
    return this.typeCreative && this.typeCreative.isEqual(CreativeVideo.TYPE_FILE);
  }

  isCreativeTypeVast() {
    return this.typeCreative && this.typeCreative.isEqual(CreativeVideo.TYPE_VAST);
  }

  changeType(type) {
    this.typeCreative = type;

    if (!this.typeCreative || this.typeCreative.isEqual(CreativeVideo.TYPE_FILE)) {
      this.vast = null;
    }

    if (!this.typeCreative || this.typeCreative.isEqual(CreativeVideo.TYPE_VAST)) {
      this.destinationUrl = null;
      this.file = null;
      this.urlFile = null;
    }
  }

  addExternalTracker(event, url) {
    this.externalTracking.push({
      event,
      url,
    });
  }

  payload() {
    const body = new FormData();

    body.set('name', this.name);
    body.set('link', this.destinationUrl);
    body.set('status', this.status.value);
    body.set(
      `externalTracker`,
      JSON.stringify(this.externalTracking.map(item => ({ event: item.event.id, url: item.url })))
    );
    if (this.isCreativeTypeFile()) body.set('file', this.file);
    if (this.isCreativeTypeVast()) body.set('vastUrl', this.vast);

    return body;
  }

  static create(entity) {
    const { name, impressions, views, link, externalTrackers, url, vastUrl } = entity.attributes;

    const status = CREATIVE_STATUS.find(status => status.id === entity.attributes.status);

    const externalTracking = externalTrackers.map(item => ({
      event: CreativeVideo.EXTERNAL_TRACKING_TYPES.find(type => type.id === item.event),
      url: item.url,
    }));

    let typeCreative = null;
    if (url) typeCreative = CreativeVideo.TYPE_FILE;
    else if (vastUrl) typeCreative = CreativeVideo.TYPE_VAST;

    return new CreativeVideo(
      entity.id,
      name,
      impressions,
      views,
      status,
      typeCreative,
      null,
      url,
      vastUrl,
      link,
      externalTracking
    );
  }
}
