import { stringToDate } from '@/filters/dateFilters';

/**
 * Platform
 * @class
 * @constructor
 * @public
 */

export default class PlatformFee {
  /**
   * @constructor
   * @typedef {object} PlatformFee
   * @property @param {string} id
   * @property @param {string} platformId
   * @property @param {number} openFee
   * @property @param {number} dealsFee
   * @property @param {Date} startDate
   * @property @param {Date|null} endDate
   * @property @param {boolean} deleted
   */

  constructor(
    id = null,
    platformId = '',
    openFee = null,
    dealsFee = null,
    startDate = null,
    endDate = null,
    deleted = false
  ) {
    this.id = id;
    this.platformId = platformId;
    this.openFee = openFee;
    this.dealsFee = dealsFee;
    this.startDate = startDate;
    this.endDate = endDate;
    this.deleted = deleted;
  }

  payload() {
    const { openFee, dealsFee, startDate, endDate } = this;
    return {
      openFee,
      dealsFee,
      startDate,
      endDate,
    };
  }

  /**
   *
   * @param {{id:string, attributes: Campaign}} entity
   * @return {PlatformFee}
   */
  static create(entity) {
    const startDate = entity.attributes.startDate;
    const endDate = entity.attributes.endDate;
    return new PlatformFee(
      entity.id,
      entity.attributes.platformId,
      entity.attributes.openFee,
      entity.attributes.dealsFee,
      startDate ? stringToDate(startDate) : null,
      endDate ? stringToDate(endDate) : null,
      entity.attributes.deleted
    );
  }
}
