import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';
// import { supply } from './supply';
import { admin } from './admin';
import { platforms } from './platforms';
import { notFound } from '@/router/private/shared/notFound';
import { analytics } from './analytics';
import { profile } from './profile';
import { campaignManager } from './campaignManager';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;
const contextSecure = CONTEXTS.DASHBOARD_SSP.id;

export const dashboardSSP = {
  path: `/${basePath}`,
  redirect: { name: notFound.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: new BreadcrumbItem('Home', { name: 'home' }),
    contextSecure,
  },
  children: [
    {
      path: `:clientId`,
      component: () =>
        import(/* webpackChunkName: "ds-index" */ '@/views/private/modules/dashboardSSP/DashboardSSPIndex'),
      children: [analytics, platforms, admin, /* supply, */ profile, campaignManager],
      props: true,
    },
  ],
  props: true,
};
