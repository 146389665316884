import {
  DATA_REPORT_MODAL_KEY,
  SHOW_REPORT_MODAL_KEY,
  TYPE_REPORT_MODAL_KEY,
} from '@/store/modules/campaignManager/keys';

export const initialState = () => ({
  [SHOW_REPORT_MODAL_KEY]: false,
  [DATA_REPORT_MODAL_KEY]: null,
  [TYPE_REPORT_MODAL_KEY]: null,
});
