import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import analyticsReportToast from '@/store/modules/analyticsReportToast';
import auth from '@/store/modules/auth';
import breadcrumbs from '@/store/modules/breadcrumbs';
import category from '@/store/modules/category';
import context from '@/store/modules/context';
import contextBoard from '@/store/modules/contextBoard';
import country from '@/store/modules/country';
import currency from '@/store/modules/currency';
import filters from '@/store/modules/filters';
import load from '@/store/modules/load';
import loadingToast from '@/store/modules/loadingToast';
import toast from '@/store/modules/toast';
import { createResetStore } from '@/store/utils/createResetStore';
import browser from '@/store/modules/browser';
import operatingSystem from '@/store/modules/operatingSystem';
import device from '@/store/modules/device';
import contentTargeting from '@/store/modules/contentTargeting';
import settings from '@/store/modules/settings';
import campaignManager from '@/store/modules/campaignManager';
import appConfig from '@/store/modules/appConfig';

const debug = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test';

Vue.use(Vuex);

const storeModules = {
  analyticsReportToast,
  auth,
  breadcrumbs,
  category,
  context,
  contextBoard,
  country,
  currency,
  filters,
  load,
  loadingToast,
  toast,
  browser,
  operatingSystem,
  device,
  contentTargeting,
  settings,
  campaignManager,
  appConfig,
};
export const storeOptions = {
  modules: {
    ...storeModules,
    reset: createResetStore(Object.keys(storeModules)),
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [], // set logger only for development
};

export default new Vuex.Store(storeOptions);
