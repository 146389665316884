/**
 * @module Device
 */
export default class Device {
  /**
   * Create a Device
   * @param {string|null} id
   * @param {string|null} family
   * @param {string|null} brand
   * @param {string|null} model
   * @param {object|null} platform
   */
  constructor(id, family = null, brand = null, model = null, platform = null) {
    this.id = id;
    this.family = family;
    this.brand = brand;
    this.model = model;
    this.platform = platform;
    this.name = this.getDisplayName();
  }

  static create(entity) {
    const { family, brand, model, platform } = entity.attributes;
    return new Device(entity.id, family, brand, model, platform);
  }

  getDisplayName() {
    return `${this.brand} ${this.family} - ${this.model}`;
  }
}
