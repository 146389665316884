import getDefaultRouterNameByUser from '@/model/shared/context/getDefaultRouterByUser';
import { CONTEXTS } from '@/model/shared/contexts';

export default function redirectToContextPage(loggedUser) {
  const contextToGo = loggedUser.contextRoles.find(cr => cr.context !== CONTEXTS.CORE.id).context;
  if (contextToGo === CONTEXTS.SUNSTUDIO.id) {
    window.location.href = CONTEXTS[contextToGo].getDefaultComponent();
    return;
  }
  return { name: getDefaultRouterNameByUser(CONTEXTS[contextToGo], loggedUser) };
}
