import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import basicInfo from '@/router/private/modules/ecommerce/supply/publisher/edit/basicInfo';
import additionalInfo from '@/router/private/modules/ecommerce/supply/publisher/edit/additionalInfo';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const edit = {
  path: ':publisherId/edit',
  redirect: { name: basicInfo.name },
  name: `${basePath}-edit-publisher`,
  component: () =>
    import(/* webpackChunkName: "ec-publisher" */ '@/views/private/modules/ecommerce/publisher/PublisherEdit'),
  meta: {
    requiresAuth: true,
    title: 'Publisher',
    breadcrumbs: new BreadcrumbItem('Edit Publisher', { name: `${basePath}-edit-publisher` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  children: [basicInfo, additionalInfo],
  props: true,
};
