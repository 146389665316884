/// <reference path="../../model/shared/Context.js" />

import axios from 'axios';
import { createApi } from './api';
import { CONTEXTS } from '@/model/shared/contexts';

export class Obelix {
  /**
   *
   * @typedef {object} Obelix
   * @property @param {string|Context} context
   * @property @param {string} version
   * @property {AxiosInstance} api
   * @property {CancelTokenSource} cancelTokenSource
   * @property {Map<CancelTokenSource>} cancelTokenList
   */
  constructor(context = CONTEXTS.CORE, version = 'v1') {
    this.context = typeof context === 'string' ? context : context?.subdomainUrl;
    this.version = version;
    this.api = createApi(`${process.env.VUE_APP_API_PROTOCOL}://${this.context}.${process.env.VUE_APP_API_BASE_URL}`);
    /** @type CancelTokenSource */
    this.cancelTokenSource = axios.CancelToken.source();
    this.cancelTokenList = new Map();
  }

  /**
   * Create a partial URL using an Object of key-values
   * @param {Object} resources
   * @param {string} version
   * @returns {string} url
   */
  createUrl(resources, version = this.version) {
    if (!resources) throw new Error(`Resources parameter can't be undefined. Please, provide an object of resources.`);

    /**
     * 1. Object entries to transform key-value pairs to arrays
     * 2. Filter to avoid null values into url
     * 3. Join key-value pairs using /
     * 4. Join array of key-value pairs
     */
    const partialUrl = Object.entries(resources)
      .map(resource => resource.filter(notNull => notNull).join('/'))
      .join('/');

    return `/${version}/${partialUrl}`;
  }

  /**
   *
   * @param {string} url
   * @private
   */
  _validateUrl(url) {
    if (!url) throw new Error(`URL parameter can't be undefined. Please, provide an URL.`);
  }

  /**
   * Helper function to allow cancel API calls
   * @private
   */
  _createCancelTokenSource() {
    this.cancelTokenSource = axios.CancelToken.source();
  }

  /**
   *
   * @param {string} url
   * @private
   */
  _initializeApiCall(url) {
    this._validateUrl(url);
    this._createCancelTokenSource();
  }

  refreshCancelToken() {
    this.cancelTokenSource.cancel('Cancelled by view');
    this._createCancelTokenSource();
  }

  /**
   * GET
   * @param {string} url
   * @param {object} params
   * @param {object} config
   * @param {object} config.preventCancel default false, if true cancel request  in progress with equal url
   * @param {object} config.responseType default null, value to change request format
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get(url, params = {}, config = { preventCancel: false, responseType: null, preventKey: null }) {
    this._initializeApiCall(url);
    const preventId = config.preventKey ? url + config.preventKey : url;
    const cancelToken = this.cancelTokenList.get(preventId);
    cancelToken?.cancel();

    if (!config.preventCancel) {
      this.cancelTokenList.set(preventId, this.cancelTokenSource);
    }

    const resp = await this.api.get(url, {
      ...config,
      params,
      cancelToken: this.cancelTokenSource.token,
    });
    this.cancelTokenList.delete(preventId);
    return resp;
  }

  /**
   * POST
   * @param {string} url
   * @param {Object} payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  async create(url, payload = {}) {
    this._initializeApiCall(url);
    return await this.api.post(url, payload, {
      cancelToken: this.cancelTokenSource.token,
    });
  }

  /**
   * PUT
   * @param {string} url
   * @param {Object} payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  async update(url, payload = {}) {
    this._initializeApiCall(url);
    return await this.api.put(url, payload, {
      cancelToken: this.cancelTokenSource.token,
    });
  }

  /**
   *
   * @param {string} url
   * @returns {Promise<AxiosResponse<any>>}
   */
  async delete(url) {
    this._initializeApiCall(url);
    return await this.api.delete(url, {
      cancelToken: this.cancelTokenSource.token,
    });
  }
}

export const { createUrl } = Obelix;
export default Obelix;
