import UserCore from '@/entities/Auth/UserCore';
import CommentTag from '@/model/shared/CommentTag';
import { stringToDate } from '@/filters/dateFilters';
import { COMMENT_TAGS } from '@/model/shared/Tags';

/**
 * CommentShared
 *
 * @class
 * @public
 */
export default class CommentShared {
  /**
   * @constructor
   * @typedef {object} CommentShared
   * @property @param {string} id
   * @property @param {string} createdAt
   * @property @param {string} author
   * @property @param {CommentTag} tag
   * @property @param {string} content
   * @property @param {boolean} canUpdate
   */
  constructor(id = '', createdAt = 0, updatedAt = 0, author = {}, content = '', tag = '', canUpdate = false) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.author = author;
    this.content = content;
    this.tag = tag;
    this.canUpdate = canUpdate;
  }

  payload() {
    return { tag: this.tag.id, content: this.content };
  }

  static create(entity, included, tags = COMMENT_TAGS) {
    const createdAt = entity.attributes.createdAt;
    const updatedAt = entity.attributes.updatedAt;

    const author =
      included?.author?.find(ath => ath.id === entity?.relationships?.author.data.id) ||
      new UserCore(entity?.relationships?.author.data.id);

    const tag =
      tags?.find(t => t.id === entity.attributes.tag) ||
      new CommentTag(entity.attributes.tag, 'gray', entity.attributes.tag);

    return new CommentShared(
      entity.id,
      createdAt ? stringToDate(createdAt) : null,
      updatedAt ? stringToDate(updatedAt) : null,
      author,
      entity.attributes.content,
      tag,
      entity.attributes.canUpdate
    );
  }
}
