import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { users } from './users';
import { wallet } from './wallet';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;

export const admin = {
  path: 'admin',
  name: `${basePath}-admin`,
  redirect: { name: users.name },
  meta: {
    requiresAuth: true,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.AD_OPS.id],
  },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [users, wallet],
  props: true,
};
