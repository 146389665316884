import {
  SAVE_PARAMS_KEY,
  RESET_CONTEXTBOARD_KEY,
  RESET_PARAMS_EXCEPT_KEY,
  PARAM_LIST_KEY,
} from '@/store/modules/contextBoard/keys';
import { initialState } from '@/store/modules/contextBoard/initialState';

const actions = {
  [SAVE_PARAMS_KEY]: async ({ commit }, payload) => {
    commit(SAVE_PARAMS_KEY, payload);
  },
  [RESET_PARAMS_EXCEPT_KEY]: async ({ commit, state }, keys) => {
    const oldParamsToMantain = keys.map(key => ({ [key]: state[PARAM_LIST_KEY][key] }));
    const newParams = Object.assign({ ...initialState()[PARAM_LIST_KEY] }, ...oldParamsToMantain);
    commit(SAVE_PARAMS_KEY, newParams);
  },
  [RESET_CONTEXTBOARD_KEY]: async ({ commit }) => {
    commit(RESET_CONTEXTBOARD_KEY);
  },
};

export default actions;
