import { REGION_OFFICE_RESOURCE, LINKED_USER_RESOURCE } from '@/services/keys';
import { CONTEXTS } from '@/model/shared/contexts';
import { ClientShared } from '@/entities/shared/ClientShared';
import RegionOffice from '@/entities/dashboardSSP/RegionOffice';

export class DashboardSSPClient extends ClientShared {
  constructor(
    id = '',
    name = '',
    email = '',
    contactName = '',
    isExternal = false,
    users = [],
    taxId = '',
    regionOffice = new RegionOffice()
  ) {
    super(id, name, email, CONTEXTS.DASHBOARD_SSP.id, contactName, isExternal, users, taxId);
    this.regionOffice = regionOffice;
  }

  payload() {
    const { users, regionOffice, ...params } = this;
    return {
      ...params,
      userIds: users.map(user => user.id),
      regionOfficeId: regionOffice.id,
    };
  }

  /**
   *
   * @param {{id:string, attributes: DashboardSSPClient}} entity
   * @param {Object} included
   * @return {DashboardSSPClient}
   */
  static create(entity, included) {
    const regionOffice = included?.[REGION_OFFICE_RESOURCE]?.find(r => r.id === entity.attributes.regionOfficeId);
    const linkedUsers = entity.relationships?.[LINKED_USER_RESOURCE].data;

    return new DashboardSSPClient(
      entity.id,
      entity.attributes.name,
      entity.attributes.email,
      entity.attributes.contactName,
      entity.attributes.isExternal,
      linkedUsers && included.users?.filter(user => linkedUsers.find(linkUser => linkUser.id === user.id)),
      entity.attributes.taxId,
      regionOffice
    );
  }
}
