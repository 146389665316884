import UserCore from '@/entities/Auth/UserCore';

export default class Seller extends UserCore {
  /**
   * @param {string|null} id
   * @param {string} name
   * @param {string} email
   */
  constructor(id = null, name = '', email = '') {
    super(id, name, email);
  }

  static create(entity) {
    return new Seller(entity.id, entity.attributes.name, entity.attributes.email);
  }
}
