import { api } from '.';
import { USER_AGENTS_RESOURCE, OPERATING_SYSTEM_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import coreEntityParser from '@/entities/Core/CoreEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { SMART_AD_SERVER } from '@/model/shared/Platforms';

export const getResources = () => ({
  [USER_AGENTS_RESOURCE]: undefined,
  [OPERATING_SYSTEM_RESOURCE]: undefined,
});

/**
 * @returns {Promise.<{data:Array<OperatingSystem>, meta:undefined}>}
 */
export async function getOperatingSystemsSmart(params = new QueryParamsBuilder()) {
  params.addFilter('platform', SMART_AD_SERVER.id);
  const partialUrl = api.createUrl(getResources());
  const data = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(coreEntityParser.parse(data.data)).build();
}
