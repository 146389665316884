export const BROWSER_NS = 'browser';

export const GET_BROWSERS_SMART_REQUEST_KEY = 'get_browsers_smart_request';
export const GET_BROWSERS_VIDEO_PLAZA_REQUEST_KEY = 'get_browsers_video_plaza_request';
export const BROWSERS_SMART_KEY = 'browsers_smart';
export const BROWSERS_VIDEO_PLAZA_KEY = 'browsers_video_plaza';

export const GET_BROWSERS_SMART_REQUEST = `${BROWSER_NS}/${GET_BROWSERS_SMART_REQUEST_KEY}`;
export const GET_BROWSERS_VIDEO_PLAZA_REQUEST = `${BROWSER_NS}/${GET_BROWSERS_VIDEO_PLAZA_REQUEST_KEY}`;
export const BROWSERS_SMART = `${BROWSER_NS}/${BROWSERS_SMART_KEY}`;
export const BROWSERS_VIDEO_PLAZA = `${BROWSER_NS}/${BROWSERS_VIDEO_PLAZA_KEY}`;
