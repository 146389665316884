import { SETTINGS, USER_SINGLE_RESOURCE } from '@/services/keys.js';

/**
 * @param {Obelix} api
 * @param {Object} settings Object containing user config
 */
export async function updateSettings(api, settings) {
  const partialUrl = api.createUrl({
    [USER_SINGLE_RESOURCE]: null,
    [SETTINGS]: null,
  });

  const { data } = await api.update(partialUrl, { settings });
  return data;
}
