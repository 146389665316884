/// <reference path="./roles.js" />
import { ROLES } from '@/model/shared/roles';

/**
 * Context
 * @class
 * @public
 */
export default class Context {
  /**
   * Create a Context
   * @constructor
   * @typedef {object} Context
   * @property @param {string} id
   * @property @param {string} name
   * @property @param {Object} permissions - list of permissions, yuo can get from '@/model/shared/permissions/'
   * @property @param {string} baseUrl - Base vue router  url
   * @property @param {string} defaultLocation - view index url (vue router url) for this context
   * @property @param {{ [string] : Role[] }} roles - List of roles enabled in this context
   * @property @param {string} subdomainUrl - sub domain url, you can set in the env file, default: process.env['VUE_APP_API_' + this.id]
   * @property @param {string} version - api version
   */
  constructor(
    id,
    name,
    permissions,
    baseUrl,
    defaultLocation = null,
    roles = { [ROLES.ADMIN.id]: [] },
    userRolesToLink = [ROLES.CLIENT],
    version = 'v1',
    subdomainUrl = null
  ) {
    this.id = id;
    this.name = name;
    this.permissions = permissions;
    this.baseUrl = baseUrl;
    this.defaultLocation = defaultLocation;
    this.roles = Object.keys(roles).map(rolId => ROLES[rolId]);
    this.roleHierarchy = roles;
    this.userRolesToLink = userRolesToLink;
    this.version = version;
    this.subdomainUrl = subdomainUrl ?? (process.env?.[`VUE_APP_API_${this.id}`] || '');
  }

  /**
   * @param {string} role
   */
  getRoleIfHasRole(role) {
    return this.roles.find(r => r.id === role);
  }

  toSelectOption() {
    return { value: this.id, text: this.name };
  }

  getDefaultComponent() {
    return `${this.baseUrl}-${this.defaultLocation}`;
  }

  toString() {
    return this.name;
  }

  /**
   * Set shortName property
   * @param {string} shortName
   * @returns Context
   */
  setShortName(shortName) {
    this.shortName = shortName;
    return this;
  }
}
