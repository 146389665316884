import { initialState } from '@/store/modules/settings/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import {
  UPDATE_USER_SETTINGS_INDEX_KEY,
  UPDATE_USER_SETTINGS_INDEX_SECOND_KEY,
  USER_CONTEXT_SETTINGS_KEY,
  USER_SETTINGS_KEY,
} from './keys';

const mutations = {
  [USER_SETTINGS_KEY]: (state, payload) => {
    state[USER_SETTINGS_KEY] = payload;
  },
  [USER_CONTEXT_SETTINGS_KEY]: (state, payload) => {
    state[USER_CONTEXT_SETTINGS_KEY] = payload;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[USER_SETTINGS_KEY] = initialState()[USER_SETTINGS_KEY];
    state[USER_CONTEXT_SETTINGS_KEY] = initialState()[USER_CONTEXT_SETTINGS_KEY];
  },
  [UPDATE_USER_SETTINGS_INDEX_KEY]: (state, { index, value }) => {
    state[USER_SETTINGS_KEY].config[index] = value;
  },
  [UPDATE_USER_SETTINGS_INDEX_SECOND_KEY]: (state, { index, indexSecond, value }) => {
    if (index in state[USER_SETTINGS_KEY].config) {
      state[USER_SETTINGS_KEY].config[index][indexSecond] = value;
    } else {
      state[USER_SETTINGS_KEY].config[index] = {};
      state[USER_SETTINGS_KEY].config[index][indexSecond] = value;
    }
  },
};

export default mutations;
