import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { getSettings as getSettingsTP, updateSettings as updateSettingsTP } from '@/services/modules/ThirdParty/user';
import {
  getSettings as getSettingsSSP,
  updateSettings as updateSettingsSSP,
} from '@/services/modules/dashboardSSP/user';

import {
  GET_USER_SETTINGS_REQUEST_KEY,
  RESET_USER_CONTEXT_SETTINGS_KEY,
  UPDATE_USER_SETTINGS_REQUEST_KEY,
  USER_CONTEXT_SETTINGS_KEY,
  USER_SETTINGS_KEY,
} from './keys';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import { ACTIVE_CONTEXT_KEY, CONTEXT_NS } from '@/store/modules/context/keys';
import { CONTEXTS } from '@/model/shared/contexts';

const actions = {
  [GET_USER_SETTINGS_REQUEST_KEY]: async ({ state, commit, rootState }) => {
    const currentContext = rootState[CONTEXT_NS][ACTIVE_CONTEXT_KEY].id;
    let functionByContext = () => null;
    switch (currentContext) {
      case CONTEXTS.DASHBOARD_SSP.id:
        functionByContext = getSettingsSSP;
        break;

      case CONTEXTS.THIRD_PARTY.id:
      default:
        functionByContext = getSettingsTP;
        break;
    }

    if (!state[USER_CONTEXT_SETTINGS_KEY]) {
      commit(USER_CONTEXT_SETTINGS_KEY, currentContext);
    }

    if (state[USER_SETTINGS_KEY] && currentContext === state[USER_CONTEXT_SETTINGS_KEY]) {
      return state[USER_SETTINGS_KEY];
    }

    try {
      const userSettings = await functionByContext();
      await commit(USER_SETTINGS_KEY, userSettings);
      commit(USER_CONTEXT_SETTINGS_KEY, currentContext);
      return state[USER_SETTINGS_KEY];
    } catch (error) {
      if (error.code !== HTTPStatusCode.Cancel) throw error;
    }
  },
  [UPDATE_USER_SETTINGS_REQUEST_KEY]: async ({ state, commit }, userSettings) => {
    let functionByContext = null;
    switch (state[USER_CONTEXT_SETTINGS_KEY]) {
      case CONTEXTS.DASHBOARD_SSP.id:
        functionByContext = updateSettingsSSP;
        break;

      case CONTEXTS.THIRD_PARTY.id:
      default:
        functionByContext = updateSettingsTP;
        break;
    }

    await functionByContext(userSettings);
    commit(USER_SETTINGS_KEY, userSettings);
  },
  [RESET_USER_CONTEXT_SETTINGS_KEY]: ({ commit }) => {
    commit(RESET_STORE_MODULE_MUTATION);
  },
};

export default actions;
