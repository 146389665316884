import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const list = {
  path: 'list',
  name: `${basePath}-offers`,
  component: () => import(/* webpackChunkName: "ec-offers" */ '@/views/private/modules/ecommerce/offer/OfferList'),
  meta: {
    requiresAuth: true,
    title: 'Offers ',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.PUBLISHER.id],
  },
  props: true,
};
