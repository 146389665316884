import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const logs = {
  path: 'logs',
  name: `${basePath}-analytics-logs`,
  component: () =>
    import(/* webpackChunkName: "ec-analytics-logs" */ '@/views/private/modules/ecommerce/analytics/Logs.vue'),
  meta: {
    breadcrumbs: new BreadcrumbItem('Logs', { name: `${basePath}-analytics-logs` }, {}),
    requiresAuth: true,
    title: 'Logs',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.SUPER_ADVERTISER.id, ROLES.ADVERTISER.id],
  },
  props: true,
};

export default logs;
