import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const campaignManagerTomorrow = {
  path: 'campaign-manager-tomorrow',
  name: `${basePath}-campaign-manager-tomorrow`,
  component: () =>
    import(
      /* webpackChunkName: "tp-campaign-manager-tomorrow" */ '@/views/private/modules/thirdParty/campaignManager/campaignManagerTomorrow/CampaignManagerTomorrow.vue'
    ),
  meta: {
    breadcrumbs: new BreadcrumbItem('Campaign Manager Tomorrow', { name: `${basePath}-campaign-manager-tomorrow` }, {}),
    requiresAuth: true,
    title: 'Campaign Manager Tomorrow',
  },
  props: true,
};

export default campaignManagerTomorrow;
