export const ANALYTICS_REPORT_TOAST_NS = 'analyticsReportToast';

export const ANALYTICS_REPORT_TOAST_KEY = 'analyticsReportToast';
export const SET_ANALYTICS_REPORT_TOAST_KEY = 'setAnalyticsReportToast';
export const SET_ANALYTICS_REPORT_TOAST_FINISHED_KEY = 'setAnalyticsReportToastFinished';
export const DELETE_ANALYTICS_REPORT_TOAST_KEY = 'deleteAnalyticsReportToast';

export const ANALYTICS_REPORT_TOAST = `${ANALYTICS_REPORT_TOAST_NS}/${ANALYTICS_REPORT_TOAST_KEY}`;
export const SET_ANALYTICS_REPORT_TOAST = `${ANALYTICS_REPORT_TOAST_NS}/${SET_ANALYTICS_REPORT_TOAST_KEY}`;
export const DELETE_ANALYTICS_REPORT_TOAST = `${ANALYTICS_REPORT_TOAST_NS}/${DELETE_ANALYTICS_REPORT_TOAST_KEY}`;
export const SET_ANALYTICS_REPORT_TOAST_FINISHED = `${ANALYTICS_REPORT_TOAST_NS}/${SET_ANALYTICS_REPORT_TOAST_FINISHED_KEY}`;
