import { api } from '.';
import { CLIENT_RESOURCE } from '@/services/keys';

/**
 *
 * @param {Client} client
 * @returns {Promise.<object>}
 */
export async function deleteClient(client) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: client.id,
  });
  return await api.delete(partialUrl);
}
