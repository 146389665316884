import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { list } from '@/router/private/modules/thirdParty/demand/advertiser/list';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;
const breadcrumbs = {
  index: new BreadcrumbItem('Advertisers', { name: `${basePath}-advertisers` }, {}),
  new: new BreadcrumbItem('New', { name: `${basePath}-new-advertiser` }, {}),
};

export const advertiser = {
  name: `${basePath}-advertiser`,
  path: 'advertiser',
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: breadcrumbs.index,
  },
  children: [list],
};
