import { CONTEXTS } from '../contexts';
import { ROLES } from '@/model/shared/roles';
import { deals as dealsRouter } from '@/router/private/modules/dashboardSSP/analytics/deals.js';
import branding from '@/router/private/modules/thirdParty/analytics/branding';

export default function getDefaultRouterNameByUser(context, user) {
  if (
    context.id === CONTEXTS.DASHBOARD_SSP.id &&
    user.contextRoles.find(user => user.context === CONTEXTS.DASHBOARD_SSP.id).role === ROLES.SELLER.id
  ) {
    return dealsRouter.name;
  }
  if (
    context.id === CONTEXTS.THIRD_PARTY.id &&
    user.contextRoles.find(user => user.context === CONTEXTS.THIRD_PARTY.id).role === ROLES.SELLER.id
  ) {
    return branding.name;
  }

  return context.getDefaultComponent();
}
