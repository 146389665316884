import { users } from './users';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { scheduledEmails } from '@/router/private/modules/thirdParty/admin/scheduledEmails';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const admin = {
  path: 'admin',
  name: `${basePath}-admin`,
  redirect: { name: users.name },
  meta: {
    requiresAuth: true,
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id],
  },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [users, scheduledEmails],
  props: true,
};
