import UserCore from '../Auth/UserCore.js';
import Finance from './Finance';
import { CONTEXTS } from '@/model/shared/contexts';

export default class SocialAudienceUser extends UserCore {
  constructor(
    id = null,
    name = '',
    email = '',
    password = '',
    contextRoles = [],
    enabled = true,
    clients = [],
    advertisers = [],
    publishers = [],
    finance = new Finance()
  ) {
    super(id, name, email, password, contextRoles, enabled, clients, advertisers, publishers);
    this.finance = finance;
  }

  payload() {
    const contextRolSocialAudience = this.contextRoles.find(({ context }) => context === CONTEXTS.SOCIAL_AUDIENCE.id);
    contextRolSocialAudience.settings.finance = this.finance.payload();

    return {
      ...super.payload(),
    };
  }

  /**
   *
   * @param {{id:string, attributes: UserCore}} entity
   * @param object included
   * @return {UserCore}
   */
  static create(entity, included) {
    const userCore = UserCore.create(entity, included);
    const contextRolSocialAudience = userCore.contextRoles.find(
      ({ context }) => context === CONTEXTS.SOCIAL_AUDIENCE.id
    );

    return new SocialAudienceUser(
      userCore.id,
      userCore.name,
      userCore.email,
      userCore.password,
      userCore.contextRoles,
      userCore.enabled,
      userCore.clients,
      userCore.advertisers,
      userCore.publishers,
      contextRolSocialAudience ? contextRolSocialAudience.finance : null
    );
  }
}
