import CreativeFeedType from '@/model/modules/ecommerce/offer/creative/creativeFeed/CreativeFeedType';

const CREATIVE_FEED_TYPES = {
  FILE: new CreativeFeedType('FILE', 'File Upload', 'Upload a spreadsheet or file to add your inventory'),
  FEED: new CreativeFeedType(
    'FEED',
    'Scheduled Feed',
    'Use a file hosted on a website to automatically add and update your inventory'
  ),
};

export default CREATIVE_FEED_TYPES;
