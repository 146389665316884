function percentage(value, notAvailable = '-', locale = 'en-UK', maximumFractionDigits = 2) {
  if (value === null || value === undefined) return notAvailable;

  let amount = typeof value === 'string' ? parseFloat(value) : value;
  amount = isNaN(amount) ? 0 : amount;
  return `${amount.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits,
  })}%`;
}

export default percentage;
