import { initialState } from '@/store/modules/device/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import { DEVICES_SMART_KEY, DEVICES_VIDEO_PLAZA_KEY } from './keys';

const mutations = {
  [DEVICES_SMART_KEY]: (state, payload) => {
    state[DEVICES_SMART_KEY] = payload;
  },
  [DEVICES_VIDEO_PLAZA_KEY]: (state, payload) => {
    state[DEVICES_VIDEO_PLAZA_KEY] = payload;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[DEVICES_SMART_KEY] = initialState()[DEVICES_SMART_KEY];
    state[DEVICES_VIDEO_PLAZA_KEY] = initialState()[DEVICES_VIDEO_PLAZA_KEY];
  },
};

export default mutations;
