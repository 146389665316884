import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import basicInfo from '@/router/private/modules/ecommerce/demand/offer/editForm/basicInfo';
import publishers from '@/router/private/modules/ecommerce/demand/offer/editForm/publishers';
import targeting from '@/router/private/modules/ecommerce/demand/offer/editForm/targeting';
import creatives from '@/router/private/modules/ecommerce/demand/offer/editForm/creatives';
import fraud from '@/router/private/modules/ecommerce/demand/offer/editForm/fraud';
import postEvent from '@/router/private/modules/ecommerce/demand/offer/editForm/postEvent';
import { history } from '@/router/private/modules/ecommerce/demand/offer/editForm/history';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const edit = {
  path: ':offerId/edit',
  redirect: { name: basicInfo.name },
  name: `${basePath}-edit-offer`,
  component: () => import(/* webpackChunkName: "ec-offers-edit" */ '@/views/private/modules/ecommerce/offer/OfferEdit'),
  meta: {
    requiresAuth: true,
    title: 'Offer',
    breadcrumbs: new BreadcrumbItem('Edit Offer', { name: `${basePath}-edit-offer` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  children: [basicInfo, targeting, creatives, fraud, postEvent, publishers, history],
  props: true,
};
