import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';
import { list } from '@/router/private/modules/thirdParty/demand/product/list';
import { edit } from '@/router/private/modules/thirdParty/demand/product/edit';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const product = {
  path: 'products',
  name: `${basePath}-product`,
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: new BreadcrumbItem('Products', { name: `${basePath}-products` }, {}),
  },
  children: [list, edit],
  props: true,
};

export default product;
