import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.CONTEXTUAL.baseUrl;
const routeName = `${basePath}-cluster-stats`;

export const stats = {
  path: ':clusterId/stats',
  name: routeName,
  component: () =>
    import(/* webpackChunkName: "co-cluster" */ '@/views/private/modules/contextual/cluster/ClusterStats'),
  meta: {
    requiresAuth: true,
    title: 'Cluster Stats - SunMedia',
    breadcrumbs: new BreadcrumbItem('Cluster Stats', { name: routeName }, {}),
    props: true,
  },
};
