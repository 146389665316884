import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { list } from '@/router/private/modules/ecommerce/billing/publisherTechFee/list';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;
const breadcrumbs = {
  index: new BreadcrumbItem('Publisher Tech Fee', { name: `${basePath}-publisherTechFee` }, {}),
};

export const publisherTechFee = {
  name: `${basePath}-publisherTechFee`,
  path: 'publisher-tech-fee',
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: breadcrumbs.index,
  },
  children: [list],
};
