import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';

export const CONTEXT_NS = 'context';

export const ACTIVE_CLIENT_KEY = 'active_client';
export const ACTIVE_CONTEXT_KEY = 'active_context';
export const SET_ACTIVE_CLIENT_KEY = 'set_active_client';

export const ACTIVE_CLIENT = `${CONTEXT_NS}/${ACTIVE_CLIENT_KEY}`;
export const ACTIVE_CONTEXT = `${CONTEXT_NS}/${ACTIVE_CONTEXT_KEY}`;
export const SET_ACTIVE_CLIENT = `${CONTEXT_NS}/${SET_ACTIVE_CLIENT_KEY}`;
export const RESET_CONTEXT = `${CONTEXT_NS}/${RESET_STORE_MODULE_MUTATION}`;
