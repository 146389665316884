export const USER_SETTINGS_NS = 'settings';

export const GET_USER_SETTINGS_REQUEST_KEY = 'get_user_settings_request';
export const UPDATE_USER_SETTINGS_REQUEST_KEY = 'update_user_settings_request';
export const USER_SETTINGS_KEY = 'user_settings';
export const RESET_USER_CONTEXT_SETTINGS_KEY = 'reset_user_settings';
export const UPDATE_USER_SETTINGS_INDEX_KEY = 'update_user_settings_index';
export const UPDATE_USER_SETTINGS_INDEX_SECOND_KEY = 'update_user_settings_index_second';
export const USER_CONTEXT_SETTINGS_KEY = 'user_context_settings';

export const GET_USER_SETTINGS_REQUEST = `${USER_SETTINGS_NS}/${GET_USER_SETTINGS_REQUEST_KEY}`;
export const UPDATE_USER_SETTINGS_REQUEST = `${USER_SETTINGS_NS}/${UPDATE_USER_SETTINGS_REQUEST_KEY}`;
export const USER_SETTINGS = `${USER_SETTINGS_NS}/${USER_SETTINGS_KEY}`;
export const RESET_USER_CONTEXT_SETTINGS = `${USER_SETTINGS_NS}/${RESET_USER_CONTEXT_SETTINGS_KEY}`;
export const UPDATE_USER_SETTINGS_INDEX = `${USER_SETTINGS_NS}/${UPDATE_USER_SETTINGS_INDEX_KEY}`;
export const UPDATE_USER_SETTINGS_INDEX_SECOND = `${USER_SETTINGS_NS}/${UPDATE_USER_SETTINGS_INDEX_SECOND_KEY}`;
export const USER_CONTEXT_SETTINGS = `${USER_SETTINGS_NS}/${USER_CONTEXT_SETTINGS_KEY}`;
