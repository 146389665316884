class Currency {
  /**
   * @param {string} id
   * @param {string} name
   * @param {string} symbol
   * @param {string} label
   * @param {string} labelIso
   */
  constructor(id = null, name = '', symbol = null, label = '', labelIso = '') {
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.label = label;
    this.labelIso = labelIso;
  }

  // TODO: delete if not used
  // update(id = null, name = '', symbol = null, label = '', labelIso = '') {
  //   this.id = id;
  //   this.name = name;
  //   this.symbol = symbol;
  //   this.label = label;
  //   this.labelIso = labelIso;
  // }

  /**
   *
   * @param {{id:string, attributes: Currency}} entity
   * @param object included
   * @return {Currency}
   */
  static create(entity) {
    const attr = entity.attributes;
    const name = `${entity.id} (${attr.symbol})`;
    const labelIso = `${attr.name} (${entity.id})`;
    return new Currency(entity.id, name, attr.symbol, attr.name, labelIso);
  }
}

export default Currency;
