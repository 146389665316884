import { initialState } from '@/store/modules/load/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import { IS_LOADING_KEY, SET_LOAD_KEY } from './keys';

const mutations = {
  [SET_LOAD_KEY]: (state, status) => {
    state[IS_LOADING_KEY] = status;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[IS_LOADING_KEY] = initialState()[IS_LOADING_KEY];
  },
};

export default mutations;
