import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const list = {
  path: 'list',
  name: `${basePath}-publishersTechFee`,
  component: () =>
    import(
      /* webpackChunkName: "ec-publisher" */ '@/views/private/modules/ecommerce/publisherTechFee/PublisherTechFeeList'
    ),
  meta: {
    requiresAuth: true,
    title: 'Publisher Tech Fee ',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id],
  },
  props: true,
};
