class WalletType {
  constructor(id, value) {
    this.id = id;
    this.value = value;
  }
}

export const VIRTUAL_TYPE = new WalletType('VIRTUAL', 'Virtual');
export const CREDIT_CARD_TYPE = new WalletType('CREDIT_CARD', 'Credit card');

export const WALLET_TYPES = [VIRTUAL_TYPE, CREDIT_CARD_TYPE];
export const WALLET_TYPE_DEFAULT = CREDIT_CARD_TYPE;
