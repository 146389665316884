import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

const targeting = {
  path: 'targeting-audience',
  name: `${basePath}-offer-targeting`,
  component: () =>
    import(
      /* webpackChunkName: "ec-offers" */ '@/components/organisms/modules/ecommerce/offer/form/targeting/Targeting'
    ),

  meta: {
    requiresAuth: true,
    title: 'Offer',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.PUBLISHER.id],
  },
  props: true,
};

export default targeting;
