import { RESET_PASSWORD_LOGGED_RESOURCE, USER_RESOURCE } from '../../keys';
import { api } from '.';

/**
 * @param {string} oldPassword
 * @param {string} newPassword
 */
export function loggedInResetPassword(oldPassword, newPassword) {
  const partialUrl = api.createUrl({
    [USER_RESOURCE]: undefined,
    [RESET_PASSWORD_LOGGED_RESOURCE]: undefined,
  });
  return api.create(partialUrl, { oldPassword, newPassword1: newPassword, newPassword2: newPassword });
}
