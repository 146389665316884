import * as RESOURCE from '@/services/keys';
import { EntityParser } from '@/entities/shared/EntityParser';
import { SocialAudienceClient as Client } from './SocialAudienceClient';
import Advertiser from './Advertiser';
import Campaign from '@/entities/socialAudience/Campaign';
import CampaignAnalytics from './CampaignAnalytics';
import Category from '@/entities/Core/Category';
import CreativeFactory from './CreativeFactory';
import Currency from '@/entities/shared/Currency';
import Insertion from './Insertion';
import Platform from './Platform';
import TargetNative from './TargetNative';
import TargetVideo from './TargetVideo';
import UserCore from '@/entities/Auth/UserCore';
import Wallet from '@/entities/socialAudience/Wallet';
import ContentTargeting from '@/entities/socialAudience/ContentTargeting';
import SocialAudienceUser from '@/entities/socialAudience/SocialAudienceUser';
import Transaction from '@/entities/socialAudience/Transaction';

const socialAudienceEntityParser = new EntityParser({
  [RESOURCE.ADVERTISER_RESOURCE]: Advertiser.create,
  [RESOURCE.CAMPAIGNS_ANALYTICS_BREAKDOWN_RESOURCE]: CampaignAnalytics.create,
  [RESOURCE.CAMPAIGNS_ANALYTICS_RESOURCE]: CampaignAnalytics.create,
  [RESOURCE.CAMPAIGNS_RESOURCE]: Campaign.create,
  [RESOURCE.CATEGORY_RESOURCE]: Category.create,
  [RESOURCE.CLIENT_RESOURCE]: Client.create,
  [RESOURCE.CREATIVE_RESOURCE]: CreativeFactory.create,
  [RESOURCE.CURRENCY_RESOURCE]: Currency.create,
  [RESOURCE.INSERTION_RESOURCE]: Insertion.create,
  [RESOURCE.LINKED_USER_RESOURCE]: UserCore.create,
  [RESOURCE.PLATFORM_RESOURCE]: Platform.create,
  [RESOURCE.SUBCATEGORIES_RESOURCE]: Category.create,
  [RESOURCE.TARGETING_NATIVE_RESOURCE]: TargetNative.create,
  [RESOURCE.TARGETING_VIDEO_RESOURCE]: TargetVideo.create,
  [RESOURCE.USER_RESOURCE]: SocialAudienceUser.create,
  [RESOURCE.WALLET_TRANSACTIONS_RESOURCE]: Wallet.create,
  [RESOURCE.WALLETS_RESOURCE]: Wallet.create,
  [RESOURCE.CONTENT_TARGETING]: ContentTargeting.create,
  [RESOURCE.TRANSACTIONS_RESOURCE]: Transaction.create,
  // TODO waiting for back to delete this
  targeting: TargetNative.create,
  creative: CreativeFactory.create,
  walletTransactions: Wallet.create,
  subCategories: Category.create, //  TODO: is a included
  linkedUsers: UserCore.create, // TODO  is a inlcuded
});
export default socialAudienceEntityParser;
