import SelectOption from '@/model/shared/SelectOption';
import { TRANSACTION_STATUS_DEFAULT } from '@/model/modules/socialAudience/wallet/TransactionStatus';

export default class Wallet {
  /**
   * @param {string|null} id
   * @param {float} fee
   * @param {float} techCost
   * @param {float} allocated
   * @param {float} spent
   * @param {float} balance
   * @param {string} status
   * @param {string} walletType
   * @param {boolean} automaticRecharge
   * @param {User} user
   * @param {array<Transaction>} transactions
   */
  constructor(
    id = null,
    fee = null,
    techCost = null,
    allocated = null,
    spent = null,
    balance = null,
    status = null,
    walletType = new SelectOption('INCOME'),
    automaticRecharge = false,
    user = null,
    transactions = []
  ) {
    this.id = id;
    this.fee = fee;
    this.techCost = techCost;
    this.allocated = allocated;
    this.spent = spent;
    this.balance = balance;
    this.status = status || TRANSACTION_STATUS_DEFAULT;
    this.walletType = walletType;
    this.automaticRecharge = automaticRecharge;
    this.user = user;
    this.transactions = transactions;
  }

  /**
   *
   * @param {{id:string, attributes: Wallet}} entity
   * @param object included
   * @return {Wallet}
   */
  static create(entity, included) {
    const attr = entity.attributes;
    const user = included.users?.find(included => included.id === entity.relationships.user.data.id);

    const transactions = [];
    if (included.transactions) {
      entity.relationships.transactions.data.forEach(transaction => {
        transactions.push(included.transactions.find(included => included.id === transaction.id));
      });
    }

    return new Wallet(
      entity.id,
      attr.fee,
      attr.techCost,
      attr.allocated,
      attr.spent,
      attr.balance,
      attr.status,
      new SelectOption(attr.walletType),
      attr.automaticRecharge,
      user,
      transactions
    );
  }
}
