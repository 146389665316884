/**
 * EmailIngestion
 * @class
 * @constructor
 * @public
 */

export default class EmailIngestion {
  /**
   * @constructor
   * @typedef {Object} EmailIngestion
   * @property @param {string} id
   * @property @param {string} ingestionDate
   * @property @param {string[]} ingestionDates
   * @property @param {string} status
   * @property @param {string} subject
   */
  constructor(id = null, ingestionDate = null, ingestionDates = [], status = null, subject = null) {
    this.id = id;
    this.ingestionDate = ingestionDate;
    this.ingestionDates = ingestionDates;
    this.status = status;
    this.subject = subject;
  }

  static create(entity) {
    const attr = entity.attributes;
    return new EmailIngestion(entity.id, attr.ingestionDate, attr.ingestionDates, attr.status, attr.subject);
  }
}
