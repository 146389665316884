import { economics } from '@/router/private/modules/dashboardSSP/analytics/economics';
import { deals } from '@/router/private/modules/dashboardSSP/analytics/deals';
import { openMarket } from '@/router/private/modules/dashboardSSP/analytics/openMarket';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { dealsOtt } from '@/router/private/modules/dashboardSSP/analytics/dealsOtt';
import { markets } from '@/router/private/modules/dashboardSSP/analytics/markets';
import { skin } from '@/router/private/modules/dashboardSSP/analytics/skin';
import { others } from '@/router/private/modules/dashboardSSP/analytics/others';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;

export const analytics = {
  path: 'analytics',
  name: `${basePath}-analytics`,
  component: () =>
    import(
      /* webpackChunkName: "ds-analytics-economics" */ '@/views/private/modules/dashboardSSP/analytics/AnalyticsIndex'
    ),
  meta: {
    requiresAuth: true,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.SELLER.id, ROLES.CLIENT.id],
  },
  children: [economics, deals, openMarket, dealsOtt, markets, skin, others],
  props: true,
};
