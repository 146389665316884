import Status from '@/model/shared/Status';

export const DRAFT_STATUS = new Status('DRAFT', 'DRAFT', 'blue');
export const PENDING_STATUS = new Status('PENDING', 'PENDING', 'indigo');
export const WAITING_STATUS = new Status('WAITING', 'WAITING', 'yellow');
export const RUNNING_STATUS = new Status('RUNNING', 'RUNNING', 'green');
export const PAUSED_STATUS = new Status('PAUSED', 'PAUSED', 'yellow');
export const COMPLETED_STATUS = new Status('COMPLETED', 'COMPLETED', 'indigo');
export const FAILED_STATUS = new Status('FAILED', 'FAILED', 'red');

export const CAMPAIGN_STATUS = [
  DRAFT_STATUS,
  PENDING_STATUS,
  WAITING_STATUS,
  RUNNING_STATUS,
  PAUSED_STATUS,
  COMPLETED_STATUS,
  FAILED_STATUS,
];

export const CAMPAIGN_STATUS_DEFAULT = DRAFT_STATUS;
