import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { basicInfo } from '@/router/private/modules/ecommerce/admin/fraudTemplates/formEdit/basicInfo';
import { fraud } from '@/router/private/modules/ecommerce/admin/fraudTemplates/formEdit/fraud';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const edit = {
  path: ':fraudTemplateId/:formMode',
  name: `${basePath}-edit-fraud-template`,
  redirect: { name: basicInfo.name },
  component: () =>
    import(
      /* webpackChunkName: "ec-fraudTemplates" */ '@/views/private/modules/ecommerce/fraudTemplates/FraudTemplatesForm'
    ),
  meta: {
    requiresAuth: true,
    title: 'Fraud Template Edit',
    breadcrumbs: new BreadcrumbItem('Edit Fraud Template', { name: `${basePath}-edit-fraud-template` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id],
  },
  children: [basicInfo, fraud],
  props: true,
};
