import { COUNTRIES_KEY, COUNTRIES_MAP_KEY, GET_COUNTRIES_REQUEST_KEY } from './keys';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { getCountries } from '@/services/modules/Core/country';

const actions = {
  [GET_COUNTRIES_REQUEST_KEY]: async ({ state, commit }) => {
    try {
      if (state[COUNTRIES_KEY].length) return state[COUNTRIES_KEY];
      const { data } = await getCountries();
      const countriesMap = new Map(data.map(country => [country.id, country]));

      commit(COUNTRIES_MAP_KEY, countriesMap);
      commit(COUNTRIES_KEY, data);
    } catch (error) {
      if (error.code !== HTTPStatusCode.Cancel) throw error;
    }
  },
};

export default actions;
