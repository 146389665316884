import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;

export const economics = {
  path: 'economics',
  name: `${basePath}-economics`,
  component: () =>
    import(
      /* webpackChunkName: "ds-analytics-economics" */ '@/views/private/modules/dashboardSSP/analytics/economics/Economics'
    ),
  meta: {
    requiresAuth: true,
    title: 'Analytics Economics',
    contextSecure: CONTEXTS.DASHBOARD_SSP.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id],
    loadingIcon: true,
    breadcrumbs: new BreadcrumbItem('Economics', { name: `${basePath}-economics` }, {}),
  },
  props: true,
};
