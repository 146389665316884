export const logout = {
  noBreadcrums: true,
  path: '/logout',
  name: 'logout',
  component: () => import(/* webpackChunkName: "logout" */ '@/views/private/shared/Logout.vue'),
  meta: {
    requiresAuth: true,
    title: 'Logout',
  },
};
