import { initialState } from '@/store/modules/filters/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';

import {
  BREAKDOWN_KEY,
  COLUMN_CONFIG_KEY,
  FILTER_LIST_KEY,
  ITEMS_PER_PAGE_KEY,
  QUICK_FILTER_LIST_KEY,
  SAVE_BREAKDOWN_KEY,
  SAVE_COLUMN_CONFIG_KEY,
  SAVE_FILTERS_KEY,
  SAVE_ITEMS_PER_PAGE_KEY,
  SAVE_QUICK_FILTERS_KEY,
  SAVE_SORTERS_KEY,
  SORTER_LIST_KEY,
} from './keys';

const mutations = {
  [SAVE_FILTERS_KEY]: (state, filters) => {
    state[FILTER_LIST_KEY] = filters;
  },
  [SAVE_QUICK_FILTERS_KEY]: (state, filters) => {
    state[QUICK_FILTER_LIST_KEY] = filters;
  },

  [SAVE_BREAKDOWN_KEY]: (state, payload) => {
    state[BREAKDOWN_KEY].set(payload.idView, payload.breakdown);
  },

  [SAVE_COLUMN_CONFIG_KEY]: (state, payload) => {
    state[COLUMN_CONFIG_KEY].set(payload.idView, payload.config);
  },

  [SAVE_ITEMS_PER_PAGE_KEY]: (state, { idView, number }) => {
    state[ITEMS_PER_PAGE_KEY].set(idView, parseInt(number) || 50);
  },

  [SAVE_SORTERS_KEY]: (state, sortersMap) => {
    state[SORTER_LIST_KEY] = sortersMap;
  },

  [RESET_STORE_MODULE_MUTATION]: state => {
    state[BREAKDOWN_KEY] = initialState()[BREAKDOWN_KEY];
    state[COLUMN_CONFIG_KEY] = initialState()[COLUMN_CONFIG_KEY];
    state[FILTER_LIST_KEY] = initialState()[FILTER_LIST_KEY];
    state[ITEMS_PER_PAGE_KEY] = initialState()[ITEMS_PER_PAGE_KEY];
    state[QUICK_FILTER_LIST_KEY] = initialState()[QUICK_FILTER_LIST_KEY];
    state[SORTER_LIST_KEY] = initialState()[SORTER_LIST_KEY];
  },
};

export default mutations;
