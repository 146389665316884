import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { getOperatingSystemsSmart, getOperatingSystemsVideoPlaza } from '@/services/modules/Core/userAgent';

import { OS_SMART_KEY, OS_VIDEO_PLAZA_KEY, GET_OS_SMART_REQUEST_KEY, GET_OS_VIDEO_PLAZA_REQUEST_KEY } from './keys';

const actions = {
  [GET_OS_SMART_REQUEST_KEY]: async ({ state, commit }) => {
    try {
      if (state[OS_SMART_KEY].length) return state[OS_SMART_KEY];
      const { data } = await getOperatingSystemsSmart();
      commit(OS_SMART_KEY, data);
    } catch (error) {
      if (error.code !== HTTPStatusCode.Cancel) throw error;
    }
  },
  [GET_OS_VIDEO_PLAZA_REQUEST_KEY]: async ({ state, commit }) => {
    try {
      if (state[OS_VIDEO_PLAZA_KEY].length) return state[OS_VIDEO_PLAZA_KEY];
      const { data } = await getOperatingSystemsVideoPlaza();
      commit(OS_VIDEO_PLAZA_KEY, data);
    } catch (error) {
      if (error.code !== HTTPStatusCode.Cancel) throw error;
    }
  },
};

export default actions;
