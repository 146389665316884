import { api } from '.';
import { COUNTRIES_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import Country from '@//entities/Core/Country';
import { COUNTRY_NS, COUNTRIES_KEY, COUNTRY_BY_ID } from '@/store/modules/country/keys';
import store from '@/store';

/**
 * @param {string} iso
 * @returns {Promise<Country[]>}
 */
export async function getCountryByIso(iso) {
  const partialUrl = api.createUrl({
    [COUNTRIES_RESOURCE]: undefined,
  });

  if (store.state[COUNTRY_NS][COUNTRIES_KEY].length > 0) {
    return store.getters[COUNTRY_BY_ID](iso);
  }
  const params = new QueryParamsBuilder();
  params.addFilter('iso', iso);

  const {
    data: { data },
  } = await api.get(partialUrl, params.buildWithoutPage());
  const attributes = data[0].attributes;
  return new Country(
    data[0].id,
    attributes.name,
    attributes.iso3,
    attributes.currencyName,
    attributes.currencyCode,
    attributes.continent,
    attributes.isoNumeric,
    attributes.geonameId,
    attributes.coordinates,
    attributes.platform
  );
}
