import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;
export const blockList = {
  path: 'blockList',
  name: `${basePath}-block-list`,
  component: () =>
    import(/* webpackChunkName: "ec-blockList" */ '@/views/private/modules/ecommerce/blockList/BlockList'),
  meta: {
    requiresAuth: true,
    title: 'Block list',
    breadcrumbs: new BreadcrumbItem('Block list', { name: `${basePath}-block-list` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
};
