import { Address } from './Address';

export class AdvertiserShared {
  constructor(id = '', name = '', email = '', category = null, address = new Address()) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.category = category;
    this.address = address;
  }

  /**
   * This method is using on entity parse
   * @param {{id:string, attributes: AdvertiserShared}} entity
   * @return {AdvertiserShared}
   */
  static create(entity) {
    return new AdvertiserShared(entity.id, entity.attributes.name, entity.attributes.email);
  }
}
