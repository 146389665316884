import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;
export const edit = {
  path: ':platformId/details',
  name: `${basePath}-platform-edit`,
  component: () =>
    import(/* webpackChunkName: "ds-platform-edit" */ '@/views/private/modules/dashboardSSP/platform/PlatformEdit'),
  meta: {
    title: 'Platform Edit',
    requiresAuth: true,
    contextSecure: CONTEXTS.DASHBOARD_SSP.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id, ROLES.CLIENT.id],
    breadcrumbs: new BreadcrumbItem('Platform Edit', { name: `${basePath}-platform-edit` }, {}),
  },
  props: true,
};
