import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const breadcrumbs = {
  index: new BreadcrumbItem('Profile', { name: 'profile' }, {}),
};

const profile = [
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/private/modules/core/profile'),
    meta: {
      breadcrumbs: breadcrumbs.index,
      requiresAuth: true,
      title: 'Profile',
      noParentMargins: true,
    },
  },
];

export default profile;
