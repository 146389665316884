var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { attrs: { viewBox: "0 0 192 512" } }, [
    _c("path", {
      staticClass: "fill-current",
      attrs: {
        d: "M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 \n    8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 \n    8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 \n    0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 \n    24 32.235 24 72s32.235 72 72 72s72-32.235 72-72S135.764 0 96 0z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }