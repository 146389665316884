import parseISO from 'date-fns/parseISO';

export function stringToDate(dateString) {
  return typeof dateString === 'string' ? parseISO(dateString) : new Date(dateString);
}
export function dateToYYYYMMDD(d, useTime = false) {
  const date = stringToDate(d);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  let time = '';

  if (useTime) {
    const hours = date.getHours().toString();
    const minutes = date.getMinutes().toString();
    time = ` ${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  }

  return [year, month, day].join('-') + time;
}

/*
 * date: Date
 * formatter: DateFormatter
 * */
export function applyDateFormatters(date, formatters) {
  return date.toLocaleDateString(formatters.locale, formatters.options);
}
export function applyTimeFormatters(date, formatters) {
  return date.toLocaleTimeString(formatters.locale, formatters.options);
}
