import { initialState } from '@/store/modules/country/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import { COUNTRIES_KEY, COUNTRIES_MAP_KEY } from './keys';

const mutations = {
  [COUNTRIES_KEY]: (state, payload) => {
    state[COUNTRIES_KEY] = payload;
  },
  [COUNTRIES_MAP_KEY]: (state, payload) => {
    state[COUNTRIES_MAP_KEY] = payload;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state.countries = initialState()[COUNTRIES_KEY];
  },
};

export default mutations;
