var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { attrs: { viewBox: "0 0 512 512" } }, [
    _c("path", {
      staticClass: "fill-current",
      attrs: {
        d: "M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 \n    0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 \n    0L192 312.69L432.095 72.596c9.997-9.997 26.207-9.997 36.204 \n    0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 \n    294.401c-9.998 9.997-26.207 9.997-36.204-.001z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }