import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { list as clusterList } from '@/router/private/modules/contextual/cluster/list';
import { edit as clusterEdit } from '@/router/private/modules/contextual/cluster/edit';
import { create as clusterCreate } from '@/router/private/modules/contextual/cluster/create';
import { stats as clusterStats } from '@/router/private/modules/contextual/cluster/stats';

const basePath = CONTEXTS.CONTEXTUAL.baseUrl;

export const cluster = {
  path: 'cluster',
  name: `${basePath}-cluster`,
  redirect: { name: clusterList.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    requiresAuth: true,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.SALES.id],
    breadcrumbs: new BreadcrumbItem('Cluster', { name: `${basePath}-cluster-list` }),
  },
  children: [clusterList, clusterEdit, clusterCreate, clusterStats],
  props: true,
};
