import Metric from './Metric';
import Platform from './Platform';
import Country from '../Core/Country';

/**
 * Analytics
 * @class
 * @constructor
 * @public
 */
export default class Analytics {
  /**
   * @typedef {object} Analytics
   * @property @param {string} id
   * @property @param {string} platformDealName || platformDealName
   * @property @param {Country} country || countryName
   * @property @param {Platform} platform || platformName
   * @property @param {Metric[]} metrics
   * @property @param {Metric[]} compareMetrics
   * @property @param {Metric[]} compareMetricsResult
   * @property @param {string} breakdown
   * @property @param {string} subBreakdown
   * @property @param {string} date
   */
  constructor(
    id = null,
    metrics = {},
    compareMetrics = {},
    compareMetricsResult = {},
    platformDealName = '',
    country = new Country(),
    platform = new Platform(),
    breakdown = '',
    subBreakdown = '',
    date = ''
  ) {
    this.id = id;
    this.metrics = metrics;
    this.compareMetrics = compareMetrics;
    this.compareMetricsResult = compareMetricsResult;
    this.platformDealName = platformDealName;
    this.country = country;
    this.platform = platform;
    this.breakdown = breakdown;
    this.subBreakdown = subBreakdown;
    this.date = date;
    this.setPercentages();
  }

  setPercentages() {
    this.percentages = {};
    Object.keys(this.metrics).forEach(key => {
      this.percentages[key] = this.getPercent(this.metrics[key], this.compareMetrics[key]);
    });
  }

  getPercent(metric, compareMetric) {
    const MAX_PERCENT = 100;
    const MIN_PERCENT = 0;

    if (!compareMetric) return metric ? MAX_PERCENT : MIN_PERCENT;
    if (!metric) return -MAX_PERCENT;

    return (metric / compareMetric - 1) * MAX_PERCENT;
  }

  /**
   * @param {object} payload
   * @param {string} payload.id
   * @param {Analytics} payload.attributes
   * @return {Analytics}
   */
  static create({ id: entityId, attributes: { analytic } }) {
    const metrics = Metric.build(analytic[0].metrics);
    const compareMetrics = Metric.build(analytic[0].compareMetrics);
    const compareMetricsResult = Metric.build(analytic[0].compareMetricsResult);

    return new Analytics(
      entityId,
      metrics,
      compareMetrics,
      compareMetricsResult,
      analytic[0].platformDealName,
      new Country(null, analytic[0].countryName),
      new Platform(null, analytic[0].platformName),
      analytic[0].breakdown,
      analytic[0].subBreakdown,
      analytic[0].date
    );
  }
}
