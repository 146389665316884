import { LOGIN_RESOURCE } from '../../keys';
import { api } from '.';

export async function login(credentials) {
  const partialUrl = api.createUrl({
    [LOGIN_RESOURCE]: undefined,
  });

  const { data } = await api.create(partialUrl, credentials);

  return data;
}
