import { AUTH_LOGOUT, PERMISSIONS, USER } from '@/store/modules/auth/keys';
import { setToken } from '@/utils/user/setToken';
import { RESET_STORE_MODULE_ACTION, RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import { SET_ACTIVE_CLIENT_KEY } from './keys';
import { Cookies } from '@/model/shared/Cookies';
import { setPermissions } from '@/utils/user/setPermissions';
import { EMPTY_PERMISSIONS } from '@/model/shared/constants';
import { RESET_STORE_FILTER } from '@/store/modules/filters/keys';
import { RESET_USER_CONTEXT_SETTINGS } from '@/store/modules/settings/keys';

const cookies = new Cookies();

const actions = {
  [SET_ACTIVE_CLIENT_KEY]: ({ commit, dispatch, rootGetters }, activeClientContext) => {
    if (activeClientContext) {
      const permissions = setPermissions(rootGetters[USER], activeClientContext.type);
      commit(PERMISSIONS, permissions, { root: true });
      cookies.setCookie('sunClient', JSON.stringify({ id: activeClientContext.id, type: activeClientContext.type }));
      commit(SET_ACTIVE_CLIENT_KEY, activeClientContext);
      commit(RESET_STORE_FILTER, null, { root: true });
      dispatch(RESET_USER_CONTEXT_SETTINGS, null, { root: true });
    } else {
      dispatch(RESET_STORE_MODULE_ACTION);
    }
  },
  [RESET_STORE_MODULE_ACTION]: ({ commit }) => {
    commit(PERMISSIONS, EMPTY_PERMISSIONS, { root: true });
    cookies.deleteCookie('sunClient');
    commit(RESET_STORE_MODULE_MUTATION);
  },
  [AUTH_LOGOUT]: ({ dispatch }) => {
    setToken(null);
    cookies.deleteCookie(process.env.VUE_APP_REFRESH_TOKEN_NAME, true);
    cookies.deleteCookie(process.env.VUE_APP_USER_COOKIE_NAME, true);

    dispatch(`resetStore`, {}, { root: true });
  },
};

export default actions;
