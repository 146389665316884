import {
  BREAKDOWN_KEY,
  COLUMN_CONFIG_KEY,
  FILTER_LIST_KEY,
  ITEMS_PER_PAGE_KEY,
  QUICK_FILTER_LIST_KEY,
  SORTER_LIST_KEY,
} from '@/store/modules/filters/keys';

export const initialState = () => ({
  [BREAKDOWN_KEY]: new Map(),
  [COLUMN_CONFIG_KEY]: new Map(),
  [FILTER_LIST_KEY]: new Map(),
  [ITEMS_PER_PAGE_KEY]: new Map(),
  [QUICK_FILTER_LIST_KEY]: new Map(),
  [SORTER_LIST_KEY]: new Map(),
});
