import { WALLET_TYPES } from '@/model/modules/socialAudience/wallet/WalletType';

export default class Finance {
  constructor(walletType = null, fee = 0, techCost = 0) {
    this.walletType = walletType;
    this.fee = fee;
    this.techCost = techCost;
  }

  payload() {
    return {
      ...this,
      walletType: this.walletType.id,
    };
  }

  create(entity) {
    const walletType = WALLET_TYPES.find(type => type.id === entity.walletType);

    return new Finance(walletType, entity.fee, entity.techCost);
  }
}
