import { RESET_PASSWORD_REQUEST_RESOURCE } from '../../keys';
import { api } from '.';

export async function resetPasswordAndSendEmail(email) {
  const partialUrl = api.createUrl({
    [RESET_PASSWORD_REQUEST_RESOURCE]: undefined,
  });
  const { data } = await api.create(partialUrl, { email });

  return data;
}
