import logger from '@/model/shared/Logger';

/**
 * Automatically creates a reset module that commits the reset mutation for each module
 */
export const RESET_STORE_ACTION = 'RESET_STORE';
export const RESET_STORE_MODULE_MUTATION = 'reset';
export const RESET_STORE_MODULE_ACTION = 'reset';

export const createResetStore = modules => ({
  actions: {
    [RESET_STORE_ACTION]: ({ commit }) => {
      modules.forEach(module => {
        try {
          commit(`${module}/${RESET_STORE_MODULE_MUTATION}`);
        } catch (error) {
          logger.error(`Module ${module} has not reset`);
        }
      });
    },
  },
  mutations: {},
  getters: {},
  state: {},
});
