import { api } from '.';
import { USER_AGENTS_RESOURCE, BROWSER_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import coreEntityParser from '@/entities/Core/CoreEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { VIDEO_PLAZA } from '@/model/shared/Platforms';

export const getResources = () => ({
  [USER_AGENTS_RESOURCE]: undefined,
  [BROWSER_RESOURCE]: undefined,
});

/**
 * @returns {Promise.<{data:Array<Browser>, meta:undefined}>}
 */
export async function getBrowsersVideoPlaza(params = new QueryParamsBuilder()) {
  params.addFilter('platform', VIDEO_PLAZA.id);
  const partialUrl = api.createUrl(getResources());
  const data = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(coreEntityParser.parse(data.data)).build();
}
