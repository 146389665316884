import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { notFound } from '@/router/private/shared/notFound';
import { analytics } from './analytics';
import { demand } from './demand';
import { admin } from './admin';
import { profile } from './profile';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;
const contextSecure = CONTEXTS.SOCIAL_AUDIENCE.id;

export const socialAudience = {
  path: `/${basePath}`,
  redirect: { name: notFound.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: new BreadcrumbItem('Home', { name: 'home' }),
    contextSecure,
  },
  children: [
    {
      path: `:clientId`,
      component: () =>
        import(/* webpackChunkName: "sa-index" */ '@/views/private/modules/socialAudience/SocialAudienceIndex'),
      children: [analytics, admin, demand, profile],
      props: true,
    },
  ],
  props: true,
};
