export default class User {
  /**
   *
   * @param {string|null} id
   * @param {string} name
   * @param {string} email
   * @param {string} password
   * @param {Boolean} enabled
   */
  constructor(id = null, name = '', email = '', password = '', enabled = true) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.password = password;
    this.enabled = enabled;
  }

  payload() {
    const { id, name, email, password, enabled } = this;
    return {
      id,
      name,
      email,
      password,
      enabled,
    };
  }

  /**
   * @param entity
   * @return {User}
   */
  static create(entity) {
    return new User(
      entity.id,
      entity.attributes.name,
      entity.attributes.email,
      entity.attributes.password,
      entity.attributes.enabled
    );
  }
}
