<template>
  <div id="app" class="bg-gray-200 relative">
    <transition name="fade">
      <router-view />
    </transition>

    <asterix-toasts />
    <loading-toast />
  </div>
</template>

<script>
import AsterixToasts from '@/components/molecules/shared/AsterixToasts';
import LoadingToast from '@/components/molecules/shared/loadingToast/LoadingToast';
import { isCustomDomain, customImgTypes } from '@/utils/domain';

export default {
  name: 'App',
  components: {
    AsterixToasts,
    LoadingToast,
  },
  data() {
    return {
      customImgTypes,
    };
  },
  created() {
    if (isCustomDomain()) {
      let links = document.querySelectorAll("link[rel~='icon']");
      if (links.length === 0) {
        links = document.createElement('link');
        links.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(links);
      }
      links.forEach(link => {
        link.href = `${process.env.VUE_APP_IMAGES_URL}/${window.location.hostname}/${this.customImgTypes.FAVICON}`;
      });
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  &.padding-y-container,
  .padding-y-container {
    padding-top: 74px;
  }
}

.-top-78 {
  top: -78px;
}

@media (max-width: 640px) {
  #app.padding-256,
  #app .padding-256 {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .-top-78 {
    top: -95px;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.apexcharts-tooltip {
  max-height: 40vh;
  overflow-y: auto !important;
  pointer-events: auto !important;
}

.apexcharts-tooltip:hover {
  opacity: 1;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  position: sticky;
  top: 0;
}
</style>
