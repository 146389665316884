import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const create = {
  path: 'new',
  name: `${basePath}-new-advertiser`,
  component: () =>
    import(/* webpackChunkName: "ec-advertiser" */ '@/views/private/modules/ecommerce/advertiser/AdvertiserEdit'),
  meta: {
    requiresAuth: true,
    title: 'Advertiser',
    breadcrumbs: new BreadcrumbItem('New Advertiser', { name: `${basePath}-new-advertiser` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  props: true,
};
