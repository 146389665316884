import { initialState } from '@/store/modules/breadcrumbs/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import { BREADCRUMB_FROM_ROUTE_KEY, BREADCRUMBS_KEY } from './keys';

const mutations = {
  [BREADCRUMB_FROM_ROUTE_KEY]: (state, payload) => {
    state[BREADCRUMBS_KEY] = payload;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[BREADCRUMBS_KEY] = initialState()[BREADCRUMBS_KEY];
  },
};

export default mutations;
