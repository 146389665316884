import { APP_CONFIG_KEY, RESET_APP_CONFIG } from './keys';
import { initialState } from './initialState';

const actions = {
  [RESET_APP_CONFIG]: ({ commit }) => {
    commit(APP_CONFIG_KEY, initialState()[APP_CONFIG_KEY]);
  },
};

export default actions;
