import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { publisherTechFee } from '@/router/private/modules/ecommerce/billing/publisherTechFee';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const billing = {
  path: 'billing',
  name: `${basePath}-billing`,
  redirect: { name: publisherTechFee.name },
  meta: {
    requiresAuth: true,
    rolesAccepted: [ROLES.ADMIN.id],
  },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [publisherTechFee],
  props: true,
};
