export class Address {
  constructor(street = '', city = '', country = '', county = '', postalCode = '') {
    this.street = street;
    this.city = city;
    this.country = country;
    this.county = county;
    this.postalCode = postalCode;
  }

  payload() {
    return {
      street: this.street,
      city: this.city,
      country: this.country.iso,
      county: this.county,
      postalCode: this.postalCode,
    };
  }

  static from({ country, city, street, county, postalCode }) {
    return new Address(street, city, country, county, postalCode);
  }
}
