import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const basicInfo = {
  path: '',
  name: `${basePath}-edit-fraud-template-basic-info`,
  component: () =>
    import(/* webpackChunkName: "ec-fraudTemplates" */ '@/components/organisms/modules/ecommerce/fraud/form/BasicInfo'),

  meta: {
    requiresAuth: true,
    title: 'Edit Fraud Template',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id],
  },
  props: true,
};
