import { ROLES } from '@/model/shared/roles';

export const CONTEXTUAL = {
  [ROLES.ADMIN.id]: {
    CREATE: ['CLUSTER'],
    DELETE: ['CLUSTER'],
    SHOW_LIST: ['CLUSTER'],
    EDIT: ['CLUSTER'],
  },
  [ROLES.AD_OPS.id]: {
    CREATE: ['CLUSTER'],
    DELETE: ['CLUSTER'],
    SHOW_LIST: ['CLUSTER'],
    EDIT: ['CLUSTER'],
  },
  [ROLES.SALES.id]: {
    CREATE: ['CLUSTER'],
    DELETE: ['CLUSTER'],
    SHOW_LIST: ['CLUSTER'],
    EDIT: ['CLUSTER'],
  },
};
