import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;
const contextSecure = CONTEXTS.SOCIAL_AUDIENCE.id;

export const list = {
  path: 'list',
  name: `${basePath}-campaigns`,
  component: () =>
    import(/* webpackChunkName: "sa-campaign" */ '@/views/private/modules/socialAudience/campaign/CampaignList'),
  meta: {
    requiresAuth: true,
    title: 'Campaigns ',
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id, ROLES.CLIENT.id, ROLES.ADVERTISER.id],
  },
  props: true,
};
