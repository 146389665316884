import { CREATIVE_STATUS } from '@/model/modules/socialAudience/creative/CreativeStatus';

/**
 * CreativeNative
 * @class
 * @constructor
 * @public
 */
export default class CreativeNative {
  /**
   * Create a Creative
   * @param {string} id
   * @param {string} link
   * @param {string} pixel
   * @param {string} fileName
   * @param {number} impressions
   * @param {number} clicks
   * @param {File} file
   * @param {string} urlFile
   */
  constructor(
    id = null,
    link = null,
    pixel = '',
    fileName = null,
    impressions = null,
    clicks = null,
    file = null,
    urlFile = null,
    status = null
  ) {
    this.id = id;
    this.link = link;
    this.pixel = pixel;
    this.fileName = fileName;
    this.impressions = impressions;
    this.clicks = clicks;
    this.file = file;
    this.urlFile = urlFile;
    this.status = status;
  }

  payload() {
    const body = new FormData();

    body.set('link', this.link);
    body.set('pixel', this.pixel);
    body.set('status', this.status.value);
    if (this.file) body.set('file', this.file);

    return body;
  }

  static create(entity) {
    const { link, pixel, fileName, impressions, clicks, url } = entity.attributes;

    const status = CREATIVE_STATUS.find(status => status.id === entity.attributes.status);

    return new CreativeNative(entity.id, link, pixel, fileName, impressions, clicks, null, url, status);
  }
}
