import { CONTEXTS } from '@/model/shared/contexts';
import { create } from './create';
import { edit } from './edit';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;

export const creative = {
  path: 'creative',
  name: `${basePath}-creative`,
  component: () =>
    import(
      /* webpackChunkName: "sa-advertiser" */ '@/views/private/modules/socialAudience/creative/CreativeListHandler'
    ),
  children: [create, edit],
  props: true,
  meta: {
    title: 'Campaign',
    requiresAuth: true,
    contextSecure: CONTEXTS.SOCIAL_AUDIENCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id],
  },
};
