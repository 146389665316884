import { CREATE_TOAST_KEY, DELETE_TOAST_KEY, TOAST_LIST_KEY } from './keys';
import { Toast } from '../../../model/shared/Toast';

const actions = {
  [CREATE_TOAST_KEY]: ({ commit, state, dispatch }, payload) => {
    if (payload) {
      const toast = new Toast(payload.title || '', payload.message || '', payload.type || 'info');
      if (state[TOAST_LIST_KEY].length < 4) {
        commit(CREATE_TOAST_KEY, toast);
      } else {
        dispatch(DELETE_TOAST_KEY, { index: 0 });
        setTimeout(() => {
          commit(CREATE_TOAST_KEY, toast);
        }, 500);
      }
      setTimeout(() => {
        const saveId = toast.id;
        const index = state[TOAST_LIST_KEY].findIndex(toastF => toastF.id === saveId);
        if (index || index === 0) dispatch(DELETE_TOAST_KEY, { index });
      }, 5000);
    }
  },
  [DELETE_TOAST_KEY]: ({ state, commit }, payload) => {
    const auxList = JSON.parse(JSON.stringify(state[TOAST_LIST_KEY]));
    if (auxList[payload.index]) {
      auxList.splice(payload.index, 1);
    }
    commit(DELETE_TOAST_KEY, auxList);
  },
};

export default actions;
