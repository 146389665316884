import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;
const contextSecure = CONTEXTS.SOCIAL_AUDIENCE.id;

export const analytics = {
  path: 'analytics',
  name: `${basePath}-analytics`,
  component: () =>
    import(/* webpackChunkName: "sa-analytics" */ '@/views/private/modules/socialAudience/analytics/AnalyticsList.vue'),
  meta: {
    requiresAuth: true,
    title: 'Analytics ',
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.ADVERTISER.id, ROLES.AD_OPS.id],
    breadcrumbs: new BreadcrumbItem('Analytics', { name: `${basePath}-analytics` }, {}),
  },
  props: true,
};
