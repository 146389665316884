/// <reference path="./DealComment.js" />
/// <reference path="./PlatformDeals.js" />

import { stringToDate } from '@/filters/dateFilters';
import MoneyAmount from '@/model/shared/MoneyAmount';
import Seller from './Seller';
import { DEAL_STATUS, DEAL_STATUS_DEFAULT } from '@/model/modules/dashboardSSP/deal/DealStatus';
import { PLATFORM_DEALS_RESOURCE } from '@/services/keys';

/**
 * Deal
 * @class
 * @constructor
 * @public
 */

export default class Deal {
  /**
   * @constructor
   * @typedef {object} Deal
   * @property @param {string} id
   * @property @param {string} name
   * @property @param {number} idOpportunity
   * @property @param {string} type
   * @property @param {string | null} start
   * @property @param {string | null} end
   * @property @param {string} status
   * @property @param {string} syncStatus
   * @property @param {Seller} seller
   * @property @param {string} agency
   * @property @param {string} commercial
   * @property @param {string} account
   * @property @param {string} brand
   * @property @param {MoneyAmount} amount
   * @property @param {PlatformDeals[]} platformsDeals
   */
  constructor(
    id = null,
    name = '',
    idOpportunity = '',
    type = '',
    start = null,
    end = null,
    status = null,
    syncStatus = 'KO',
    agency = '',
    seller = new Seller(),
    account = '',
    brand = '',
    amount = new MoneyAmount(),
    platformsDeals = []
  ) {
    this.id = id;
    this.name = name;
    this.idOpportunity = idOpportunity;
    this.type = type;
    this.start = start;
    this.end = end;
    this.status = status || DEAL_STATUS_DEFAULT;
    this.syncStatus = syncStatus;
    this.agency = agency;
    this.seller = seller;
    this.account = account;
    this.brand = brand;
    this.amount = amount;
    this.platformsDeals = platformsDeals;
  }

  payload() {
    const { start, end, ...params } = this;
    return {
      ...params,
      idOpportunity: this.idOpportunity,
      ammount: this.ammount?.value,
      amountCurrencyId: this.ammount?.currency,
      startDate: start,
      endDate: end,
      dealAccount: this.account,
      dealBrand: this.brand,
    };
  }

  /**
   * @param {{id:string,attributes:Deal}} entity
   * @returns {Deal}
   */
  static create(entity, included) {
    const attr = entity.attributes;
    const seller = new Seller(attr.sellerId);
    const start = attr.start;
    const end = attr.end;
    const amount = new MoneyAmount(attr.amount.currency, attr.amount.value);
    const status = DEAL_STATUS.find(status => status.id === attr.status);

    return new Deal(
      entity.id,
      attr.name,
      attr.idOpportunity,
      attr.type,
      start ? stringToDate(start) : null,
      end ? stringToDate(end) : null,
      status,
      attr.syncStatus,
      attr.agency,
      seller,
      attr.account,
      attr.brand,
      amount,
      included?.[PLATFORM_DEALS_RESOURCE]
    );
  }
}
