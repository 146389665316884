import { analytics } from '@/router/private/modules/thirdParty/analytics';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';
import { demand } from './demand';
import { admin } from './admin';
import { profile } from './profile';
import { connection } from '@/router/private/modules/thirdParty/platforms/connection';
import { notFound } from '@/router/private/shared/notFound';
import campaignManagerList from '@/router/private/modules/thirdParty/campaignManager/campaignManagerList';
import campaignManagerToday from '@/router/private/modules/thirdParty/campaignManager/campaignManagerToday';
import campaignManagerTomorrow from '@/router/private/modules/thirdParty/campaignManager/campaignManagerTomorrow';
import campaignManagerStarting from '@/router/private/modules/thirdParty/campaignManager/campaignManagerStarting';
import campaignManagerStartingYesterday from '@/router/private/modules/thirdParty/campaignManager/campaignManagerStartingYesterday';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const thirdParty = {
  path: `/${basePath}`,
  redirect: { name: notFound.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: new BreadcrumbItem('Home', { name: 'home' }),
    contextSecure: CONTEXTS.THIRD_PARTY.id,
  },
  children: [
    {
      path: `:clientId`,
      redirect: { name: notFound.name },
      component: () => import(/* webpackChunkName: "tp-index" */ '@/views/private/modules/thirdParty/ThirdPartyIndex'),
      children: [
        analytics,
        connection,
        admin,
        demand,
        profile,
        campaignManagerList,
        campaignManagerToday,
        campaignManagerTomorrow,
        campaignManagerStarting,
        campaignManagerStartingYesterday,
      ],
      props: true,
    },
  ],
  props: true,
};
