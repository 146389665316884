import * as asserts from '@/utils/validation/assertions';

/**
 * @class
 */
class Logger {
  constructor() {
    this.setMode(process.env.NODE_ENV !== 'production');
  }

  setMode(debug = false) {
    this.debug = debug === true ? debug : false;
  }

  /**
   * Generic for passing data to the browser's debugging console
   */
  log({ type = 'log', style = 'font-weight: bold', args = [] } = {}) {
    if (this.debug) {
      const level = `SM-${type.toUpperCase()}:`;
      // eslint-disable-next-line no-console
      console[type].apply(console, [`%c${level}`, style, ...args]);
    }
  }

  /**
   * Informative logging of information.
   * You may use string substitution and additional arguments with this method.
   */
  info(...args) {
    this.log({ type: 'info', style: 'color: blue; font-weight: bold', args });
  }

  /**
   * Outputs a warning message.
   * You may use string substitution and additional arguments with this method.
   */
  warning(...args) {
    this.log({ type: 'warn', args });
  }

  /**
   * Outputs an error message.
   * You may use string substitution and additional arguments with this method.
   */
  error(...args) {
    this.log({ type: 'error', args });
  }
}

/**
 * Create an instance of Logger
 *
 * @return {Logger} A new instance of Logger
 * @private
 */
function createInstance() {
  return new Logger();
}

/**
 * @const {Logger} logger
 */
const logger = createInstance();

logger.wrapWithName = name => {
  asserts.isString(name, 'loggerName');

  const _name = name.toUpperCase();

  return {
    info: (...args) => logger.info(`[${_name}]`, ...args),
    error: (...args) => logger.error(`[${_name}]`, ...args),
    warning: (...args) => logger.warning(`[${_name}]`, ...args),
  };
};

export default logger;
