import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';
import { list } from './list';
import { create } from './create';
import { edit } from './edit';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;

export const campaign = {
  path: 'campaigns',
  name: `${basePath}-campaign`,
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    breadcrumbs: new BreadcrumbItem('Campaigns', { name: `${basePath}-campaigns` }, {}),
  },
  children: [list, edit, create],
  props: true,
};

export default campaign;
