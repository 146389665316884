import IncludeItem from '@/model/shared/IncludeItem';
import { SMART_AD_SERVER } from '@/model/shared/Platforms';

export default class TargetNative {
  /**
   * @param {String} id
   * @param {array} domains
   * @param {array} countries
   * @param {array} browsers
   * @param {array} devices
   */
  constructor(id = null, domains = [], countries = [], browsers = [], devices = []) {
    this.id = id;
    this.domains = domains;
    this.countryFilter = new IncludeItem(
      'countryFilter',
      'Countries',
      countries.length > 0 ? IncludeItem.VALID_TYPES.INCLUDE : IncludeItem.VALID_TYPES.NONE,
      countries
    );
    this.browserFilter = new IncludeItem(
      'browserFilter',
      'Browsers',
      browsers.length > 0 ? IncludeItem.VALID_TYPES.INCLUDE : IncludeItem.VALID_TYPES.NONE,
      browsers
    );
    this.deviceFilter = new IncludeItem(
      'deviceFilter',
      'Devices',
      devices.length > 0 ? IncludeItem.VALID_TYPES.INCLUDE : IncludeItem.VALID_TYPES.NONE,
      devices,
      false
    );
  }

  payload() {
    const { domains, countryFilter, browserFilter, deviceFilter } = this;
    return {
      domains,
      countries: countryFilter.value.map(item => ({
        ...item,
        externalId: item.externalId || item.platform[SMART_AD_SERVER.id],
      })),
      browsers: browserFilter.value.map(item => ({
        ...item,
        externalId: item.externalId || item.platform[SMART_AD_SERVER.id],
      })),
      devices: deviceFilter.value.map(item => ({
        ...item,
        externalId: item.externalId || item.platform[SMART_AD_SERVER.id],
      })),
    };
  }

  clone() {
    return new TargetNative(
      this.id,
      this.domains,
      this.countryFilter.value,
      this.browserFilter.value,
      this.deviceFilter.value
    );
  }

  static create(entity) {
    const { domains, countries, browsers, devices } = entity?.attributes;

    return new TargetNative(entity?.id, domains, countries, browsers, devices);
  }
}
