import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const edit = {
  path: ':id/edit',
  name: `${basePath}-edit-user`,
  component: () =>
    import(/* webpackChunkName: "ec-user" */ '@/views/private/modules/ecommerce/users/EcommerceUsersEdit'),
  meta: {
    requiresAuth: true,
    title: 'User Edit',
    breadcrumbs: new BreadcrumbItem('Edit User', { name: `${basePath}-edit-user` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id],
  },
  props: true,
};
