<template>
  <div class="fixed waiting-toast z-100 w-26" :class="{ hidden: isHidden }">
    <div
      class="relative bg-white rounded-lg px-6 py-4 flex shadow-xl justify-start items-center z-100 border border-gray-200"
      :class="{ 'bounce-animation': status !== loading }"
    >
      <div class="flex items-center w-full">
        <div
          class="p-2 opacity-75 mr-4 inline-block justify-center rounded-full -ml-2"
          :class="{
            'bg-red-200': status === error,
            'bg-green-200': status === success,
            'bg-blue-200': status === loading,
          }"
        >
          <sun-loading v-if="status === loading" class="h-4 w-4 text-blue-900" />
          <check-svg v-else-if="status === success" class="h-4 w-4 text-green-900" />
          <warning-svg v-else class="h-4 w-4 text-red-900" />
        </div>
        <div class="flex flex-col max-w-full text-sm">
          <p v-if="status === loading" class="text-blue-700 font-black text-left">Please wait...</p>
          <p v-else-if="status === success" class="text-green-700 font-black text-left">Success!</p>
          <p v-else class="text-red-700 font-black text-left">Error!</p>
          <slot :close="close">
            <p class="text-gray-600 text-left">
              <slot name="message" />
            </p>
          </slot>
        </div>
      </div>
      <div
        v-if="status !== loading"
        class="absolute font-black close-right cursor-pointer text-gray-600 p-2"
        @click.prevent="close()"
      >
        <close-svg class="h-3 text-gray-700" />
      </div>
    </div>
  </div>
</template>
<script>
import CloseSvg from '@/components/icons/CloseSvg';
import CheckSvg from '@/components/icons/CheckSvg';
import WarningSvg from '@/components/icons/WarningSvg';
import { LOADING_TOAST_STATUS } from '@/model/shared/LoadingToastStatus';

export default {
  name: 'WaitToast',
  components: {
    CheckSvg,
    CloseSvg,
    WarningSvg,
  },
  props: {
    value: { type: Boolean, default: false },
    status: { type: String, default: LOADING_TOAST_STATUS.LOADING },
  },
  data() {
    return {
      loading: LOADING_TOAST_STATUS.LOADING,
      success: LOADING_TOAST_STATUS.SUCCESS,
      error: LOADING_TOAST_STATUS.ERROR,
    };
  },
  computed: {
    isHidden() {
      return !this.value;
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.$emit('input', false);
    },
  },
};
</script>
<style scoped>
.waiting-toast {
  right: 1rem;
  top: 0.25rem;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(50px);
  }
}

.bounce-animation {
  animation: bounce 0.3s ease-in 8 alternate;
}

.close-right {
  right: 0.5rem;
  top: 0.1rem;
}

.w-26 {
  width: 26rem;
}
</style>
