import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;

export const deals = {
  path: 'deals',
  name: `${basePath}-deals`,
  component: () =>
    import(/* webpackChunkName: "ds-analytics-deals" */ '@/views/private/modules/dashboardSSP/analytics/deals/Deals'),
  meta: {
    requiresAuth: true,
    title: 'Analytics Deals Web',
    contextSecure: CONTEXTS.DASHBOARD_SSP.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.SELLER.id, ROLES.CLIENT.id],
    breadcrumbs: new BreadcrumbItem('Deals Web', { name: `${basePath}-deals` }),
  },
  props: true,
};
