import * as resourceKeys from '@/services/keys';

/**
 * Role
 * @class
 * @public
 */
export class Role {
  /**
   * Create a Role
   * @constructor
   * @typedef {object} Role
   * @property @param {string} id
   * @property @param {string} name
   * @property @param {string} resource find on /services/keys.js
   */
  constructor(id, name, resource) {
    this.id = id;
    this.name = name || id;
    this.resource = resource || resourceKeys?.[`${id}_RESOURCE`];
  }

  toString() {
    return this.name;
  }
}

export const ROLES = {
  ADMIN: new Role('ADMIN', 'Admin'),
  AD_OPS: new Role('AD_OPS', 'Ad ops'),
  ADVERTISER: new Role('ADVERTISER', 'Advertiser'),
  SUPER_ADVERTISER: new Role('SUPER_ADVERTISER', 'Super Advertiser'),
  CLIENT: new Role('CLIENT', 'Client'),
  FINANCE: new Role('FINANCE', 'Finance'),
  OFFICE_OWNER: new Role('CLIENT', 'Office Owner'),
  PUBLISHER: new Role('PUBLISHER', 'Publisher'),
  SELLER: new Role('SELLER', 'Seller', resourceKeys.CLIENT_RESOURCE),
  USER: new Role('USER', 'User'),
  MARKETING: new Role('MARKETING', 'Marketing'),
  SALES: new Role('SALES', 'Sales'),
  TRAFFICKER: new Role('TRAFFICKER', 'Trafficker'),
  ACCOUNT_MANAGER: new Role('ACCOUNT_MANAGER', 'Account Manager'),
  DESIGNER: new Role('DESIGNER', 'Designer'),
  EXTERNAL_DESIGNER: new Role('EXTERNAL_DESIGNER', 'External Designer'),
  SUPERVIEWER: new Role('SUPERVIEWER', 'Superviewer'),
};
