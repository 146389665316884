import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const campaignManagerStartingYesterday = {
  path: 'campaign-manager-starting-yesterday',
  name: `${basePath}-campaign-manager-starting-yesterday`,
  component: () =>
    import(
      /* webpackChunkName: "tp-campaign-manager-starting-yesterday" */ '@/views/private/modules/thirdParty/campaignManager/campaignManagerStartingYesterday/CampaignManagerStartingYesterday.vue'
    ),
  meta: {
    breadcrumbs: new BreadcrumbItem(
      'Campaign Manager Starting Yesterday',
      { name: `${basePath}-campaign-manager-starting-yesterday` },
      {}
    ),
    requiresAuth: true,
    title: 'Campaign Manager Starting Yesterday',
  },
  props: true,
};

export default campaignManagerStartingYesterday;
