import { FILTER_SEARCH } from '@/model/shared/QuickFilterSearchName';

class QueryParamsBuilder {
  constructor(page = 1, itemsPerPage = 10) {
    this.page = page;
    this.itemsPerPage = itemsPerPage;
    /** @readonly @type {Object.<string, any>} List of filters */
    this.filters = {};
  }

  addInclude(...include) {
    this.include = include.join();
    return this;
  }

  addSort(field, order = 'ASC') {
    this.sort = this.constructor.formatSortParam({ field, order });
    return this;
  }

  addGroupBy(groupBy) {
    this.groupBy = groupBy;
    return this;
  }

  addQuery(query) {
    this.query = query;
    return this;
  }

  addQ(q) {
    this.q = q;
    return this;
  }

  addFilter(key, value) {
    this.filters[key] = value;
    return this;
  }

  /**
   * This method delete filter values and return values deleted.
   * @param string key - filter name
   * @returns string
   */
  removeFilter(key) {
    const valueToDelete = this.filters[key];
    delete this.filters[key];
    return valueToDelete;
  }

  setBreakdown(value) {
    this.breakdown = value;
    return this;
  }

  setSubBreakdown(value) {
    this.subBreakdown = value;
    return this;
  }

  build() {
    const { page, itemsPerPage, sort } = this;
    const params = this.buildWithoutPage();

    if (typeof sort !== 'undefined' && sort !== null) {
      params.sort = sort;
    }

    return {
      ...params,
      'page[number]': page,
      'page[size]': itemsPerPage,
    };
  }

  buildWithoutPage() {
    const params = {};
    const { filters, include, breakdown, subBreakdown, all, query, q } = this;

    Object.entries(filters).forEach(filter => {
      const [key, value] = filter;
      if (value?.operator) {
        params[`filter[${key}][${value.operator}]`] = value.value;
      } else if (key !== FILTER_SEARCH) {
        params[`filter[${key}]`] = value;
      } else {
        params[key.toLocaleLowerCase()] = value;
      }
    });

    if (typeof include !== 'undefined') {
      params.include = include;
    }

    if (typeof breakdown !== 'undefined') {
      params.breakdown = breakdown;
    }

    if (typeof all !== 'undefined') {
      params.all = all;
    }

    if (typeof subBreakdown !== 'undefined') {
      params.subBreakdown = subBreakdown;
    }

    if (typeof query !== 'undefined') {
      params.query = query;
    }

    if (typeof q !== 'undefined') {
      params.q = q;
    }

    return {
      ...params,
    };
  }

  buildQuery() {
    const params = {
      'page[number]': this.page,
      'page[size]': this.itemsPerPage,
    };

    if (this.query) {
      params.q = this.query;
    }

    return params;
  }

  static formatSortParam({ field, order }) {
    return order.toLowerCase() === 'desc' ? `-${field.toString()}` : field;
  }
}
export default QueryParamsBuilder;
