import Vue from 'vue';
import SunUI from '@sunmedia/sun-ui';
import VueApexCharts from 'vue-apexcharts';
import VueMeta from 'vue-meta';
import draggable from 'vuedraggable';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import App from './App.vue';
import { INIT_SERVICE_WORKER } from './registerServiceWorker';
import router from './router';
import store from './store';
import { capitalize } from './utils/text/capitalize';
import { lowercase } from './utils/text/lowercase';
import { uppercase } from './utils/text/uppercase';
import currencyFilter from './filters/currency';
import percentageFilter from './filters/percentage';
import { thousandSeparator } from './filters/thousandSeparator';
import numberFormat from './filters/numberFormat';
import numberFormatEs from './filters/numberFormatEs';
import './assets/css/main.css';
import { isProduction } from '@/utils/environments';
import 'ag-grid-enterprise';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

Vue.config.productionTip = false;

INIT_SERVICE_WORKER(process.env.NODE_ENV);
Vue.use(VueApexCharts);
Vue.use(VueMeta);
Vue.use(SunUI);

Vue.component('Apexchart', VueApexCharts);
Vue.component('Draggable', draggable);
Vue.component('VueDatePicker', VueDatePicker);

Vue.filter('lowercase', lowercase);
Vue.filter('capitalize', capitalize);
Vue.filter('uppercase', uppercase);
Vue.filter('numFormat', numberFormat);
Vue.filter('numFormatEs', numberFormatEs);
Vue.filter('currency', currencyFilter);
Vue.filter('percentage', percentageFilter);
Vue.filter('thousandSeparator', thousandSeparator);

if (isProduction) {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_ENVIRONMENT,
    release: process.env.VUE_APP_VERSION,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_API_URL, /^\//],
      }),
    ],
    debug: true,
    tracesSampleRate: 0.2,
    tracingOptions: {
      trackComponents: true,
    },
    logErrors: true,
    attachProps: true,
    attachStacktrace: true,
  });
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
