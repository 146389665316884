import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const list = {
  path: 'list',
  name: `${basePath}-fraud-templates-list`,
  component: () =>
    import(
      /* webpackChunkName: "ec-fraudTemplates" */ '@/views/private/modules/ecommerce/fraudTemplates/FraudTemplates'
    ),
  meta: {
    requiresAuth: true,
    title: 'Fraud Templates',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id],
  },
  props: true,
};

export default list;
