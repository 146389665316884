/**
 * Option
 *
 * @class
 * @public
 */
export default class SelectOption {
  /**
   * @constructor
   * @type SelectOption
   * @property @param {string} id
   * @property @param {string|undefined} name
   */
  constructor(id, name = '') {
    this.id = id;
    this.name = name || id?.charAt(0) + id?.substring(1).toLowerCase();
  }

  isEqual(option) {
    return this.id === option?.id;
  }
}
