/**
 * @class
 * @public
 */
export default class TemplateCreative {
  /**
   * @constructor
   * @type TemplateCreative
   * @property @param {string} name
   * @property @param {string} description
   */
  constructor(name = '', description = '') {
    this.name = name;
    this.description = description;
  }

  payload() {
    return this;
  }

  clone() {
    return new TemplateCreative(this.name, this.description);
  }
}
