import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const others = {
  path: 'others',
  name: `${basePath}-others`,
  component: () =>
    import(/* webpackChunkName: "tp-analytics" */ '@/views/private/modules/thirdParty/analytics/Others.vue'),
  meta: {
    breadcrumbs: new BreadcrumbItem('Others', { name: `${basePath}-others` }, {}),
    requiresAuth: true,
    title: 'Others',
  },
  props: true,
};

export default others;
