import store from '@/store';
import { USER } from '@/store/modules/auth/keys';

export function hasRolePermissions(routeTo) {
  const roles = routeTo.meta.rolesAccepted;
  const context = routeTo.meta.contextSecure;
  const userRoles = store.getters[USER]?.contextRoles;

  const permissionNeeded = roles && context;
  const hasPermission = !!userRoles?.find(contextRole => {
    return contextRole.context === context && roles.includes(contextRole?.role);
  });

  return !permissionNeeded || hasPermission;
}
