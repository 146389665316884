import axios from 'axios';
import logger from '@/model/shared/Logger';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import store from '@/store';
import router from '@/router';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { AUTH_REFRESH, AUTH_LOGOUT, TOKEN, IS_AUTHENTICATED, TOKEN_TO_RESET_PASS } from '@/store/modules/auth/keys';
import { transformArrayToObject } from '@/utils/transformations/transformArrayToObject';
import { REFRESH_TOKEN_RESOURCE } from '@/services/keys';
import { APP_CONFIG, RESET_APP_CONFIG } from '@/store/modules/appConfig/keys';

const refreshTokenUrl = `/v1/${REFRESH_TOKEN_RESOURCE}`;
const api = createApi(process.env.VUE_APP_CORE_BASE_URL);

export default api;

export function createApi(baseURL) {
  const api = axios.create({
    baseURL,
    withCredentials: false,
    crossDomain: true,
  });

  api.interceptors.request.use(
    config => {
      if (store.getters[IS_AUTHENTICATED] || store.getters[TOKEN_TO_RESET_PASS] !== null) {
        config.headers['x-api-token'] = store.getters[IS_AUTHENTICATED]
          ? store.getters[TOKEN]
          : store.getters[TOKEN_TO_RESET_PASS];
      }
      return config;
    },
    error => {
      logger.error('Error adding token to request. ', {
        IS_AUTHENTICATED: store.getters[IS_AUTHENTICATED],
        TOKEN_TO_RESET_PASS: store.getters[TOKEN_TO_RESET_PASS],
      });
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    async response => {
      if (response.data && response.data.included && response.data.included.length > 0) {
        response.data.objectInclude = transformArrayToObject(response.data.included);
      }
      const appConfigCurrent = store.getters[APP_CONFIG];
      if (response.headers['x-app-config']) {
        const appConfig = JSON.parse(response.headers['x-app-config']);
        await store.commit(APP_CONFIG, appConfig);
      } else if (appConfigCurrent) {
        await store.dispatch(RESET_APP_CONFIG);
      }

      return response;
    },
    async error => {
      const { response } = error;
      let logErrorMsg = null;
      if (axios.isCancel(error)) {
        logErrorMsg = 'Error - canceled by user';
        throw { message: logErrorMsg, code: HTTPStatusCode.Cancel };
      } else if (response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const isUnauthorizedRefreshTokenUrl =
          HTTPStatusCode.Unauthorized === response.status && response.config.url === refreshTokenUrl;
        const isForbiddenRefreshTokenUrl =
          HTTPStatusCode.Forbidden === response.status && response.config.url === refreshTokenUrl;
        const isUnauthorizedOtherUrl =
          HTTPStatusCode.Unauthorized === response.status && response.config.url !== refreshTokenUrl;

        if (isUnauthorizedRefreshTokenUrl || isForbiddenRefreshTokenUrl) {
          await store.dispatch(AUTH_LOGOUT);
          router.push({ name: 'login' });
          logErrorMsg = 'Could not refresh token';
        } else if (isUnauthorizedOtherUrl) {
          store.dispatch(CREATE_TOAST, Toast.info('Loading...', 'Access credentials are being renewed'));
          await store.dispatch(AUTH_REFRESH);
          router.go(0);
          logErrorMsg = 'Unauthorized request for %url%';
        } else {
          logErrorMsg = 'Error requesting %url%';
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        logErrorMsg = 'No response received from %url%';
        throw Error(`Request - ${error.request}`);
      } else {
        // Something happened in setting up the request that triggered an Error
        logErrorMsg = 'Unknown error requesting %url%';
        throw Error(`Error - ${error.message}`);
      }

      const composedErrorMsg = logErrorMsg.replace('%url%', response.config.url);
      logger.error(composedErrorMsg, { code: response.status, message: response.message });

      return Promise.reject({ message: response?.data?.error || error?.message, status: response?.status });
    }
  );

  return api;
}
