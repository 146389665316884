/**
 * @module Language
 */
export default class Language {
  /**
   * Create a Language
   * @param {string|null} id
   * @param {string|null} name
   */
  constructor(id, name = '') {
    this.id = id;
    this.name = name;
  }

  static create(entity) {
    const attributes = entity.attributes;
    return new Language(entity.id, attributes.name);
  }
}
