import { VIDEO_PLAZA } from '@/model/shared/Platforms';

/**
 * @module ContentTargeting
 */
export default class ContentTargeting {
  /**
   * Create a ContentTargeting
   * @param {string|null} name
   * @param {string|null} description
   * @param {string|null} parentId
   * @param {object|null} platform
   */
  constructor(name = null, description = null, parentId = null, platform = null) {
    this.id = platform[VIDEO_PLAZA.id];
    this.name = name;
    this.description = description;
    this.parentId = parentId;
    this.platform = platform;
  }

  static create(entity) {
    const { name, description, parentId, platform } = entity.attributes;
    return new ContentTargeting(name, description, parentId, platform);
  }

  getDisplayName() {
    return this.name;
  }
}
