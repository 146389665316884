import { initialState } from '@/store/modules/contextBoard/initialState';
import { SAVE_PARAMS_KEY, PARAM_LIST_KEY, RESET_CONTEXTBOARD_KEY } from '@/store/modules/contextBoard/keys';

const mutations = {
  [SAVE_PARAMS_KEY]: (state, filters) => {
    state[PARAM_LIST_KEY] = filters;
  },

  [RESET_CONTEXTBOARD_KEY]: state => {
    state[PARAM_LIST_KEY] = initialState()[PARAM_LIST_KEY];
  },
};

export default mutations;
