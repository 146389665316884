import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;

export const markets = {
  path: 'markets',
  name: `${basePath}-markets`,
  component: () =>
    import(
      /* webpackChunkName: "ds-analytics-markets" */ '@/views/private/modules/dashboardSSP/analytics/markets/Markets'
    ),
  meta: {
    requiresAuth: true,
    title: 'Analytics Markets',
    contextSecure: CONTEXTS.DASHBOARD_SSP.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.SELLER.id, ROLES.CLIENT.id],
    breadcrumbs: new BreadcrumbItem('Markets', { name: `${basePath}-markets` }),
  },
  props: true,
};
