import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import basicInfo from '@/router/private/modules/ecommerce/demand/offer/duplicateForm/basicInfo';
import fraud from '@/router/private/modules/ecommerce/demand/offer/duplicateForm/fraud';
import postEvent from '@/router/private/modules/ecommerce/demand/offer/duplicateForm/postEvent';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const duplicate = {
  path: ':offerId/duplicate',
  name: `${basePath}-duplicate-offer`,
  redirect: { name: basicInfo.name },
  component: () =>
    import(/* webpackChunkName: "ec-offer-duplicate" */ '@/views/private/modules/ecommerce/offer/OfferDuplicate'),
  meta: {
    requiresAuth: true,
    title: 'Duplicate Offer',
    breadcrumbs: new BreadcrumbItem('Duplicate Offer', { name: `${basePath}-duplicate-offer` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.PUBLISHER.id],
  },
  children: [basicInfo, fraud, postEvent],
  props: true,
};
