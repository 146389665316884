import { stringToDate } from '@/filters/dateFilters';
import SelectOption from '@/model/shared/SelectOption';
import MoneyAmount from '@/model/shared/MoneyAmount';
import {
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_DEFAULT,
} from '@/model/modules/socialAudience/wallet/TransactionStatus';

export default class Transaction {
  /**
   * @param {string|null} id
   * @param {string} type
   * @param {MoneyAmount} money
   * @param {MoneyAmount} balance
   * @param {Date} createdAt
   * @param {string} status
   */

  constructor(
    id = null,
    type = new SelectOption('INCOME'),
    money = new MoneyAmount(0),
    balance = new MoneyAmount(0),
    createdAt = new Date(),
    status = null
  ) {
    this.id = id;
    this.type = type;
    this.money = money;
    this.balance = balance;
    this.createdAt = createdAt;
    this.status = status || TRANSACTION_STATUS_DEFAULT;
  }

  payload() {
    const { money } = this;

    return {
      amount: money.value,
    };
  }

  /**
   *
   * @param {{id:string, attributes: Transaction}} entity
   * @param object included
   * @return {Transaction}
   */
  static create(entity) {
    const { createdAt, status } = entity.attributes;
    const transactionStatus = TRANSACTION_STATUS.find(transactionStatus => transactionStatus.id === status);

    return new Transaction(
      entity.id,
      new SelectOption(entity.attributes.type),
      MoneyAmount.build(entity.attributes.money),
      new MoneyAmount(entity.attributes.money.currency, entity.attributes.balance),
      createdAt ? stringToDate(createdAt) : null,
      transactionStatus
    );
  }
}
