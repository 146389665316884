import * as RESOURCE from '@/services/keys';
import { DashboardSSPClient as Client } from './DashboardSSPClient';
import { EntityParser } from '@/entities/shared/EntityParser';
import AnalyticsEconomicGraph from './AnalyticsEconomicGraph';
import Currency from '@/entities/shared/Currency';
import Deal from './Deal';
import Analytic from './Analytic';
import DealComment from './DealComment';
import DealType from './DealType';
import IngestionJobNotifications from '@/entities/shared/IngestionJobNotifications.js';
import Platform from './Platform';
import PlatformDeals from './PlatformDeals';
import PlatformFee from './PlatformFee';
import Provider from './Provider';
import RegionOffice from './RegionOffice';
import Salesforce from './Salesforce';
import Seller from './Seller';
import UserCore from '@/entities/Auth/UserCore';
import EmailIngestion from './EmailIngestion';

const dashboardSSPEntityParser = new EntityParser({
  [RESOURCE.AUTHOR_RESOURCE]: UserCore.create,
  [RESOURCE.CLIENT_RESOURCE]: Client.create,
  [RESOURCE.COMMENTS_RESOURCE]: DealComment.create,
  [RESOURCE.CURRENCY_RESOURCE]: Currency.create,
  [RESOURCE.DEALS_RESOURCE]: Deal.create,
  [RESOURCE.DEALS_TYPES_RESOURCE]: DealType.create,
  [RESOURCE.ANALYTICS_RESOURCE]: Analytic.create,
  [RESOURCE.ECONOMICS_GRAPH_ANALYTICS_RESOURCE_RESOURCE]: AnalyticsEconomicGraph.create,
  [RESOURCE.DEAL_ANALYTICS_KPIS_RESOURCE]: Analytic.create, // TODO: delete (use "deal-analytics")
  [RESOURCE.DEAL_BREAKDOWN_ANALYTICS_RESOURCE]: Analytic.create,
  [RESOURCE.DAY_BREAKDOWN_ANALYTICS_RESOURCE]: Analytic.create,
  [RESOURCE.DASHBOARD_GRAPH_ANALYTICS_RESOURCE_RESOURCE]: AnalyticsEconomicGraph.create, // TODO: delete infavor of "economics-graph-analytics"
  //
  [RESOURCE.INGESTION_JOB_NOTIFICATIONS_RESOURCE]: IngestionJobNotifications.create,
  [RESOURCE.LINKED_USER_RESOURCE]: UserCore.create,
  [RESOURCE.PLATFORM_DEALS_RESOURCE]: PlatformDeals.create,
  [RESOURCE.PLATFORM_FEE_RESOURCE]: PlatformFee.create,
  [RESOURCE.PLATFORM_RESOURCE]: Platform.create,
  [RESOURCE.EMAILS_RESOURCES]: EmailIngestion.create,
  [RESOURCE.PROVIDERS_RESOURCE]: Provider.create,
  [RESOURCE.REGION_OFFICE_RESOURCE]: RegionOffice.create,
  [RESOURCE.SALESFORCE_ACCOUNT_RESOURCE]: UserCore.create,
  [RESOURCE.SALESFORCE_USERS_RESOURCE]: Salesforce.create,
  [RESOURCE.SELLER_RESOURCE]: Seller.create,
  [RESOURCE.USER_RESOURCE]: UserCore.create,
  salesforceAccounts: UserCore.create, // TODO this  is core
  salesforceUsers: Salesforce.create, // TODO: this is core
});
export default dashboardSSPEntityParser;
