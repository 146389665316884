import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const precio = {
  path: 'precio',
  name: `${basePath}-precio`,
  component: () =>
    import(/* webpackChunkName: "tp-analytics-precio" */ '@/views/private/modules/thirdParty/analytics/Precio.vue'),
  meta: {
    breadcrumbs: new BreadcrumbItem('Precio', { name: `${basePath}-precio` }, {}),
    requiresAuth: true,
    title: 'Precio',
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.SELLER.id],
  },
  props: true,
};

export default precio;
