const CreativeFeedAllowedHeaders = {
  ID: { id: 'id', name: 'ID', value: 'id' },
  TITLE: { id: 'title', name: 'Title', value: 'title' },
  PRICE: { id: 'price', name: 'Price', value: 'price' },
  LINK: { id: 'link', name: 'Link', value: 'link' },
  IMAGE_LINK: { id: 'image_link', name: 'Image link', value: 'image_link' },
  DESCRIPTION: { id: 'description', name: 'Description', value: 'description' },
  CATEGORY: { id: 'category', name: 'Category', value: 'category' },
  SUBCATEGORY: { id: 'subcategory', name: 'Subcategory', value: 'subcategory' },
};

export default CreativeFeedAllowedHeaders;
