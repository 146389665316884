import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { list } from '@/router/private/modules/thirdParty/admin/users/list';
import { create } from '@/router/private/modules/thirdParty/admin/users/create';
import { edit } from '@/router/private/modules/thirdParty/admin/users/edit';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const users = {
  name: `${basePath}-user`,
  path: 'users',
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    requiresAuth: true,
    title: 'Users ',
    breadcrumbs: new BreadcrumbItem('Users', { name: `${basePath}-users` }, {}),
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id, ROLES.CLIENT.id],
  },
  props: true,
  children: [list, create, edit],
};
