<template>
  <div class="fixed top-0 right-0 w-full p-4 w-26 z-101" :class="{ hidden: toastList.length === 0 }">
    <transition-group name="move" tag="div" class="flex flex-col">
      <template v-for="(toast, index) in toastList">
        <div
          :key="`toast_${index}`"
          class="relative bg-white rounded-lg px-6 py-4 flex shadow-xl justify-start items-center z-100 border border-gray-200"
          :class="{ 'mt-4': index !== 0 }"
        >
          <slot name="icon">
            <div
              :class="`p-2 bg-${color(toast.type)}-200 opacity-75
              mr-4 flex items-center justify-center rounded-full -ml-2`"
            >
              <component
                :is="getIcon(toast.type)"
                :class="`h-4 text-${color(toast.type)}-900 ${classes(toast.type)}`"
              />
            </div>
          </slot>
          <slot name="text">
            <div class="flex flex-col max-w-full text-sm">
              <span :class="`text-${color(toast.type)}-700 font-black text-left`">
                {{ toast.title }}
              </span>
              <span class="text-gray-600 text-left">
                {{ toast.message }}
              </span>
            </div>
          </slot>
          <slot name="close">
            <div
              class="absolute font-black close-right cursor-pointer text-gray-600 p-2 z-20"
              @click.prevent="deleteByIndex(index)"
            >
              <close-svg class="h-3 text-gray-700" />
            </div>
          </slot>
        </div>
      </template>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TOAST_LIST, DELETE_TOAST } from '@/store/modules/toast/keys';
import { TOAST_TYPES } from '@/model/shared/Toast';
import ErrorSvg from '../../icons/ErrorSvg';
import CheckSvg from '../../icons/CheckSvg';
import InfoSvg from '../../icons/InfoSvg';
import WarningSvg from '../../icons/WarningSvg';
import CloseSvg from '../../icons/CloseSvg';

export default {
  name: 'AsterixToasts',
  components: {
    CloseSvg,
  },
  computed: {
    ...mapGetters({
      toastList: TOAST_LIST,
    }),
  },
  methods: {
    ...mapActions([DELETE_TOAST]),
    deleteByIndex(index) {
      this[DELETE_TOAST]({ index });
    },
    color(type) {
      switch (type) {
        case TOAST_TYPES.ERROR:
          return 'red';
        case TOAST_TYPES.WARNING:
          return 'orange';
        case TOAST_TYPES.SUCCESS:
          return 'green';
        default:
          return 'blue';
      }
    },
    getIcon(type) {
      switch (type) {
        case TOAST_TYPES.ERROR:
          return ErrorSvg;
        case TOAST_TYPES.WARNING:
          return WarningSvg;
        case TOAST_TYPES.SUCCESS:
          return CheckSvg;
        default:
          return InfoSvg;
      }
    },
    classes(type) {
      switch (type) {
        case TOAST_TYPES.ERROR:
          return 'h-5';
        case TOAST_TYPES.WARNING:
          return 'h-5';
        case TOAST_TYPES.INFO:
          return 'w-4';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.max-w-23 {
  max-width: 23rem;
}
.w-26 {
  width: 26rem;
}
@media (max-width: 768px) {
  .w-26 {
    width: 100%;
  }
}
.close-right {
  right: 1rem;
  top: 0.25rem;
}
.move-enter-active {
  transition: all 0.3s ease;
}
.move-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.move-enter,
.move-leave-to {
  transform: translateX(200px);
  opacity: 0;
}
.z-101 {
  z-index: 101;
}
</style>
