import { CONTEXTS } from '../../model/shared/contexts';
import { ClientShared } from '../shared/ClientShared';
import { Address } from '../shared/Address';
import Finance from './Finance';
import User from '../shared/User';
import WalletClient from './WalletClient';
import { LINKED_USER_RESOURCE } from '@/services/keys';
import { WALLET_TYPES } from '@/model/modules/socialAudience/wallet/WalletType';

export class SocialAudienceClient extends ClientShared {
  constructor(
    id = '',
    name = '',
    email = '',
    contactName = '',
    isExternal = false,
    users = [],
    taxId = '',
    address = new Address(),
    finance = new Finance(),
    salesforceAccount = null,
    salesforceUser = null,
    wallet = new WalletClient()
  ) {
    super(id, name, email, CONTEXTS.SOCIAL_AUDIENCE.id, contactName, isExternal, users, taxId);
    this.address = address;
    this.finance = finance;
    this.salesforceAccount = salesforceAccount;
    this.salesforceUser = salesforceUser;
    this.wallet = wallet;
  }

  payload() {
    const { users, salesforceAccount, salesforceUser, finance, ...params } = this;

    return {
      ...params,
      userIds: users.map(user => user.id),
      userSalesforceId: salesforceUser?.id,
      accountSalesforceId: salesforceAccount?.id,
      accountName: salesforceAccount?.name,
      finance: finance.payload(),
    };
  }

  /**
   *
   * @param {{id:string, attributes: SocialAudienceClient}} entity
   * @param {Object} included
   * @return {SocialAudienceClient}
   */
  static create(entity, included) {
    const linkedUsers = entity.relationships?.linkedUsers.data;
    let users = [];
    if (linkedUsers) {
      users = included?.[LINKED_USER_RESOURCE]?.filter(user => linkedUsers.find(linkUser => linkUser.id === user.id));
    }
    const { finance } = entity.attributes;
    const walletType = WALLET_TYPES.find(type => type.id === finance?.walletType) || null;

    return new SocialAudienceClient(
      entity.id,
      entity.attributes.name,
      entity.attributes.email,
      entity.attributes.contactName,
      entity.attributes.isExternal,
      users,
      entity.attributes.taxId,
      entity.attributes.address,
      new Finance(walletType, finance?.fee, finance?.techCost),
      new User(entity.attributes.accountSalesforceId),
      new User(entity.attributes.userSalesforceId),
      new WalletClient(entity.attributes.wallet?.balance, entity.attributes.wallet?.automaticRecharge, walletType)
    );
  }

  createFinance(walletType, fee, techCost) {
    this.finance = new Finance(walletType, fee, techCost);
  }
}
