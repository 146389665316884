import { ROLES } from '@/model/shared/roles';

export const THIRD_PARTY = {
  [ROLES.ADMIN.id]: {
    CREATE: ['USER', 'CLIENT', 'PLATFORM', 'COMMENT'],
    DELETE: ['USER', 'CLIENT', 'PLATFORM'],
    SHOW_LIST: ['USER', 'CLIENT', 'ADVERTISER', 'PRODUCT', 'PLATFORM'],
    SHOW: ['USER', 'CLIENT', 'PRODUCT'],
    EDIT: ['USER', 'CLIENT', 'PLATFORM'],
  },
  [ROLES.AD_OPS.id]: {
    CREATE: ['PLATFORM', 'CLIENT', 'COMMENT'],
    DELETE: [],
    SHOW_LIST: ['ADVERTISER', 'PRODUCT', 'PLATFORM', 'CLIENT'],
    SHOW: ['ADVERTISER', 'PRODUCT', 'CLIENT'],
    EDIT: ['PLATFORM', 'CLIENT'],
  },
  [ROLES.CLIENT.id]: {
    CREATE: ['USER', 'PLATFORM', 'COMMENT'],
    DELETE: ['USER'],
    SHOW_LIST: ['USER', 'CLIENT', 'ADVERTISER', 'PRODUCT', 'PLATFORM'],
    SHOW: ['USER', 'CLIENT', 'ADVERTISER', 'PRODUCT'],
    EDIT: ['USER', 'CLIENT', 'PLATFORM'],
  },
  [ROLES.ADVERTISER.id]: {
    CREATE: [],
    DELETE: [],
    SHOW_LIST: ['ADVERTISER', 'PRODUCT'],
    SHOW: ['ADVERTISER', 'PRODUCT'],
    EDIT: [],
  },
  [ROLES.FINANCE.id]: {
    CREATE: [],
    DELETE: [],
    SHOW_LIST: [],
    SHOW: ['USER'],
    EDIT: ['USER'],
  },
  [ROLES.SELLER.id]: {
    CREATE: [],
    DELETE: [],
    SHOW_LIST: ['CLIENT'],
    SHOW: ['CLIENT'],
    EDIT: [],
  },
};
