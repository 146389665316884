import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;

export const campaignManager = {
  path: 'campaign-manager',
  name: `${basePath}-campaign-manager`,
  component: () =>
    import(
      /* webpackChunkName: "tp-analytics" */ '@/views/private/modules/dashboardSSP/campaignManager/PGRCampaignManager.vue'
    ),
  meta: {
    breadcrumbs: new BreadcrumbItem('Campaign Manager', { name: `${basePath}-campaign-manager` }, {}),
    requiresAuth: true,
    title: 'Campaign Manager',
  },
  props: true,
};

export default campaignManager;
