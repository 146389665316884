import { login } from './login';
import { refreshToken } from './refreshToken';
import { resetPasswordAndSendEmail } from './resetPasswordAndSendEmail';
import { resetPassword } from './resetPassword';
import { loggedInResetPassword } from './loggedInResetPassword';
import { CoreApi } from '../../api/CoreApi';

export const api = new CoreApi();

export { login, refreshToken, resetPasswordAndSendEmail, resetPassword, loggedInResetPassword };
