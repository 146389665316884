/**
 * FeedStructure
 * @class
 * @public
 */

import CreativeFeedAllowedHeaders from '@/model/shared/CreativeFeedAllowedHeaders';

const REQUIRED_HEADERS = [CreativeFeedAllowedHeaders.LINK];

/**
 * FeedStructure data
 * @constructor
 * @typedef {object} FeedStructure
 * @property @param {Object<String,String>>} dictionary
 */
export default class FeedStructure {
  constructor(dictionary = {}) {
    this.dictionary = dictionary;
  }

  isDictionaryValid(requiredHeaders = REQUIRED_HEADERS) {
    const values = Object.values(this.dictionary?.feed || {}).filter(v => v);
    const requiredHeadersId = requiredHeaders.map(item => item.id);

    return values.findIndex(item => requiredHeadersId.includes(item.id)) >= 0;
  }
}
