import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import basicInfo from '@/router/private/modules/ecommerce/supply/publisher/create/basicInfo';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const create = {
  path: 'new',
  name: `${basePath}-new-publisher`,
  redirect: { name: basicInfo.name },
  component: () =>
    import(/* webpackChunkName: "ec-publishers" */ '@/views/private/modules/ecommerce/publisher/PublisherEdit'),
  meta: {
    requiresAuth: true,
    title: 'New Publisher ',
    breadcrumbs: new BreadcrumbItem('New Publisher', { name: `${basePath}-new-publisher` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  children: [basicInfo],
  props: true,
};
