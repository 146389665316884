import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;
const contextSecure = CONTEXTS.SOCIAL_AUDIENCE.id;

export const list = {
  path: 'list',
  name: `${basePath}-users`,
  component: () =>
    import(/* webpackChunkName: "sa-user" */ '@/views/private/modules/socialAudience/users/SocialAudienceUsersList'),
  meta: {
    requiresAuth: true,
    title: 'Users ',
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  props: true,
};
