import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const feedParserProvider = {
  path: 'feedParserProvider',
  name: `${basePath}-feed-parser-provider`,
  component: () =>
    import(
      /* webpackChunkName: "ec-feedParserProvider" */ '@/views/private/modules/ecommerce/feedParserProvider/FeedParserProviderList'
    ),
  meta: {
    requiresAuth: true,
    title: 'Feed Parser Provider ',
    breadcrumbs: new BreadcrumbItem('Feed parser provider', { name: `${basePath}-feed-parser-provider` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id],
  },
};
