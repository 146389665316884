import { api } from '.';
import { CATEGORY_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import coreEntityParser from '@/entities/Core/CoreEntityParser';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = categoryId => ({
  [CATEGORY_RESOURCE]: categoryId,
});

/**
 *
 * @param {string} categoryId
 * @returns {Promise.<{data:Category,meta: import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export async function getCategoryById(categoryId) {
  const params = new QueryParamsBuilder();
  const partialUrl = api.createUrl(getResources(categoryId));

  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(coreEntityParser.parse(data), data.meta).build();
}
