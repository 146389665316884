import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { list } from '@/router/private/modules/ecommerce/demand/postEventTemplates/list';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const postEvents = {
  name: `${basePath}-postEvent`,
  path: 'post-events',
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    requiresAuth: true,
    title: 'Users ',
    breadcrumbs: new BreadcrumbItem('Post events', { name: `${basePath}-postEvent` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  props: true,
  children: [list],
};
