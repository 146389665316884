import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';

export const FILTERS_NS = 'filters';

// actions
export const SAVE_BREAKDOWN_KEY = 'saveBreakDown';
export const SAVE_COLUMN_CONFIG_KEY = 'saveColumnConfig';
export const SAVE_FILTERS_KEY = 'saveFilter';
export const SAVE_ITEMS_PER_PAGE_KEY = 'saveItemsPerPage';
export const SAVE_QUICK_FILTERS_KEY = 'saveQuickFilter';
export const SAVE_SORTERS_KEY = 'saveSorter';

// mutation || getters
export const BREAKDOWN_KEY = 'breakdown';
export const COLUMN_CONFIG_KEY = 'columnConfig';
export const FILTER_LIST_KEY = 'filterList';
export const ITEMS_PER_PAGE_KEY = 'itemsPerPage';
export const QUICK_FILTER_LIST_KEY = 'quickFilterList';
export const SORTER_LIST_KEY = 'sorterList';

// views
export const SAVE_BREAKDOWN = `${FILTERS_NS}/${SAVE_BREAKDOWN_KEY}`;
export const SAVE_COLUMN_CONFIG = `${FILTERS_NS}/${SAVE_COLUMN_CONFIG_KEY}`;
export const SAVE_FILTERS = `${FILTERS_NS}/${SAVE_FILTERS_KEY}`;
export const SAVE_ITEMS_PER_PAGE = `${FILTERS_NS}/${SAVE_ITEMS_PER_PAGE_KEY}`;
export const SAVE_QUICK_FILTERS = `${FILTERS_NS}/${SAVE_QUICK_FILTERS_KEY}`;
export const SAVE_SORTERS = `${FILTERS_NS}/${SAVE_SORTERS_KEY}`;
export const SORTER_LIST = `${FILTERS_NS}/${SORTER_LIST_KEY}`;

export const BREAKDOWN = `${FILTERS_NS}/${BREAKDOWN_KEY}`;
export const COLUMN_CONFIG = `${FILTERS_NS}/${COLUMN_CONFIG_KEY}`;
export const FILTER_LIST = `${FILTERS_NS}/${FILTER_LIST_KEY}`;
export const ITEMS_PER_PAGE = `${FILTERS_NS}/${ITEMS_PER_PAGE_KEY}`;
export const QUICK_FILTERS = `${FILTERS_NS}/${QUICK_FILTER_LIST_KEY}`;
export const RESET_STORE_FILTER = `${FILTERS_NS}/${RESET_STORE_MODULE_MUTATION}`;
