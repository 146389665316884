import { Cookies } from '@/model/shared/Cookies';
import router from '@/router/index';
import { getProfile } from '@/services/modules/Core/profile';
import { login, refreshToken } from '@/services/modules/Auth';
import { setToken } from '@/utils/user/setToken';
import { RESET_STORE_ACTION } from '@/store/utils/createResetStore';
import {
  AUTH_ERROR_KEY,
  AUTH_LOGOUT_KEY,
  AUTH_LOGIN_KEY,
  AUTH_SUCCESS_KEY,
  AUTH_REFRESH_KEY,
  USER_KEY,
  LAST_URL_KEY,
  UPDATE_USER_KEY,
  TOKEN_TO_RESET_PASS_KEY,
} from './keys';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';

const cookies = new Cookies();

const actions = {
  [AUTH_LOGIN_KEY]: async ({ commit }, credentials) => {
    try {
      // Delete any item on localStorage, like column config
      localStorage.clear();

      const data = await login({
        user: credentials.username,
        password: credentials.password,
      });
      const token = data.access_token;
      const refreshTokenVal = data.refresh_token;
      setToken(token);
      cookies.setCookie(
        process.env.VUE_APP_REFRESH_TOKEN_NAME,
        refreshTokenVal,
        +process.env.VUE_APP_REFRESH_TOKEN_TTL,
        true
      );
      commit(AUTH_SUCCESS_KEY, token, refreshTokenVal);
    } catch (error) {
      commit(AUTH_ERROR_KEY, error);
      setToken(null);
      cookies.deleteCookie(process.env.VUE_APP_REFRESH_TOKEN_NAME, true);
      throw new Error(error);
    }
  },
  // Force to update
  [USER_KEY]: async ({ commit, getters }, { force = false }) => {
    if (force === true || !getters[USER_KEY]) {
      try {
        const {
          data: { data },
        } = await getProfile();
        const { id, attributes } = data;
        const userObject = { id, ...attributes };
        cookies.setCookie(
          process.env.VUE_APP_USER_COOKIE_NAME,
          JSON.stringify(userObject),
          +process.env.VUE_APP_ACCESS_TOKEN_TTL,
          true
        );
        commit(USER_KEY, userObject);
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) throw error;
      }
    }
  },
  [UPDATE_USER_KEY]: async ({ commit, getters, dispatch }, userUpdated) => {
    await dispatch(USER_KEY, { force: true });
    // backend take time to update user
    const user = {
      ...getters[USER_KEY],
      ...userUpdated,
    };
    commit(USER_KEY, user);
  },
  [LAST_URL_KEY]: ({ commit }, nameUrl = null) => {
    commit(LAST_URL_KEY, nameUrl);
  },
  [AUTH_LOGOUT_KEY]: async ({ dispatch }) => {
    setToken(null);
    cookies.deleteCookie(process.env.VUE_APP_REFRESH_TOKEN_NAME, true);
    cookies.deleteCookie(process.env.VUE_APP_USER_COOKIE_NAME, true);

    // Delete any item on localStorage, like column config
    localStorage.clear();

    dispatch(RESET_STORE_ACTION, {}, { root: true });

    await router.push({ name: 'login' });
  },
  [AUTH_REFRESH_KEY]: async ({ commit, dispatch }) => {
    const actualRefreshToken = cookies.getCookie(process.env.VUE_APP_REFRESH_TOKEN_NAME);
    if (!actualRefreshToken) throw new Error('NOT VALID REFRESH TOKEN');
    try {
      const { access_token, refresh_token } = await refreshToken(actualRefreshToken);
      setToken(access_token);
      cookies.setCookie(
        process.env.VUE_APP_REFRESH_TOKEN_NAME,
        refresh_token,
        +process.env.VUE_APP_REFRESH_TOKEN_TTL,
        true
      );
      await commit(AUTH_REFRESH_KEY, { token: access_token, refreshToken: refresh_token });
      // Se resetea el profile
      await dispatch(USER_KEY, { force: true });
    } catch (error) {
      setToken(null);
      cookies.deleteCookie(process.env.VUE_APP_REFRESH_TOKEN_NAME, true);
      cookies.deleteCookie(process.env.VUE_APP_USER_COOKIE_NAME, true);
      commit(AUTH_ERROR_KEY, error);
      throw new Error('NOT VALID REFRESH TOKEN');
    }
  },
  [TOKEN_TO_RESET_PASS_KEY]: ({ commit }, token) => {
    commit(TOKEN_TO_RESET_PASS_KEY, token);
  },
};
export default actions;
