var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fixed top-0 right-0 w-full p-4 w-26 z-101",
      class: { hidden: _vm.toastList.length === 0 },
    },
    [
      _c(
        "transition-group",
        { staticClass: "flex flex-col", attrs: { name: "move", tag: "div" } },
        [
          _vm._l(_vm.toastList, function (toast, index) {
            return [
              _c(
                "div",
                {
                  key: `toast_${index}`,
                  staticClass:
                    "relative bg-white rounded-lg px-6 py-4 flex shadow-xl justify-start items-center z-100 border border-gray-200",
                  class: { "mt-4": index !== 0 },
                },
                [
                  _vm._t("icon", function () {
                    return [
                      _c(
                        "div",
                        {
                          class: `p-2 bg-${_vm.color(toast.type)}-200 opacity-75
            mr-4 flex items-center justify-center rounded-full -ml-2`,
                        },
                        [
                          _c(_vm.getIcon(toast.type), {
                            tag: "component",
                            class: `h-4 text-${_vm.color(
                              toast.type
                            )}-900 ${_vm.classes(toast.type)}`,
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                  _vm._t("text", function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "flex flex-col max-w-full text-sm" },
                        [
                          _c(
                            "span",
                            {
                              class: `text-${_vm.color(
                                toast.type
                              )}-700 font-black text-left`,
                            },
                            [_vm._v(" " + _vm._s(toast.title) + " ")]
                          ),
                          _c(
                            "span",
                            { staticClass: "text-gray-600 text-left" },
                            [_vm._v(" " + _vm._s(toast.message) + " ")]
                          ),
                        ]
                      ),
                    ]
                  }),
                  _vm._t("close", function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "absolute font-black close-right cursor-pointer text-gray-600 p-2 z-20",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.deleteByIndex(index)
                            },
                          },
                        },
                        [_c("close-svg", { staticClass: "h-3 text-gray-700" })],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }