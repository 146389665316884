/**
 * @module OperatingSystem
 */
export default class OperatingSystem {
  /**
   * Create a OperatingSystem
   * @param {string|null} id
   * @param {string|null} family
   * @param {string|null} version
   * @param {object|null} platform
   */
  constructor(id, family = null, version = null, platform = null) {
    this.id = id;
    this.family = family;
    this.version = version;
    this.platform = platform;
    this.name = this.getDisplayName();
  }

  static create(entity) {
    const { family, version, platform } = entity.attributes;
    return new OperatingSystem(entity.id, family, version, platform);
  }

  getDisplayName() {
    return `${this.family} (${this.version})`;
  }
}
