export default class Provider {
  constructor(id = null, name = '') {
    this.id = id;
    this.name = name;
  }

  static create(entity) {
    return new Provider(entity.id, entity.attributes.name);
  }
}
