export const OS_NS = 'operatingSystem';

export const GET_OS_SMART_REQUEST_KEY = 'get_operating_systems_smart_request';
export const GET_OS_VIDEO_PLAZA_REQUEST_KEY = 'get_operating_systems_video_plaza_request';
export const OS_SMART_KEY = 'operating_ystems_smart';
export const OS_VIDEO_PLAZA_KEY = 'operating_systems_video_plaza';

export const GET_OS_SMART_REQUEST = `${OS_NS}/${GET_OS_SMART_REQUEST_KEY}`;
export const GET_OS_VIDEO_PLAZA_REQUEST = `${OS_NS}/${GET_OS_VIDEO_PLAZA_REQUEST_KEY}`;
export const OS_SMART = `${OS_NS}/${OS_SMART_KEY}`;
export const OS_VIDEO_PLAZA = `${OS_NS}/${OS_VIDEO_PLAZA_KEY}`;
