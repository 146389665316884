import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { getBrowsersSmart, getBrowsersVideoPlaza } from '@/services/modules/Core/userAgent';

import {
  BROWSERS_SMART_KEY,
  BROWSERS_VIDEO_PLAZA_KEY,
  GET_BROWSERS_SMART_REQUEST_KEY,
  GET_BROWSERS_VIDEO_PLAZA_REQUEST_KEY,
} from './keys';

const actions = {
  [GET_BROWSERS_SMART_REQUEST_KEY]: async ({ state, commit }) => {
    try {
      if (state[BROWSERS_SMART_KEY].length) return state[BROWSERS_SMART_KEY];
      const { data } = await getBrowsersSmart();
      commit(BROWSERS_SMART_KEY, data);
    } catch (error) {
      if (error.code !== HTTPStatusCode.Cancel) throw error;
    }
  },
  [GET_BROWSERS_VIDEO_PLAZA_REQUEST_KEY]: async ({ state, commit }) => {
    try {
      if (state[BROWSERS_VIDEO_PLAZA_KEY].length) return state[BROWSERS_VIDEO_PLAZA_KEY];
      const { data } = await getBrowsersVideoPlaza();
      commit(BROWSERS_VIDEO_PLAZA_KEY, data);
    } catch (error) {
      if (error.code !== HTTPStatusCode.Cancel) throw error;
    }
  },
};

export default actions;
