import { UPDATE_USER_SETTINGS_INDEX, UPDATE_USER_SETTINGS_INDEX_SECOND } from '@/store/modules/settings/keys';
import store from '@/store';

export default class Settings {
  /**
   * Buil the object with settings
   * @param settings
   */
  constructor(settings = {}) {
    this.config = settings;
  }

  /**
   * Validate if it has a context
   * @param index
   * @returns {boolean}
   */
  hasContext(index) {
    return index in this.config;
  }

  /**
   * Get the context
   * @param index
   * @returns Object
   */
  getContext(index) {
    return this.config[index];
  }

  /**
   * Add new context with Settings
   * @param index
   * @param value
   * @returns Object
   */
  async setContext(index, value) {
    // this.config[index] = value;
    await store.commit(UPDATE_USER_SETTINGS_INDEX, { index, value });

    return this.config[index];
  }

  /**
   * Push a new value into an index
   * @param index
   * @param settings
   */
  pushConfigByContext(index, settings) {
    const indexSelected = this.getContext(index);
    if (this.hasContext(index) && indexSelected instanceof Object) {
      indexSelected[settings.id] = settings;
    } else {
      const newValue = {};
      newValue[settings.id] = settings;

      this.setContext(index, newValue);
    }
  }

  /**
   * Validate if the index has value
   * @param {String} index
   * @param {String} indexSecond
   * @returns {*|boolean}
   */
  hasTableByContext(index, indexSecond) {
    if (this.hasContext(index)) {
      const config = this.getContext(index);
      return indexSecond in config;
    }

    return false;
  }

  /**
   * Get table config by context
   * @param {String} index
   * @param {String} indexSecond
   * @returns {*}
   */
  getTableByContext(index, indexSecond) {
    const config = this.getContext(index);
    return config[indexSecond];
  }

  /**
   * The columns should be an array the objects Columns
   * @param {String} index
   * @param {String} indexSecond
   * @param {Array} columns
   */
  async setTableByContext(index, indexSecond, columns) {
    const config = columns.map(col => col.field);
    if (this.hasContext(index)) {
      await store.commit(UPDATE_USER_SETTINGS_INDEX_SECOND, { index, indexSecond, value: config });
    } else {
      const newValue = {};
      newValue[indexSecond] = config;
      await this.setContext(index, newValue);
    }
  }

  toSave() {
    return this.config;
  }

  clone() {
    return new Settings(this.config);
  }

  /**
   * Build an object Settings from API response
   * @param settings
   * @returns {Settings}
   */
  static build(settings) {
    if (Array.isArray(settings)) {
      const newObj = settings.reduce((obj, elemento, indice) => {
        obj[indice] = elemento;
        return obj;
      }, {});
      return new Settings(newObj);
    }

    return new Settings(settings);
  }
}
