/**
 * CommentTag
 * @class
 * @public
 */
export default class CommentTag {
  /**
   * Create a Tag for comments
   * @constructor
   * @typedef {object} CommentTag
   * @property @param {string} id
   * @property @param {string} name
   * @property @param {string} color
   * @property @param {string} value - default is id
   */
  constructor(id, color, name, value = null) {
    this.id = id;
    this.color = color;
    this.name = name;
    this.value = value || id;
  }
}
