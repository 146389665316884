export const login = {
  noBreadcrums: true,
  path: '/login',
  name: 'login',
  component: () => import(/* webpackChunkName: "login" */ '@/views/public/login/Login.vue'),
  meta: {
    title: 'Login',
    requiresAuth: false,
  },
  props: true,
};
