import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;
const contextSecure = CONTEXTS.DASHBOARD_SSP.id;

export const create = {
  path: 'new',
  name: `${basePath}-new-user`,
  component: () =>
    import(/* webpackChunkName: "ds-user" */ '@/views/private/modules/dashboardSSP/users/DashboardSSPUsersEdit'),
  meta: {
    requiresAuth: true,
    title: 'New User',
    breadcrumbs: new BreadcrumbItem('New User', { name: `${basePath}-new-user` }, {}),
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.SELLER.id, ROLES.CLIENT.id],
  },
  props: true,
};
