export const ROUTE_COMPONENTS = {
  USER: 'users',
  USER_NEW: 'new-user',
  USER_EDIT: 'edit-user',

  CLIENT: 'workspaces',
  CLIENT_NEW: 'new-workspace',
  CLIENT_EDIT: 'edit-workspace',

  HOME: 'home',
  NO_PERMISSION: 'without-permission',
  NOT_FOUND: 'not-found',
  PROFILE: 'profile',

  LOGIN: 'login',
  RESET_PASSWORD: 'reset-password',
};
