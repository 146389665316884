import { CATEGORIES_KEY, GET_CATEGORIES_REQUEST_KEY } from './keys';
import { getCategoriesAndSubcategories } from '@/services/modules/Core/category/getCategoriesAndSubcategories';
import UNCATEGORIZED_ID from '@/utils/uncategorized';

const actions = {
  [GET_CATEGORIES_REQUEST_KEY]: async ({ commit, state }) => {
    try {
      if (state[CATEGORIES_KEY].length) return state[CATEGORIES_KEY];
      const { data } = await getCategoriesAndSubcategories();
      const category = data.find(cat => cat.id === UNCATEGORIZED_ID);
      if (category) category.uncategorized = true;
      commit(GET_CATEGORIES_REQUEST_KEY, data);
    } catch (e) {
      throw new Error(e);
    }
  },
};

export default actions;
