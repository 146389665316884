import { getDomain, isCustomDomain } from '@/utils/domain';

export class Cookies {
  constructor() {
    this.timeExpire = +process.env.VUE_APP_ACCESS_TOKEN_TTL;
    this.key = process.env.VUE_APP_KEY;
  }

  getCookie = name => {
    const cookies = this.getAllCookies();
    return cookies && cookies[name] ? cookies[name] : null;
  };

  setCookie = (name, value, milliseconds = this.timeExpire, restrictDomain = false) => {
    let expires = '';
    const date = new Date();
    date.setTime(date.getTime() + milliseconds);
    expires = `expires=${date.toUTCString()}`;
    let cookieData = `${value || ''}; ${expires}; path=/;`;
    if (restrictDomain && !isCustomDomain()) cookieData += `domain=${getDomain()};`;
    document.cookie = `${name}=${cookieData}`;
  };

  deleteCookie = (name, restrictDomain = false) => {
    let cookie = `${name}='; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    if (restrictDomain && !isCustomDomain()) cookie += `domain=${getDomain()};'`;
    document.cookie = cookie;
  };

  getAllCookies = () => {
    const cookies = document.cookie;
    if (!cookies) return null;
    const objectCookie = {};
    const arrayCookies = cookies.split('; ');
    arrayCookies.forEach(cookie => {
      const key = cookie.split('=', 1).toString();
      const value = cookie.split('=').slice(1).join('=');
      objectCookie[key] = value;
    });
    return objectCookie;
  };
}
