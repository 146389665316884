/**
 * DealType
 * @class
 * @constructor
 * @public
 */

export default class DealType {
  /**
   * @constructor
   * @typedef {object} DealType
   * @property @param {string} id
   * @property @param {string} name
   */
  constructor(id = null, name = '') {
    this.id = id;
    this.name = name;
  }

  /**
   * @param {{id:string,attributes:DealType}} entity
   * @return {DealType} A DealType instance
   */
  static create(entity) {
    return new DealType(entity.id, entity.attributes.name);
  }
}
