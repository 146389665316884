export default function (value, currency = 'EUR', locale = 'en-UK', decimals = 2, notAvailable) {
  if (value === null || value === undefined) return notAvailable;

  let amount = typeof value === 'string' ? parseFloat(value) : value;
  amount = isNaN(amount) ? 0 : amount;
  if (currency === '~') return `~ ${amount.toLocaleString(locale)}`;
  return amount.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}
