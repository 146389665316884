import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const metrics = {
  path: '',
  name: `${basePath}-products-metrics`,
  component: () =>
    import(
      /* webpackChunkName: "tp-products-metrics" */
      '../../../../../../components/organisms/modules/thirdParty/product/ProductMetrics'
    ),
  meta: {
    requiresAuth: true,
    title: 'Products ',
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id, ROLES.CLIENT.id, ROLES.ADVERTISER.id],
  },
  props: true,
};

export const comments = {
  path: 'comments',
  name: `${basePath}-products-comments`,
  component: () =>
    import(
      /* webpackChunkName: "tp-products-comments" */
      '@/components/organisms/modules/thirdParty/product/ProductComments.vue'
    ),
  meta: {
    requiresAuth: true,
    title: 'Products',
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id, ROLES.CLIENT.id, ROLES.ADVERTISER.id],
  },
  props: true,
};

export const edit = {
  path: ':productId',
  component: () =>
    import(/* webpackChunkName: "tp-product-view" */ '@/views/private/modules/thirdParty/product/ProductEdit'),
  redirect: { name: `${basePath}-products-metrics` },
  meta: {
    requiresAuth: true,
    title: 'Product',
    breadcrumbs: new BreadcrumbItem('Product'),
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id, ROLES.CLIENT.id, ROLES.ADVERTISER.id],
  },
  props: true,
  children: [metrics, comments],
};
