import { initialState } from '@/store/modules/category/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import { CATEGORIES_KEY, GET_CATEGORIES_REQUEST_KEY } from './keys';

const mutations = {
  [GET_CATEGORIES_REQUEST_KEY]: (state, payload) => {
    state[CATEGORIES_KEY] = payload;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[CATEGORIES_KEY] = initialState()[CATEGORIES_KEY];
  },
};

export default mutations;
