import { CURRENCIES_KEY, GET_CURRENCIES_REQUEST_KEY } from './keys';
import { getCurrencies } from '@/services/modules/Core/currency';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';

const actions = {
  [GET_CURRENCIES_REQUEST_KEY]: async ({ state, commit }) => {
    if (state[CURRENCIES_KEY].length === 0) {
      try {
        const { data } = await getCurrencies();
        commit(GET_CURRENCIES_REQUEST_KEY, data);
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) throw error;
      }
    }
  },
};

export default actions;
