import { PlatformProvider } from '@/entities/socialAudience/PlatformProvider';

/**
 * Platform
 * @class
 * @constructor
 * @public
 */

export default class Platform {
  /**
   * @constructor
   * @typedef {object} Platform
   * @param {string} id
   * @param {string} name
   */

  constructor(id = null, name = null) {
    this.id = id;
    this.name = name;
    this.platformProvider = new PlatformProvider(id, name);
  }

  static create(entity) {
    return new Platform(entity.id, entity.attributes.name);
  }
}
