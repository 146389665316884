/**
 * Address
 * @class
 * @constructor
 * @public
 */
export default class Address {
  /**
   * Create a Advertiser
   * @param {string} city
   * @param {string} country
   * @param {string} county
   * @param {string} postalCode
   * @param {string} street
   */
  constructor(city = null, country = null, county = null, postalCode = null, street = null) {
    this.city = city;
    this.country = country;
    this.county = county;
    this.postalCode = postalCode;
    this.street = street;
  }

  payload() {
    return {
      ...this,
    };
  }
}
