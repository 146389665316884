import { api } from '.';
import { ClientShared } from '@/entities/shared/ClientShared';
import { CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

export const getResources = () => ({
  [CLIENT_RESOURCE]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @return {Promise.<{data:ClientShared[],meta:object}>}
 */
export async function getClients(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources());
  const {
    data: { data, meta },
  } = await api.get(partialUrl, params.build?.() || params);
  const auxData = data.map(
    ({ id, attributes }) =>
      new ClientShared(
        id,
        attributes.name,
        attributes.email,
        attributes.type,
        attributes.contactName,
        attributes.isExternal,
        attributes.address,
        '',
        []
      )
  );
  return { data: auxData, meta };
}
