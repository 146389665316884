import { advertiser } from './advertiser';
import { product } from './product';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const demand = {
  path: 'demand',
  name: `${basePath}-demand`,
  redirect: { name: advertiser.name },
  meta: {
    requiresAuth: true,
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id],
  },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [advertiser, product],
  props: true,
};
