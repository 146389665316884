/**
 *  IncludeItem
 *
 * @class
 * @public
 * @constructor
 */

export default class IncludeItem {
  /**
   * @param {String} id
   * @param {String} name
   * @param {string} type
   * @param {Array} value
   */

  static VALID_TYPES = {
    NONE: 'NONE',
    INCLUDE: 'INCLUDE',
    EXCLUDE: 'EXCLUDE',
  };

  static VALID_TYPES_NAMES = {
    NONE: 'None',
    INCLUDE: 'Include',
    EXCLUDE: 'Exclude',
  };

  constructor(id = null, name = null, type = IncludeItem.VALID_TYPES.NONE, value = [], multiple = true) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.multiple = multiple;

    if (Object.values(IncludeItem.VALID_TYPES).includes(type)) {
      this.type = type;
    } else {
      this.type = IncludeItem.VALID_TYPES.NONE;
    }
  }

  getTypeName() {
    return IncludeItem.VALID_TYPES_NAMES[this.type];
  }

  static deepClone(clone) {
    const value = clone?.value.map(val => ({ ...val }));
    return new IncludeItem(clone?.id, clone?.name, clone?.type, value, clone?.multiple);
  }

  clone() {
    const value = this?.value.map(val => ({ ...val }));
    return new IncludeItem(this?.id, this?.name, this?.type, value, this.multiple);
  }

  payload() {
    return this.value.map(value => value.id);
  }

  payloadForTargeting() {
    return this.value;
  }

  static isObject(obj) {
    return obj?.constructor.name === this.name;
  }
}
