import { ROLES } from '@/model/shared/roles';

export const ECOMMERCE = {
  [ROLES.ADMIN.id]: {
    CREATE: ['USER', 'CLIENT', 'PLATFORM', 'COMMENT'],
    DELETE: ['USER', 'CLIENT', 'PLATFORM'],
    SHOW_LIST: ['USER', 'CLIENT', 'ADVERTISER', 'CAMPAIGN', 'PLATFORM'],
    SHOW: ['USER', 'CLIENT', 'CAMPAIGN'],
    EDIT: ['USER', 'CLIENT', 'PLATFORM'],
  },
  [ROLES.AD_OPS.id]: {
    CREATE: ['PLATFORM', 'CLIENT', 'COMMENT'],
    DELETE: [],
    SHOW_LIST: ['ADVERTISER', 'CAMPAIGN', 'PLATFORM', 'CLIENT'],
    SHOW: ['ADVERTISER', 'CAMPAIGN', 'CLIENT'],
    EDIT: ['PLATFORM', 'CLIENT'],
  },
  [ROLES.CLIENT.id]: {
    CREATE: ['USER', 'PLATFORM', 'COMMENT'],
    DELETE: ['USER'],
    SHOW_LIST: ['USER', 'CLIENT', 'ADVERTISER', 'CAMPAIGN', 'PLATFORM'],
    SHOW: ['USER', 'CLIENT', 'ADVERTISER', 'CAMPAIGN'],
    EDIT: ['USER', 'CLIENT', 'PLATFORM'],
  },
  [ROLES.SUPER_ADVERTISER.id]: {
    CREATE: [],
    DELETE: [],
    SHOW_LIST: ['ADVERTISER', 'CAMPAIGN'],
    SHOW: ['ADVERTISER', 'CAMPAIGN'],
    EDIT: [],
  },
  [ROLES.ADVERTISER.id]: {
    CREATE: [],
    DELETE: [],
    SHOW_LIST: ['ADVERTISER', 'CAMPAIGN'],
    SHOW: ['ADVERTISER', 'CAMPAIGN'],
    EDIT: [],
  },
  [ROLES.FINANCE.id]: {
    CREATE: [],
    DELETE: [],
    SHOW_LIST: [],
    SHOW: ['USER'],
    EDIT: ['USER'],
  },
  [ROLES.PUBLISHER.id]: {
    CREATE: [],
    DELETE: [],
    SHOW_LIST: ['ADVERTISER', 'OFFER'],
    SHOW: ['ADVERTISER', 'OFFER'],
    EDIT: [],
  },
};
