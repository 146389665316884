import store from '@/store';
import { IS_AUTHENTICATED, REFRESH_TOKEN, AUTH_LOGOUT, AUTH_REFRESH } from '@/store/modules/auth/keys';

export async function hasLoggedIn() {
  const isAuthenticated = !!store.getters[IS_AUTHENTICATED];

  try {
    // Check if we can renew user credentials
    if (isAuthenticated === false && store.getters[REFRESH_TOKEN]) {
      await store.dispatch(AUTH_REFRESH);
      return store.getters[IS_AUTHENTICATED];
    }
    return isAuthenticated;
  } catch (error) {
    await store.dispatch(AUTH_LOGOUT);
    return false;
  }
}
