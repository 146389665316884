import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import User from '@/entities/shared/User';

/**
 * @typedef ContextRole
 * @property {string} context - context name
 * @property {string} role - role name
 * @property {string[]} ids - ids of clients or workspaces
 * @property {object} settings - settings for the context
 */
function getContextRolesWithIds(contextRoles, included) {
  return contextRoles.map(cx => {
    let ids = [];

    switch (cx.role) {
      case ROLES.SUPER_ADVERTISER.id:
      case ROLES.ADVERTISER.id:
        ids = included.advertisers || [];
        break;
      case ROLES.PUBLISHER.id:
        ids = included.publishers || [];
        break;
      case ROLES.FINANCE.id:
      case ROLES.SELLER.id:
      case ROLES.CLIENT.id:
      case ROLES.AD_OPS.id:
        ids = included.clients || [];
        break;
      default:
        break;
    }

    // Normalize settings, back send an array inside null on empty object
    cx.settings = cx.settings instanceof Array ? {} : cx.settings || {};
    cx.settings.ids = ids.filter(({ type }) => type === cx.context).map(cli => cli?.id || cli);
    return cx;
  });
}

export default class UserCore extends User {
  /**
   * Represents a user in Core context
   *
   * @param {string|null} id
   * @param {string} name
   * @param {string} email
   * @param {string} password
   * @param {ContextRole[]} contextRoles
   * @param {Boolean} enabled
   * @param {import('@/entities/shared/ClientShared').ClientShared[]} clients
   */
  constructor(
    id = null,
    name = '',
    email = '',
    password = '',
    contextRoles = [],
    enabled = true,
    clients = [],
    advertisers = [],
    publishers = []
  ) {
    super(id, name, email, password, enabled);

    /** @type {ContextRole[]} */
    this.contextRoles = contextRoles;

    this.clients = clients;
    this.advertisers = advertisers;
    this.publishers = publishers;
  }

  payload() {
    return {
      ...super.payload(),
      contextRoles: this.contextRoles.filter(cr => !!cr),
    };
  }

  /**
   *
   * @param {string} contextId
   * @param {string} clientId  - clientId or  workspacesId
   */
  deleteClientByContext(contextId, clientId) {
    const contextRoles = [];
    this.contextRoles.forEach(contextRole => {
      if (contextRole.context === contextId) {
        const idsToKeep = contextRole.settings.ids.filter(id => id !== clientId);
        if (idsToKeep.length) {
          contextRole.settings.ids = idsToKeep;
        }
      } else {
        contextRoles.push(contextRole);
      }
    });
    this.contextRoles = contextRoles;
  }

  /**
   *
   * @param {{id:string, attributes: UserCore}} entity
   * @param object included
   * @return {UserCore}
   */
  static create(entity, included) {
    let contextRoles = entity.attributes.contextRoles;
    contextRoles = contextRoles
      ? getContextRolesWithIds(contextRoles, included)
      : [{ role: entity.attributes.role, context: CONTEXTS.CORE.id, settings: {} }];

    return new UserCore(
      entity.id,
      entity.attributes.name,
      entity.attributes.email,
      entity.attributes.password,
      contextRoles,
      entity.attributes.enabled,
      // FIXME: clients, advertisers, publishers tiene seido
      included?.clients,
      included?.advertisers,
      included?.publishers
    );
  }
}
