import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import basicInfo from '@/router/private/modules/socialAudience/demand/campaign/editForm/basicInfo';
import targeting from '@/router/private/modules/socialAudience/demand/campaign/editForm/targeting';
import { creative } from '@/router/private/modules/socialAudience/demand/campaign/editForm/creative';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;
const contextSecure = CONTEXTS.SOCIAL_AUDIENCE.id;

export const edit = {
  path: ':campaignId/edit',
  name: `${basePath}-edit-campaign`,
  component: () =>
    import(/* webpackChunkName: "sa-campaign-view" */ '@/views/private/modules/socialAudience/campaign/CampaignEdit'),
  redirect: { name: basicInfo.name },
  meta: {
    requiresAuth: true,
    title: 'Campaign',
    breadcrumbs: new BreadcrumbItem('Edit Campaign', { name: `${basePath}-edit-campaign` }, {}),
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id, ROLES.CLIENT.id, ROLES.ADVERTISER.id],
  },
  props: true,
  children: [basicInfo, targeting, creative],
};
