import Deal from './Deal';
import Platform from './Platform';

/**
 * PlatformDeal
 * @class
 * @constructor
 * @public
 */

export default class PlatformDeals {
  /**
   * @constructor
   * @typedef {object} PlatformDeal
   * @property @param {string} id
   * @property @param {string} name
   * @property @param {Deal} Deal
   * @property @param {string} externalDealId
   * @property @param {Platform} platform
   * @property @param {DealComment[]} comments
   * @property @param {number} revenueNet
   * @property @param {String} platformName
   */

  constructor(
    id = null,
    name = '',
    deal = new Deal(),
    externalDealId = '',
    platform = new Platform(),
    comments = [],
    revenueNet = null,
    platformName = ''
  ) {
    this.id = id;
    this.name = name;
    this.deal = deal;
    this.externalDealId = externalDealId;
    this.platform = platform;
    this.comments = comments;
    this.revenueNet = revenueNet;
    this.platformName = platformName;
  }

  static create(entity, included) {
    const attr = entity.attributes;
    const deal = included?.deals?.find(deal => deal.id === attr.dealId) || new Deal(attr.dealId);
    const platform =
      included?.platforms?.find(platform => platform.id === attr.platformId) || new Platform(attr.platformId);

    const commentsOnEntry = entity.relationships?.comments.data;
    const comments = included?.comments?.filter(({ id }) => !!commentsOnEntry.find(c => id === c.id));
    comments?.forEach(comment => {
      comment.author = included?.author?.find(ath => ath.id === comment.author.id);
    });

    return new PlatformDeals(
      entity.id,
      attr.name,
      deal,
      attr.externalDealId,
      platform,
      comments,
      attr.revenueNet,
      attr.platformName
    );
  }
}
