import { SETTINGS, USER_SINGLE_RESOURCE } from '@/services/keys.js';
import Settings from '@/model/shared/user/Settings';

/**
 * @param {Obelix} api
 * @param config
 * @returns {Promise<Object>}  Object containing user config
 */
export async function getSettings(api, config = { preventCancel: false, responseType: null, preventKey: null }) {
  const partialUrl = api.createUrl({
    [USER_SINGLE_RESOURCE]: null,
    [SETTINGS]: null,
  });

  const { data } = await api.get(partialUrl, config);
  if (data.data?.attributes?.settings) {
    return Settings.build(data.data?.attributes?.settings);
  }

  return new Settings();
}
