import {
  CLEAR_REPORT_MODAL_KEY,
  SET_DATA_REPORT_MODAL_KEY,
  SET_STATE_REPORT_MODAL_KEY,
  SET_TYPE_REPORT_MODAL_KEY,
} from '@/store/modules/campaignManager/keys';

const actions = {
  [SET_STATE_REPORT_MODAL_KEY]: ({ commit }, value) => {
    commit(SET_STATE_REPORT_MODAL_KEY, value);
  },
  [SET_DATA_REPORT_MODAL_KEY]: ({ commit }, data) => {
    commit(SET_DATA_REPORT_MODAL_KEY, data);
  },
  [CLEAR_REPORT_MODAL_KEY]: ({ commit }) => {
    commit(CLEAR_REPORT_MODAL_KEY);
  },
  [SET_TYPE_REPORT_MODAL_KEY]: ({ commit }, type) => {
    commit(SET_TYPE_REPORT_MODAL_KEY, type);
  },
};

export default actions;
