import { BREADCRUMB_FROM_ROUTE_KEY } from './keys';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { deepClone } from '@/utils/deepClone';

const actions = {
  [BREADCRUMB_FROM_ROUTE_KEY]: async ({ commit, rootGetters }, payload) => {
    commit(BREADCRUMB_FROM_ROUTE_KEY, mountBreadcrumbs(payload, rootGetters));
  },
};

function mountBreadcrumbs(to, rootGetters) {
  const breadcrumbs = [];

  to?.matched.forEach(route => {
    const crumb = deepClone(route?.meta?.breadcrumbs);
    if (crumb) {
      crumb.params = to.params;
      breadcrumbs.push(crumb);
    }
  });

  const activeContext = rootGetters[ACTIVE_CONTEXT];

  // Since breadcrumbs are added from views, we need to add one for the home one
  if (breadcrumbs.length > 0) {
    breadcrumbs[0].nameUrl = { name: activeContext?.getDefaultComponent() };
  }
  return breadcrumbs.length > 1 ? breadcrumbs : [];
}

export default actions;
