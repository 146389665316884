import { initialState } from '@/store/modules/country/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import { CONTENT_TARGETING_KEY } from './keys';

const mutations = {
  [CONTENT_TARGETING_KEY]: (state, payload) => {
    state[CONTENT_TARGETING_KEY] = payload;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[CONTENT_TARGETING_KEY] = initialState()[CONTENT_TARGETING_KEY];
  },
};

export default mutations;
