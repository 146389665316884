import { isArray } from '@/utils/validation/assertions';

export class EntityParser {
  constructor(newParsers) {
    this.parsers = {};

    this.parsers = { ...this.parsers, ...newParsers };
  }

  parse(request) {
    // SingleItem condition is needed to handle the cases where only a item needs to be returned
    const singleItem = !isArray(request.data);
    const entities = singleItem ? [request.data] : request.data;
    const response = [];

    if (entities.length && entities[0].type && entities[0].id) {
      const parser = this.parsers[entities[0].type];
      if (parser) {
        const included = this.parseIncluded(request.included);
        entities.forEach(e => response.push(parser(e, included)));
      } else {
        throw new Error(`No parser found for ${entities[0].type}`);
      }
    }

    return singleItem ? response.pop() : response;
  }

  parseIncluded(included) {
    const entities = {};
    included?.forEach(entity => {
      const parser = this.parsers[entity.type];

      if (parser) {
        if (!entities[entity.type]) {
          entities[entity.type] = [];
        }

        const entityParsed = parser(entity);

        if (entity.relationships) {
          entityParsed.relationships = entity.relationships;
        }

        entities[entity.type].push(entityParsed);
      } else {
        throw new Error(`No parser found for ${entity.type}`);
      }
    });
    return entities;
  }
}
