import { api } from '.';
import { USER_RESOURCE, PROFILE_RESOURCE } from '@/services/keys';

export async function getProfile() {
  const partialUrl = api.createUrl({
    [USER_RESOURCE]: undefined,
    [PROFILE_RESOURCE]: undefined,
  });
  return await api.get(partialUrl);
}
