import { api } from '.';
import { CURRENCY_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import coreEntityParser from '@/entities/Core/CoreEntityParser';

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {object[]}
 */
export async function getCurrencies(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [CURRENCY_RESOURCE]: undefined,
  });
  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(coreEntityParser.parse(data)).build();
}
