import {
  CLEAR_REPORT_MODAL_KEY,
  DATA_REPORT_MODAL_KEY,
  SET_DATA_REPORT_MODAL_KEY,
  SET_STATE_REPORT_MODAL_KEY,
  SET_TYPE_REPORT_MODAL_KEY,
  SHOW_REPORT_MODAL_KEY,
  TYPE_REPORT_MODAL_KEY,
} from '@/store/modules/campaignManager/keys';
import { initialState } from '@/store/modules/campaignManager/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';

const mutations = {
  [SET_STATE_REPORT_MODAL_KEY]: (state, value) => {
    state[SHOW_REPORT_MODAL_KEY] = value;
  },
  [SET_DATA_REPORT_MODAL_KEY]: (state, data) => {
    state[DATA_REPORT_MODAL_KEY] = data;
  },
  [CLEAR_REPORT_MODAL_KEY]: state => {
    state[DATA_REPORT_MODAL_KEY] = initialState()[DATA_REPORT_MODAL_KEY];
    state[SHOW_REPORT_MODAL_KEY] = initialState()[SHOW_REPORT_MODAL_KEY];
  },
  [SET_TYPE_REPORT_MODAL_KEY]: (state, type) => {
    state[TYPE_REPORT_MODAL_KEY] = type;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[DATA_REPORT_MODAL_KEY] = initialState()[DATA_REPORT_MODAL_KEY];
    state[SHOW_REPORT_MODAL_KEY] = initialState()[SHOW_REPORT_MODAL_KEY];
  },
};

export default mutations;
