import { dateToYYYYMMDD } from '@/filters/dateFilters';

/**
 * @name IngestionJobNotifications
 * @class
 * @public
 */
export default class IngestionJobNotifications {
  /**
   * @param {string} id
   * @param {string} message
   * @param {Array.<string>|Object} dates - Date is Array of string o Array like
   * Object, back insiste que es lo mismo, pero no hubo forma de hacerlo
   * entender
   * @constructor
   */
  constructor(id = null, message = '', dates = []) {
    this.id = id;
    this.message = message;
    this.dates = dates;
  }

  /**
   * @param {{id:string,attributes:IngestionJobNotifications}} entity
   * @return {IngestionJobNotifications} A IngestionJobNotifications instance
   */
  static create(entity) {
    let objectDates = entity.attributes.dates;

    if (typeof objectDates === 'string') {
      objectDates = JSON.parse(objectDates);
    }
    if (!(objectDates instanceof Array) && typeof objectDates === 'object') {
      objectDates = Object.values(objectDates);
    }

    return new IngestionJobNotifications(
      entity.id,
      entity.attributes.message,
      objectDates.map(data => dateToYYYYMMDD(data))
    );
  }
}
