import IncludeItemNew from '@/model/shared/IncludeItemNew';

/**
 *  TargetItem
 *
 * @class
 * @public
 * @constructor
 */

export default class TargetItem {
  /**
   * @param {String} id
   * @param {String} name
   * @param {Array.<IncludeItemNew>} value
   */

  constructor(id = null, name = null, value = [], multiple = true, canExclude = true) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.multiple = multiple;
    this.canExclude = canExclude;
  }

  addItem(type, item) {
    this.value.push(new IncludeItemNew(type, item));
  }

  static deepClone(clone) {
    const value = clone?.value.map(val => IncludeItemNew.deepClone(val));
    return new TargetItem(clone?.id, clone?.name, value, clone?.multiple, clone?.canExclude);
  }

  clone() {
    const value = this?.value.map(val => IncludeItemNew.clone(val));
    return new TargetItem(this?.id, this?.name, value, this.multiple, this.canExclude);
  }
}
