/// <reference path="./roles.js" />
import Context from './Context';
import { ROLES } from '@/model/shared/roles';

/**
 * Context
 * @class
 * @public
 */
export default class SunStudioContext extends Context {
  /**
   * Create a SunStudioContext
   * @constructor
   * @typedef {object} SunStudioContext
   */
  constructor() {
    super('SUNSTUDIO', 'SunStudio', null, null, null, {
      [ROLES.ADMIN.id]: [],
      [ROLES.MARKETING.id]: [],
      [ROLES.SALES.id]: [],
      [ROLES.TRAFFICKER.id]: [],
      [ROLES.ACCOUNT_MANAGER.id]: [],
      [ROLES.DESIGNER.id]: [],
      [ROLES.EXTERNAL_DESIGNER.id]: [],
    });
    this.baseUrl = process.env.VUE_APP_SUNSTUDIO_URL;
  }

  getDefaultComponent() {
    return this.baseUrl;
  }
}
