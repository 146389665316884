import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const branding = {
  path: 'branding',
  name: `${basePath}-branding`,
  component: () =>
    import(/* webpackChunkName: "tp-analytics" */ '@/views/private/modules/thirdParty/analytics/Branding.vue'),
  meta: {
    breadcrumbs: new BreadcrumbItem('Branding', { name: `${basePath}-branding` }, {}),
    requiresAuth: true,
    title: 'Branding',
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.SELLER.id],
  },
  props: true,
};

export default branding;
