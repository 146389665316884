import Status from '@/model/shared/Status';

export const OK_STATUS = new Status('OK', 'OK', 'green');
export const SYNC_STATUS = new Status('SYNC', 'SYNC', 'blue');
export const IMPORTING_STATUS = new Status('IMPORTING', 'IMPORTING', 'yellow');
export const KO_STATUS = new Status('KO', 'KO', 'red');
export const WARNING_STATUS = new Status('WARNING', 'WARNING', 'orange');

export const PLATFORM_STATUS = [OK_STATUS, SYNC_STATUS, IMPORTING_STATUS, KO_STATUS, WARNING_STATUS];

export const PLATFORM_STATUS_DEFAULT = OK_STATUS;
