import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;
const contextSecure = CONTEXTS.SOCIAL_AUDIENCE.id;

export const clientList = {
  path: 'client-wallet-list',
  name: `${basePath}-admin-client-wallet-list`,
  component: () =>
    import(/* webpackChunkName: "sa-client-list-wallet" */ '@/views/private/modules/socialAudience/wallet/ClientList'),
  meta: {
    requiresAuth: true,
    title: 'Wallet ',
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  props: true,
};
