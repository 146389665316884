import { publisher } from './publisher';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const supply = {
  path: 'supply',
  name: `${basePath}-supply`,
  redirect: { name: publisher.name },
  meta: {
    requiresAuth: true,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [publisher],
  props: true,
};
