import MoneyAmount from '@/model/shared/MoneyAmount';
import Metric from './Metric';

/**
 * @name Analytic
 * @class
 * @public
 */
export default class Analytic {
  /**
   * @param {string} breakdown
   * @param {Metric} metrics
   * @param {MoneyAmount} cost
   * @constructor
   */
  constructor(breakdown = '', metrics = new Metric(), cost = new MoneyAmount(), netCost = new MoneyAmount()) {
    this.breakdown = breakdown;
    this.metrics = metrics;
    this.cost = cost;
    this.netCost = netCost;
  }

  /**
   * @param {{id:string, attributes: Analytic}} entity
   * @return {Analytic}
   */
  static create(entity) {
    const attr = entity.attributes;
    const metrics = Metric.create({ attributes: attr.metrics });

    return new Analytic(
      attr.breakdown,
      metrics,
      attr.cost && new MoneyAmount(attr.cost.currency, attr.cost.amount),
      attr.netCost && new MoneyAmount(attr.netCost.currency, attr.netCost.amount)
    );
  }
}
