import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

const postEvent = {
  path: 'post-events',
  name: `${basePath}-clone-offer-post-event`,
  component: () =>
    import(
      /* webpackChunkName: "ec-duplicate-offer-post-event" */ '@/components/organisms/modules/ecommerce/offer/form/postEvent/PostEvent'
    ),
  meta: {
    requiresAuth: true,
    title: 'Offer',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.PUBLISHER.id],
  },
  props: true,
};

export default postEvent;
