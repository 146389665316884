import { RESET_PASSWORD_NOT_LOGGED_RESOURCE } from '../../keys';
import { api } from '.';

/**
 *
 * @param {Promise<Object>} password
 */
export function resetPassword(password, token) {
  const partialUrl = api.createUrl({
    [RESET_PASSWORD_NOT_LOGGED_RESOURCE]: undefined,
  });
  return api.create(partialUrl, { token, password1: password, password2: password });
}
