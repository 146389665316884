import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const history = {
  path: '',
  name: `${basePath}-show-offer-history`,
  component: () => import('@/components/organisms/modules/ecommerce/offer/OfferHistory.vue'),
  meta: {
    requiresAuth: true,
    title: 'Offer comments ',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.PUBLISHER.id],
    loadingIcon: true,
  },
  props: true,
};
