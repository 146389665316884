import {
  QUICK_FILTER_LIST_KEY,
  SAVE_BREAKDOWN_KEY,
  SAVE_COLUMN_CONFIG_KEY,
  SAVE_FILTERS_KEY,
  SAVE_ITEMS_PER_PAGE_KEY,
  SAVE_QUICK_FILTERS_KEY,
  SAVE_SORTERS_KEY,
  SORTER_LIST_KEY,
} from './keys';

const actions = {
  [SAVE_FILTERS_KEY]: ({ commit }, payload) => {
    if (payload) {
      const filterMap = new Map();

      payload.forEach(item => {
        // TODO `|| item.name` keepping all implement
        // And ? on filterConfig?

        const filterKey = item.filterConfig?.key || item.name;
        // If filter has been getting from store before get meta from endpoint,
        // value is  equal  null to show loading indicator on pills

        // let value = item.meta?.[item.filterConfig?.trackBy] || item.value;
        function filtersGetRealValue(filter) {
          return filter?.meta?.id || filter?.meta?.value || filter?.meta || filter?.value;
        }
        const value = item.meta?.[item.filterConfig?.trackBy] || filtersGetRealValue(item);

        if (filterMap.has(filterKey)) {
          const previous = filterMap.get(filterKey);
          filterMap.set(filterKey, `${previous},${value}`);
        } else {
          filterMap.set(filterKey, value);
        }
      });

      commit(SAVE_FILTERS_KEY, filterMap);
    }
  },
  [SAVE_QUICK_FILTERS_KEY]: ({ state, commit }, payload) => {
    const map = state[QUICK_FILTER_LIST_KEY];

    const oldState = { ...map.get(payload.idView) };
    Object.entries(payload).forEach(keyValue => {
      const [key, value] = keyValue;
      oldState[key] = value;
    });

    map.set(payload.idView, payload.filters);

    commit(SAVE_QUICK_FILTERS_KEY, map);
  },

  // payload -> idView, sorter{ field, order }
  [SAVE_SORTERS_KEY]: ({ commit, state }, payload) => {
    if (payload) {
      const sorterMap = state[SORTER_LIST_KEY];

      sorterMap.set(payload.idView, payload.sorter);

      commit(SAVE_SORTERS_KEY, sorterMap);
    }
  },

  [SAVE_COLUMN_CONFIG_KEY]: ({ commit }, payload) => {
    commit(SAVE_COLUMN_CONFIG_KEY, payload);
  },

  [SAVE_BREAKDOWN_KEY]: ({ commit }, payload) => {
    commit(SAVE_BREAKDOWN_KEY, payload);
  },

  [SAVE_ITEMS_PER_PAGE_KEY]: ({ commit }, payload) => {
    commit(SAVE_ITEMS_PER_PAGE_KEY, payload);
  },
};

export default actions;
