import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const list = {
  path: 'list',
  name: `${basePath}-postEvents`,
  component: () =>
    import(/* webpackChunkName: "ec-postEvent" */ '@/views/private/modules/ecommerce/postEvents/PostEventTemplateList'),
  meta: {
    requiresAuth: true,
    title: 'Users ',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id],
  },
  props: true,
};
