import { initialState } from '@/store/modules/toast/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import { CREATE_TOAST_KEY, DELETE_TOAST_KEY, TOAST_LIST_KEY } from './keys';

const mutations = {
  [CREATE_TOAST_KEY]: (state, toast) => {
    state[TOAST_LIST_KEY].push(toast);
  },
  [DELETE_TOAST_KEY]: (state, payload) => {
    state[TOAST_LIST_KEY] = payload;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[TOAST_LIST_KEY] = initialState()[TOAST_LIST_KEY];
  },
};

export default mutations;
