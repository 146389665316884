import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.SOCIAL_AUDIENCE.baseUrl;
const contextSecure = CONTEXTS.SOCIAL_AUDIENCE.id;

export const edit = {
  path: ':id/edit',
  name: `${basePath}-edit-user`,
  component: () =>
    import(/* webpackChunkName: "sa-user" */ '@/views/private/modules/socialAudience/users/SocialAudienceUsersEdit'),
  meta: {
    requiresAuth: true,
    title: 'User Edit ',
    breadcrumbs: new BreadcrumbItem('Edit User', { name: `${basePath}-edit-user` }, {}),
    contextSecure,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  props: true,
};
