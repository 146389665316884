import { api } from '.';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { CLIENT_RESOURCE } from '@/services/keys';
import { ClientShared } from '@/entities/shared/ClientShared';

/**
 *
 * @param {string} clientId
 * @returns {Promise.<Client>}
 */
export async function getClientById(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
  });
  params.addInclude(['wallet', 'currency']);

  const {
    data: { data, objectInclude },
  } = await api.get(partialUrl, params);
  const selectWallet = objectInclude?.wallet ? objectInclude.wallet[data?.relationships?.wallet?.data?.id] : null;

  return new ClientShared(
    data.id,
    data.attributes.name,
    data.attributes.email,
    data.attributes.type,
    data.attributes.contactName,
    data.attributes.isExternal,
    data.attributes.address,
    selectWallet
  );
}

export async function getClientByIdOrNull(id) {
  try {
    return await getClientById(id);
  } catch (e) {
    return null;
  }
}
