import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const kleup = {
  path: 'kleup',
  name: `${basePath}-kleup`,
  component: () =>
    import(/* webpackChunkName: "tp-analytics" */ '@/views/private/modules/thirdParty/analytics/Kleup.vue'),
  meta: {
    breadcrumbs: new BreadcrumbItem('Kleup', { name: `${basePath}-kleup` }, {}),
    requiresAuth: true,
    title: 'Kleup',
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.SELLER.id],
  },
  props: true,
};

export default kleup;
