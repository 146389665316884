import { initialState } from '@/store/modules/loadingToast/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import { LOADING_TOAST_KEY, SET_LOADING_TOAST_KEY } from './keys';

const mutations = {
  [SET_LOADING_TOAST_KEY]: (state, loadingToast) => {
    state[LOADING_TOAST_KEY] = loadingToast;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[LOADING_TOAST_KEY] = initialState()[LOADING_TOAST_KEY];
  },
};

export default mutations;
