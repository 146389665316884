import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const stats = {
  path: 'stats',
  name: `${basePath}-analytics-stats`,
  component: () =>
    import(
      /* webpackChunkName: "ec-analytics-stats" */ '@/views/private/modules/ecommerce/analytics/AnalyticsList.vue'
    ),
  meta: {
    breadcrumbs: new BreadcrumbItem('Analytics', { name: `${basePath}-analytics-stats` }, {}),
    requiresAuth: true,
    title: 'Analytics ',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [
      ROLES.ADMIN.id,
      ROLES.CLIENT.id,
      ROLES.PUBLISHER.id,
      ROLES.AD_OPS.id,
      ROLES.SUPER_ADVERTISER.id,
      ROLES.ADVERTISER.id,
    ],
  },
  props: true,
};

export default stats;
