import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const list = {
  path: 'list',
  name: `${basePath}-campaigns`,
  component: () =>
    import(/* webpackChunkName: "ec-campaign" */ '@/views/private/modules/ecommerce/campaign/CampaignList'),
  meta: {
    requiresAuth: true,
    title: 'Campaigns ',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  props: true,
};
