import { getBrowsersSmart } from './getBrowsersSmart';
import { getBrowsersVideoPlaza } from './getBrowsersVideoPlaza';
import { getOperatingSystemsSmart } from './getOperatingSystemsSmart';
import { getOperatingSystemsVideoPlaza } from './getOperatingSystemsVideoPlaza';
import { getDevicesSmart } from './getDevicesSmart';
import { getDevicesVideoPlaza } from './getDevicesVideoPlaza';
import { core } from '..';

export const api = core;

export {
  getBrowsersSmart,
  getBrowsersVideoPlaza,
  getOperatingSystemsSmart,
  getOperatingSystemsVideoPlaza,
  getDevicesSmart,
  getDevicesVideoPlaza,
};
