import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

const additionalInfo = {
  path: 'additional-info',
  name: `${basePath}-edit-publisher-additional-info`,
  component: () =>
    import(
      /* webpackChunkName: "ec-publisher-basic-info" */ '@/components/organisms/modules/ecommerce/publisher/additionalInfo/AdditionalInfo'
    ),

  meta: {
    requiresAuth: true,
    title: 'Publisher',
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id],
  },
  props: true,
};

export default additionalInfo;
