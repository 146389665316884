import { deepClone } from '../../../utils/deepClone';
import { LOADING_TOAST_STATUS } from '@/model/shared/LoadingToastStatus';
import {
  SET_LOADING_TOAST_KEY,
  SET_STATE_LOADING_TOAST_KEY,
  LOADING_TOAST_KEY,
  SET_STATE_SUCCESS_KEY,
  SET_STATE_ERROR_KEY,
} from './keys';

const actions = {
  [SET_STATE_ERROR_KEY]: ({ commit, getters }) => {
    let loadingToast = null;
    if (getters[LOADING_TOAST_KEY]) {
      loadingToast = deepClone(getters[LOADING_TOAST_KEY]);
      loadingToast.status = LOADING_TOAST_STATUS.ERROR;
    }
    commit(SET_LOADING_TOAST_KEY, loadingToast);
  },

  [SET_STATE_SUCCESS_KEY]: ({ commit, getters }) => {
    let loadingToast = null;
    if (getters[LOADING_TOAST_KEY]) {
      loadingToast = deepClone(getters[LOADING_TOAST_KEY]);
      loadingToast.status = LOADING_TOAST_STATUS.SUCCESS;
    }
    commit(SET_LOADING_TOAST_KEY, loadingToast);
  },

  [SET_STATE_LOADING_TOAST_KEY]: ({ commit, getters }) => {
    let loadingToast = null;
    if (getters[LOADING_TOAST_KEY]) {
      loadingToast = deepClone(getters[LOADING_TOAST_KEY]);
      loadingToast.status = LOADING_TOAST_STATUS.LOADING;
    }
    commit(SET_LOADING_TOAST_KEY, loadingToast);
  },
  [SET_LOADING_TOAST_KEY]: ({ commit }, loadingToast = null) => {
    commit(SET_LOADING_TOAST_KEY, loadingToast);
  },
};

export default actions;
