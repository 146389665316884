/**
 *  CreativeFeedType
 *
 * @class
 * @public
 * @constructor
 */

export default class CreativeFeedType {
  /**
   * @param {String} id
   * @param {String} name
   * @param {String} description
   */

  constructor(id = null, name = null, description = null) {
    this.id = id;
    this.name = name;
    this.description = description;
  }
}
