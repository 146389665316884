var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { attrs: { viewBox: "0 0 1024 1024" } }, [
    _c("path", {
      staticClass: "fill-current",
      attrs: {
        d: "M464 720a48 48 0 1 0 96 0a48 48 0 1 0-96 0zm16-304v184c0 \n    4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V416c0-4.4-3.6-8-8-8h-48c-4.4 \n    0-8 3.6-8 8zm475.7 440l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 \n    5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 \n    27.7-48zm-783.5-27.9L512 239.9l339.8 588.2H172.2z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }