const isString = value => typeof value === 'string';

const isArray = value => Array.isArray(value);

const isNumber = value => (typeof value === 'number' && !isNaN(value)) || toString.call(value) === '[object Number]';

const isObject = value => typeof value === 'object' && value !== null && toString.call(value) === '[object Object]';

const isBoolean = value => value === true || value === false || toString.call(value) === '[object Boolean]';

const isEmail = email => {
  /* eslint-disable-next-line max-len, no-useless-escape */
  const pattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!pattern.test(email);
};

const isUrl = url => {
  const pattern = new RegExp(
    '(http(s)?:\\/\\/.)(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_{}%\\+.~#?&//=]*)',
    'i'
  );
  return !!pattern.test(url);
};

const isMatch = (value, regex) => {
  const regexObject = new RegExp(regex);
  return regexObject.test(value);
};

const isDate = value => {
  return value && toString.call(value) === '[object Date]' && !isNaN(value);
};

export { isString, isArray, isNumber, isObject, isBoolean, isEmail, isUrl, isMatch, isDate };
