import { REFRESH_TOKEN_RESOURCE } from '../../keys';
import { api } from '.';

export async function refreshToken(token) {
  const partialUrl = api.createUrl({
    [REFRESH_TOKEN_RESOURCE]: undefined,
  });
  const { data } = await api.create(partialUrl, { refreshToken: token });

  return data;
}
