import { CONTEXTS } from '@/model/shared/contexts';
import { initialState } from '@/store/modules/context/initialState';
import { RESET_STORE_MODULE_MUTATION } from '@/store/utils/createResetStore';
import { ACTIVE_CLIENT_KEY, SET_ACTIVE_CLIENT_KEY, ACTIVE_CONTEXT_KEY } from './keys';

const mutations = {
  [SET_ACTIVE_CLIENT_KEY]: (state, client) => {
    state[ACTIVE_CLIENT_KEY] = client;
    state[ACTIVE_CONTEXT_KEY] = CONTEXTS[client?.type] || null;
  },
  [RESET_STORE_MODULE_MUTATION]: state => {
    state[ACTIVE_CLIENT_KEY] = initialState[ACTIVE_CLIENT_KEY];
    state[ACTIVE_CONTEXT_KEY] = initialState[ACTIVE_CONTEXT_KEY];
  },
};

export default mutations;
