import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { getContentTargeting } from '@/services/modules/socialAudience/contentTargeting';
import { CONTENT_TARGETING_KEY, GET_CONTENT_TARGETING_REQUEST_KEY } from './keys';

const actions = {
  [GET_CONTENT_TARGETING_REQUEST_KEY]: async ({ state, commit }) => {
    try {
      if (state[CONTENT_TARGETING_KEY].length) return state[CONTENT_TARGETING_KEY];
      const { data } = await getContentTargeting();
      commit(CONTENT_TARGETING_KEY, data);
    } catch (error) {
      if (error.code !== HTTPStatusCode.Cancel) throw error;
    }
  },
};

export default actions;
