import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const list = {
  path: 'list',
  name: `${basePath}-products`,
  component: () =>
    import(/* webpackChunkName: "tp-product" */ '@/views/private/modules/thirdParty/product/ProductList'),
  meta: {
    requiresAuth: true,
    title: 'Products ',
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id, ROLES.CLIENT.id, ROLES.ADVERTISER.id],
  },
  props: true,
};
