import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const list = {
  path: 'list',
  name: `${basePath}-list`,
  component: () =>
    import(
      /* webpackChunkName: "tp-list" */ '@/views/private/modules/thirdParty/scheduledEmails/ThirdPartyScheduledEmailsList.vue'
    ),
  meta: {
    requiresAuth: true,
    title: 'Scheduled Emails List',
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id],
  },
  props: true,
};
