import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { list } from '@/router/private/modules/ecommerce/admin/fraudTemplates/list';
import { create } from '@/router/private/modules/ecommerce/admin/fraudTemplates/create';
import { edit } from '@/router/private/modules/ecommerce/admin/fraudTemplates/edit';
import { duplicate } from '@/router/private/modules/ecommerce/admin/fraudTemplates/duplicate';

const basePath = CONTEXTS.ECOMMERCE.baseUrl;

export const fraudTemplates = {
  name: `${basePath}-fraud-templates`,
  path: 'fraud-templates',
  redirect: { name: list.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    requiresAuth: true,
    title: 'Fraud Templates',
    breadcrumbs: new BreadcrumbItem('Fraud templates', { name: `${basePath}-fraud-templates` }, {}),
    contextSecure: CONTEXTS.ECOMMERCE.id,
    rolesAccepted: [ROLES.ADMIN.id],
  },
  props: true,
  children: [list, create, edit, duplicate],
};
